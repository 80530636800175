import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import FilterCompanyWithOpticians from "components/Filter/FilterCompanyWithOpticians"
import ErrorUtils from "components/Tools/ErrorUtils"
import OpticianUser from "views/OpticianUser/OpticianUser"
import UserUtils from "components/Utils/UserUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import IconButton from "@material-ui/core/IconButton"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import VisibilityIcon from "@material-ui/icons/Visibility"

// Nomi dei campi delle colonne
function createData(
  username,
  password,
  optician,
  userDevice,
  language,
  utc,
  email,
  active,
  description
) {
  return {
    username,
    password,
    optician,
    userDevice,
    language,
    utc,
    email,
    active,
    description,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.username !== rowToDelete
  })
}

// Click View Password
const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
  setRows(
    rows.map((elem) => {
      let newElem = elem
      if (elem === rowToViewPassword) {
        newElem.viewPassword = !newElem.viewPassword
      }
      return newElem
    })
  )
}

export default function OpticianUsers() {
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const [allBrandsDevices, setAllBrandsDevices] = useState([])
  const [changeCompany, setChangeCompany] = useState()
  const [companyName, setCompanyName] = useState()
  const [companyId, setCompanyId] = useState()
  const [companyLanguage, setCompanyLanguage] = useState()
  const [filter] = useState([
    {
      id: "username",
      value: "",
      label: t("10062"),
    },
    {
      id: "opticianId",
      value: "",
      label: t("10175"),
    },
    {
      id: "opticianName",
      value: "",
      label: t("10285"),
    },
  ])

  const headCells = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: t("10062").toUpperCase(),
    },
    {
      id: "password",
      numeric: false,
      disablePadding: false,
      label: t("10063").toUpperCase(),
    },
    {
      id: "opticianIdSort",
      numeric: false,
      disablePadding: false,
      label: t("10175").toUpperCase(),
    },
    {
      id: "deviceView",
      numeric: false,
      disablePadding: false,
      label: t("10161").toUpperCase(),
    },
    {
      id: "language",
      numeric: false,
      disablePadding: false,
      label: t("10083").toUpperCase(),
    },
    { id: "utc", numeric: true, disablePadding: false, label: t("10111").toUpperCase() },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
    {
      id: "descriptionView",
      numeric: false,
      disablePadding: false,
      label: t("10110").toUpperCase(),
    },
  ]
  const orderByDefault = "optician.opticianId"
  const [opticianPhone, setOpticianPhone] = useState()
  const [urlAccessCode, setUrlAccessCode] = useState()
  const urlGet = `${apiUrl}/companies/${urlAccessCode}/list-users/optician`
  const urlDelete = `${apiUrl}/companies/${urlAccessCode}/users/`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.username
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 8
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.password
            ? row.viewPassword
              ? row.password
              : "*".repeat(row.password.length)
            : ""}
          <Tooltip
            title={row.viewPassword ? t("10112") : t("10113")}
          >
            <IconButton
              aria-label={
                row.viewPassword ? t("10112") : t("10113")
              }
              variant="outlined"
              onClick={() => handleClickViewPassword(rows, setRows, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {UserUtils.formatOptician(row)}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {UserUtils.formatDevice(row)}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.language ? row.language : ""}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.utc ? row.utc : row.utc === 0 ? row.utc : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell8-" + key} align="left">
          {UserUtils.formatDescription(row)}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.username,
            filter.filter((filter) => {
              return filter.id === "username"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatOptician(row),
            filter.filter((filter) => {
              return filter.id === "opticianId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatOptician(row),
            filter.filter((filter) => {
              return filter.id === "opticianName"
            })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/
  //Fetch -  Elenco devices
  const fetchDevices = useCallback(() => {
    const url = `${apiUrl}/brands?excludeSoftware=true`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ brands }) => {
        setAllBrandsDevices(brands)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("25"), err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, t])

  //Fetch -  Dati optician per numero di telefono
  const fetchOpticianId = useCallback(
    (username, rows, setData, setActionFetch) => {
      let opticianId
      let appOptician
      let opticianToFetch

      opticianToFetch = rows.filter(function (elem) {
        return elem.username === username
      })[0]
      opticianId = opticianToFetch.optician.opticianId
      appOptician = opticianToFetch

      const url = `${apiUrl}/companies/${urlAccessCode}/opticians/${opticianId}`

      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setOpticianPhone(data.phone)
          setData(appOptician)
          setActionFetch(false)
        })
        .catch((err) => {
          ErrorUtils.errorLog(t("26"), err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token, urlAccessCode, t]
  )

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    fetchDevices()
  }, [fetchDevices])

  return (
    <div>
      <FilterCompanyWithOpticians
        urlAccessCode={urlAccessCode}
        setUrlAccessCode={setUrlAccessCode}
        companyName={companyName}
        companyId={companyId}
        reducedCompany={true}
        setCompanyName={setCompanyName}
        setCompanyId={setCompanyId}
        setCompanyLanguage={setCompanyLanguage}
        setChangeCompany={setChangeCompany}
        scopeIdCompany={15}
      />

      {urlAccessCode ? (
        <AdminTable
          AddEditElement={OpticianUser}
          AdminTableCells={AdminTableCells}
          allBrandsDevices={allBrandsDevices}
          changeCompany={changeCompany}
          companyId={companyId}
          companyName={companyName}
          companyLanguage={companyLanguage}
          createData={createData}
          defaultRowsPerPage={10}
          filter={filter}
          FilterAdminTableCells={FilterAdminTableCells}
          getAdminTableKey={getAdminTableKey}
          getAdminTableCellsCount={getAdminTableCellsCount}
          handleClickOpenAction={fetchOpticianId}
          handleClickViewPassword={handleClickViewPassword}
          isFullTable={true}
          headCells={headCells}
          orderByDefault={orderByDefault}
          phone={opticianPhone}
          remapDeleteRow={remapDeleteRow}
          remapGetRows={remapGetRows}
          setChangeCompany={setChangeCompany}
          urlAccessCode={urlAccessCode}
          urlDelete={urlDelete}
          urlGet={urlGet}
          useCompany={true}
        />
      ) : null}
    </div>
  )
}
