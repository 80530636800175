/*eslint-disable*/
import React, { useContext } from 'react'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { RootContext } from '../../RootContext'
const useStyles = makeStyles(styles);


export default function LoginFooter(props) {
  const classes = useStyles();
  const { t, publicUrl } = useContext(RootContext)
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <span>
          <p>
          Copyright {1900 + new Date().getYear()} Pegaso Informatica Venezia
          </p>
          <p>Sito: <a href={'http://www.pegasovenezia.com'}>{"www.pegasovenezia.com"}</a>
           - Mail: <a href={'mailto:info@pegasovenezia.com'}> {"info@pegasovenezia.com"} </a></p>
          <p>Tel: {" (+39) 041 3094100"} - Cell:  {"(+39) 349 3105314"} </p>
          <p>
          <a href={publicUrl + '/resources/SupremoPegaso.exe'} target="_blank" download='SupremoPegaso.exe' >{t("10061")}</a>
          </p>
        </span>
      </div>
    </footer>
  );
}
