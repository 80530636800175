//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"


//core components

import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from '@material-ui/core/TextField';

//pegaso/views/components
import {
  AdminTableElevationScroll,
} from "components/AdminTable/AdminTableEditScreen"
import FilterScopeById from "components/Scope/FilterScopeById"

//import CSS
import { useAdminTableEditScreenStyles } from "styles/adminTable/adminTableEditScreenCss"


export default function FilterScope(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    adminTableRows,
    allScopes,
    creation,
    handleClickClose,
    isHub,
    scopesJSON,
    setScopesJSON,
    setAdminTableRows,
    userScope,
  } = props
  //Cerca i dati dello scope selezionato
  function searchSelectedScopeData(scopeId) {
    let selected = allScopes.filter((option) => {
      return option.scopeTypeId === scopeId
    })[0]
    return selected
  }

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()

  //Variabili
  const [scopeTypeId, setScopeTypeId] = useState(
    (creation || !userScope) ? "" : userScope.scopeFull.scopeType.scopeTypeId
  )
  const [selectedScopeData, setSelectedScopeData] = useState(creation ? [] : searchSelectedScopeData(userScope.scopeFull.scopeType.scopeTypeId))

  var editableScope = true
  if (isHub && !creation) {
    editableScope = false
  }


  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia scope utente
  const handleChangeScopeId = (event) => {
    let value, selected
    value = event.target.value
    setScopeTypeId(value)
    selected = allScopes.filter((option) => {
      return option.scopeTypeId === value
    })[0]
    setSelectedScopeData(selected)
  }



  return (
    <div>
      <AdminTableElevationScroll
        {...props}
        handleClickClose={handleClickClose}
      />

      <Grid container spacing={2}>
        <Grid className={classes.gridCurrentValues} item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p align="center">
                <u>{t("10058").toUpperCase()}</u>
              </p>
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                id="scopeId"
                label={t("10060")}
                value={scopeTypeId}
                disabled={!editableScope}
                onChange={handleChangeScopeId}
                helperText={t("10060")}

              >
                {allScopes.map((option) => (
                  <MenuItem key={option.scopeTypeId} value={option.scopeTypeId}>
                    {option.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <FilterScopeById
              adminTableRows={adminTableRows}
              creation={creation}
              handleClickClose={handleClickClose}
              scopesJSON={scopesJSON}
              userScope={userScope}
              scopeTypeId={scopeTypeId}
              selectedScopeData={selectedScopeData}
              setAdminTableRows={setAdminTableRows}
              setSelectedScopeData={setSelectedScopeData}
              setScopesJSON={setScopesJSON}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>


  )
}