//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"

//import CSS
import { useAdminTableEditScreenStyles } from "styles/adminTable/adminTableEditScreenCss"


//Componenti Pegaso
import DownloadOption from "../DeviceOption/DownloadOption"
import OptionDevice from "../DeviceOption/OptionDevice"

export default function Software(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    deviceProtocol,
    selectedDeviceJSON,
    setChangeDevice,
    setSelectedDeviceJSON,
  } = props

  //Valori modificabili
  const [downloadLabel, setDownloadLabel] = useState(deviceProtocol.options.download.omaLabels)
  const [downloadOption, setDownloadOption] = useState(deviceProtocol.options.download)
  const [optionEdger, setOptionEdger] = useState({
    doLogReceiveData: deviceProtocol.options.doLogReceiveData,
    idProtocol: deviceProtocol.id,
    ignorePinChanged: deviceProtocol.options.ignorePinChanged,
    jobWithoutBarcode: deviceProtocol.options.jobWithoutBarcode,
    offsetMetal: deviceProtocol.options.offsetMetal,
    offsetOther: deviceProtocol.options.offsetOther,
    offsetPlastic: deviceProtocol.options.offsetPlastic,
    nack: deviceProtocol.options.nack,
    singleSide: deviceProtocol.options.singleSide,
    tracerId: deviceProtocol.options.tracerId,
    withoutBarcode: deviceProtocol.options.withoutBarcode,
  })
  const [openPanelOption, setOpenPanelOption] = useState(false)
  

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()

  //SAVE MODIFICHE 
  const handleEditOption = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      protocol: {
        id: optionEdger.idProtocol,
        options: {
          download: downloadOption,
          doLogReceiveData: optionEdger.doLogReceiveData,
          ignorePinChanged: optionEdger.ignorePinChanged,
          jobWithoutBarcode: optionEdger.jobWithoutBarcode,
          nack: optionEdger.nack,
          offsetMetal: optionEdger.offsetMetal,
          offsetOther: optionEdger.offsetOther,
          offsetPlastic: optionEdger.offsetPlastic,
          singleSide: optionEdger.singleSide,
          tracerId: optionEdger.tracerId,
          //upload: uploadOption,
          withoutBarcode: optionEdger.withoutBarcode,
        }
      }
    })
    setOpenPanelOption(false)
  }

  

  //Chiude il pannello contenten i dati
  const changeOpenPanelOption = (event,) => {
    var value = !openPanelOption
    setOpenPanelOption(value)
  }


  return (
    <div>
      <Accordion expanded={openPanelOption} onChange={changeOpenPanelOption}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("10013")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <OptionDevice
              changeDevice={changeDevice}
              optionDevice={optionEdger}
              setChangeDevice={setChangeDevice}
              setOpenPanelOption={setOpenPanelOption}
              setOptionDevice={setOptionEdger}
            />

            <Typography className={classes.heading}>{t("10014")}</Typography>
            <DownloadOption
              changeDevice={changeDevice}
              downloadLabel={downloadLabel}
              downloadOption={downloadOption}
              setChangeDevice={setChangeDevice}
              setDownloadLabel={setDownloadLabel}
              setDownloadOption={setDownloadOption}
              setOpenPanelOption={setOpenPanelOption}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEditOption}
                >
                  {t("10016")}
                </Button>
              </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}