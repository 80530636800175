import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import ErrorUtils from "components/Tools/ErrorUtils"
import ExportConfigXml from "components/Xml/ExportConfigXml"
import { fetchWithToken } from "components/Fetch/api-fetch"

//core components
import { CircularIndeterminate } from "views/Loading"
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';


export default function HubExportConfig(props) {
  const {
    handleClickClose,
    hubId,
    urlAccessCode,
  } = props
  const {
    apiUrl,
    access_token,
    brokerPrefix,
    getLanguage,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Variabili
  const [dataURI, setDataURI] = useState()
  const language = languageForConfig(getLanguage())

  /*************************************
   *
   * Functions
   *
   *************************************/
  // PrepareConfigXML - Crea tutte le righe del file XML
  const PrepareConfigXML = useCallback((clientId, cryptedPassword) => {
    const contentXml = ExportConfigXml(apiUrl, brokerPrefix, urlAccessCode, hubId, clientId, cryptedPassword, language)
    const toWriteXml = "data:text/plain;base64," + encodeBase64(contentXml);
    setDataURI(toWriteXml)
  }, [apiUrl, brokerPrefix, hubId, urlAccessCode, language])


  /*************************************
   *
   * Fetch
   *
   *************************************/
  //Fetch -  Prepara password x config
  const fetchConfigPassword = useCallback((clientId) => {
    const url = `${apiUrl}/companies/${urlAccessCode}/hubs/${hubId}/encrypt-cs`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        const cryptedPassword = response.body
        if (cryptedPassword) {
          if (cryptedPassword !== "") {
            PrepareConfigXML(clientId, cryptedPassword)
          }
        }
      })
      .catch((err) => {
        ErrorUtils.errorLog("fetchConfigPassword  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, PrepareConfigXML, hubId, urlAccessCode])

  //Fetch -  Prepara Hub x config
  const fetchHubForConfig = useCallback(() => {
    const url = `${apiUrl}/companies/${urlAccessCode}/hubs/${hubId}`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ clientId }) => {
        fetchConfigPassword(clientId)
      })
      .catch((err) => {
        ErrorUtils.errorLog("fetchHubForConfig  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, fetchConfigPassword, hubId, urlAccessCode])

  //Prepara la lingua per il config
  function languageForConfig(language) {
    switch (language) {
      case "it-it":
        return "it"
      case "en-en":
      case "gr-gr":
        return "en"
      default:
        return "en"
    }

  }

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    if (dataURI) {
      saveAs(dataURI, "MiniNotifyIcon.exe.config");
      handleClickClose();
    } else {
      fetchHubForConfig()
    }
  }, [dataURI, fetchHubForConfig, handleClickClose])

  return <CircularIndeterminate />;
}