import pako from 'pako'
import ErrorUtils from './ErrorUtils'

export default class CompressUtils {
    // Zlib - Decompress a string
    static zlibDecompress(toDecompress) {
        try {
            // Convert binary string to character-number array
            var charData = toDecompress.split('').map(function (x) { return x.charCodeAt(0); });
            // Turn number array into byte-array
            var binData = new Uint8Array(charData);
            // Pako magic
            var data = pako.inflate(binData);
            // Convert gunzipped byteArray back to ascii string:
            var decompressed = String.fromCharCode.apply(null, new Uint16Array(data));
            // Output to console
            return decompressed;
        } catch (err) {
            ErrorUtils.errorLog('Uncompression Error', err)
            return ""
        }
    }

    // Zlib - Compress a string
    static zlibCompress(toDecompress) {
        try {
            // Convert binary string to character-number array
            var charData = toDecompress.split('').map(function (x) { return x.charCodeAt(0); });
            // Turn number array into byte-array
            var binData = new Uint8Array(charData);
            // Pako magic
            var data = pako.deflate(binData);
            // Convert gunzipped byteArray back to ascii string:
            var decompressed = String.fromCharCode.apply(null, new Uint16Array(data));
            // Output to console
            return decompressed;
        } catch (err) {
            ErrorUtils.errorLog('Uncompression Error', err)
            return ""
        }   
    }
}