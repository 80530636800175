import merge from 'lodash/merge'

// fetchJSON is bundled wrapper around fetch which simplifies working
// with JSON API:
//   * Automatically adds Content-Type: application/json to request headers
//   * Parses response as JSON when Content-Type: application/json header is
//     present in response headers
//   * Converts non-ok responses to errors
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch'

var local = {
  apiUrl: null,
  companyId: null,
  access_token: null,
  refresh_token: null,
  setAccess_token: null,
  streaming: false,
};

// Provide your favorite token saving -- to cookies, local storage, ...
function retrieveToken() {
  var result = local.access_token;
  return result;
}
const saveToken = (newToken) => {
  local.access_token = newToken;
  sessionStorage.setItem('access_token', newToken)
  if(local.setAccess_token !== null) local.setAccess_token(newToken);
}
const clearToken = () => {
  local.access_token = null;
  //if(local.setAccess_token !== null) local.setAccess_token(null) // F5 -> Si blocca tutto se scommentato
}

// Add token to the request headers
const fetchJSONWithToken = (url, options = {}) => {
  
  if(local.apiUrl === null) local.apiUrl = options.apiUrl;
  if(local.companyId === null) local.companyId = options.companyId;
  if(local.access_token === null) local.access_token = options.access_token;
  if(local.refresh_token === null) local.refresh_token = options.refresh_token;
  if(local.setAccess_token === null) local.setAccess_token = options.setAccess_token;
  if(local.streaming === null) local.streaming = options.streaming;

  const token = retrieveToken()
  let optionsWithToken = options
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${retrieveToken()}`
      }
    })
  }

  if(local.streaming !== null){
    if( options.streaming){
      // Read in streaming
      return fetch(url, optionsWithToken)
    }
  }
  // Read normal json
  return fetchJSON(url, optionsWithToken)
}

// Add token to the request headers
const fetchJSONWithNoToken = (url, options = {}) => {
  return fetchJSON(url, options)
}

// Decide whether this error returned from API means that we want
// to try refreshing the token. error.response contains the fetch Response
// object, error.body contains the parsed JSON response body
const shouldRefreshToken = (error) => {
  return error.response && error.body
    ? (error.response.status === 401 && error.body.error === "token_expired") || 
    (error.response.status === 401 && error.body.error === "invalid_header") || 
    (error.response.status === 500 && error.body.code === "E13")
    : false
}

// Do the actual token refreshing and update the saved token
const refreshToken = () => {

  return fetchJSONWithNoToken(`${local.apiUrl}/admin-refresh`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${local.refresh_token}`
    },
  })
    .then(response => {
      var newAccessToken = response["body"]["access_token"];
      saveToken(newAccessToken)
      return newAccessToken
    })
    .catch(error => {
      clearToken()
      throw error
    })
}

export const fetchWithToken = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken,
  fetch: fetchJSONWithToken,
})