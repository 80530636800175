import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"

import DeviceUtils from "components/Utils/DeviceUtils"
import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"

//import References from "views/References/References"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import Tracer from "components/Device/Tracer"
import Blocker from "components/Device/Blocker"
import Edger from "components/Device/Edger"
import Software from "components/Device/Software"

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allBrandsDevices,
    creation,
    handleClickClose,
    setAdminTableRows,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Funzioni per settari i valori
  const portValue = (data) => {
    if (data.type !== "Software") {
      let app = data.serial.port.split("COM")
      return app[1]
    }
    return ""
  }
  const settingValue = (data) => {
    if (data.type !== "Software") {
      let appData = data.serial.settings.split(",")
      return {
        speedPort: appData[0],
        parityBit: appData[1],
        dataBit: appData[2],
        stopBit: appData[3],
      }
    }
    return {
      speedPort: "",
      parityBit: "",
      dataBit: "",
      stopBit: "",
    }
  }


  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  //Costanti tipo lenti e scope
  const deviceType = DeviceUtils.deviceType()
  const speedPortValue = DeviceUtils.speedPortValue()
  const dataBitValue = DeviceUtils.dataBitValue()
  const stopBitValue = DeviceUtils.stopBitValue()
  const parityBitValue = DeviceUtils.parityBitValue()

  //Valori dei Dati
  const [openPanel, setOpenPanel] = useState(false)
  const [comunicationPort, setComunicationPort] = useState(
    creation && adminTableRowData === "" ? 1 : portValue(adminTableRowData)
  )
  const [settings, setSettings] = useState(creation && adminTableRowData === "" ? { speedPort: "9600", parityBit: "N", dataBit: "8", stopBit: "1" } : settingValue(adminTableRowData))
  const [model, setModel] = useState(
    creation && adminTableRowData === "" ?
      {
        modelId: adminTableRows.length + 1,
        type: "",
        description: "",
        brandId: '',
        connections: [],
        serial: {
          port: "COM1",
          settings: "9600,N,8,1",
          timeout: 0,
          handshake: 0,
          readBufferSize: 409600,
          writeBufferSize: 409600,
          readTimeout: -1,
          writeTimeout: -1,
          rts: true,
          dtr: true,
          discardNull: false
        },
        allInOne: false,
        onlyShape: false
      }
      :
      {
        modelId: adminTableRowData.modelId,
        type: adminTableRowData.type,
        description: adminTableRowData.description,
        brandId: adminTableRowData.brand,
        protocol: adminTableRowData.protocol,
        serial: adminTableRowData.serial,
        allInOne: adminTableRowData.allInOne,
        onlyShape: adminTableRowData.onlyShape,
      }
  )
  const [protocol, setProtocol] = useState(creation && adminTableRowData === "" ? undefined : adminTableRowData.protocol)
  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setModel({ ...model, [name]: event.target.value })
    if (name === "type") {
      if (event.target.value === "Tracer") {
        setProtocol({
          id: "T003",
          options: {
            upload: {
              req: 2,
              trcfmtbin: 1,
              trcfmtpoints: 512,
              zfmtbin: 1,
              zfmtpoints: 512
            },
            singleSide: false,
            nack: false,
            tracerId: 5,
            withoutBarcode: false,
            jobWithoutBarcode: "",
            offsetPlastic: 0,
            offsetMetal: 0,
            offsetOther: 0,
            ignorePinChanged: false,
            doLogReceiveData: false,
          }
        }
        )
        setModel({ ...model, connections: ["serial"], [name]: event.target.value })
      }
      else if (event.target.value === "Edger") {
        setProtocol(
          {
            id: "E002",
            options: {
              download: {
                req: 1,
                trcfmtbin: 1,
                trcfmtpoints: 512,
                zfmtbin: 1,
                zfmtpoints: 512,
                omaLabels: [
                  "BEVC",
                  "BEVM",
                  "BEVP",
                  "BSIZ",
                  "CIRC",
                  "CSIZ",
                  "DBL",
                  "DO",
                  "DRILL",
                  "EPRESS",
                  "ETYP",
                  "FBFCIN",
                  "FBFCUP",
                  "FCOCIN",
                  "FCOCUP",
                  "FCRV",
                  "FCSGIN",
                  "FCSGUP",
                  "FPINB",
                  "FTYP",
                  "GDEPTH",
                  "GWIDTH",
                  "HBOX",
                  "LMATTYPE",
                  "LTYP",
                  "PINB",
                  "POLISH",
                  "VBOX",
                  "_BSHAPE",
                  "_BTFF",
                  "_CLIP",
                  "_INFJOBS",
                  "_LENSER"
                ]
              },
              singleSide: true,
              nack: true,
              tracerId: 4,
              withoutBarcode: false,
              jobWithoutBarcode: "",
              offsetPlastic: 0,
              offsetMetal: 0,
              offsetOther: 0,
              ignorePinChanged: false,
              doLogReceiveData: false,
            }
          }
        )
        setModel({ ...model, connections: ["serial", "ip"], [name]: event.target.value, ip: { address: "192.168.189.21", port: "3006", timeout: "0" } })
      }
      else if (event.target.value === "Blocker") {
        setProtocol(
          {
            id: "B003",
            options: {
              download: {
                req: 1,
                trcfmtbin: 1,
                trcfmtpoints: 512,
                zfmtbin: 1,
                zfmtpoints: 512,
                omaLabels: [
                  "AX",
                  "BSIZ",
                  "CIRC",
                  "CSIZ",
                  "CYL",
                  "DBL",
                  "DO",
                  "DRILL",
                  "FBFCIN",
                  "FBFCUP",
                  "FCOCIN",
                  "FCOCUP",
                  "FCRV",
                  "FCSGIN",
                  "FCSGUP",
                  "FTYP",
                  "HBOX",
                  "IPD",
                  "LTYP",
                  "MBD",
                  "NPD",
                  "OCHT",
                  "PRVA",
                  "PRVM",
                  "SEGHT",
                  "SPH",
                  "SWIDTH",
                  "VBOX",
                  "ZTILT",
                  "_BTFF"
                ]
              },
              upload: {
                req: 2,
                trcfmtbin: 1,
                trcfmtpoints: 512,
                zfmtbin: 1,
                zfmtpoints: 512
              },
              singleSide: true,
              nack: true,
              tracerId: 4,
              withoutBarcode: false,
              jobWithoutBarcode: "",
              offsetPlastic: 0,
              offsetMetal: 0,
              offsetOther: 0,
              ignorePinChanged: false,
              doLogReceiveData: false,
            }
          }
        )
        setModel({ ...model, connections: ["serial", "ip"], [name]: event.target.value, ip: { address: "192.168.189.21", port: "3006", timeout: "0" } })
      }
    }
  }

  const handleChangeSettings = (name) => (event) => {
    setSettings({ ...settings, [name]: event.target.value })
  }

  //Cambia field checkbox
  const handleChangeCheckbox = (name) => (event) => {
    setModel({ ...model, [name]: event.target.checked })
  }

  const handleComunicationPort = (event) => {
    let value = event.target.value
    setComunicationPort(value)
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    let serial = model.serial
    serial = {
      ...serial, settings:
        settings.speedPort +
        "," +
        settings.parityBit +
        "," +
        settings.dataBit +
        "," +
        settings.stopBit,
    }
    let brand = allBrandsDevices.filter((option) => { return option.brandId === model.brandId ? { brandId: option.brandId, brand: option.brand } : (null) })[0]
    setIsSaving(true)
    const url = `${apiUrl}/models/${model.modelId}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        modelId: creation ? adminTableRows.length + 1 : model.modelId,
        brandId: model.brandId,
        description: model.description,
        type: model.type,
        connections: [
          "serial",
          "ip"
        ],
        protocol: model.protocol,
        serial: serial,
        ip: model.ip,
        allInOne: model.allInOne,
        onlyShape: model.onlyShape,
      }),
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10204")}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        let newElem = {
          modelId: creation ? adminTableRows.length + 1 : model.modelId,
          brand: brand,
          description: model.description,
          type: model.type,
          connections: [
            "serial"
          ],
          protocol: model.protocol,
          serial: model.serial,
          ip: model.ip,
          onlyShape: model.onlyShape,
          allInOne: model.allInOne,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.modelId === model.modelId) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("24"), err)
        setDialogMsg(t("24"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }


  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result = !model.description || !model.brandId
    return result
  }

  //Chiude il pannello contenten i dati della porta seriale
  const changeOpenPanel = (event) => {
    var value = !openPanel
    setOpenPanel(value)
  }
  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10207").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DEL DEVICE*/}

                {/*RIGA 1*/}

                <Grid item xs={3}>
                  <TextField
                    select
                    id={"brands"}
                    label={t("10044")}
                    value={model.brandId}
                    margin="normal"
                    onChange={handleChange("brandId")}
                  >
                    {allBrandsDevices
                      .sort(function compare(a, b) {
                        if (a.brand < b.brand) {
                          return -1
                        }
                        if (a.brand > b.brand) {
                          return 1
                        }
                        return 0
                      })
                      .map((option) => (
                        <MenuItem key={option.brandId} value={option.brandId}>
                          {option.brand}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id={"modelType"}
                    label={t("10205")}
                    value={model.type}
                    margin="normal"
                    onChange={handleChange("type")}
                  >
                    {
                      deviceType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id={"description"}
                    label={t("10206")}
                    onChange={handleChange("description")}
                    className={classesText.textField}
                    value={model.description || ""}
                    margin="normal"
                  />
                </Grid>
                {
                  model.type === "Blocker" ? (
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={model.allInOne}
                            onChange={handleChangeCheckbox("allInOne")}
                            name="allInOne"
                          />
                        }
                        label={t("10269")}
                      />
                    </Grid>

                  ) : (null)
                }

                <Grid item xs={model.type === "Blocker" ? 1 : 2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={model.onlyShape}
                        onChange={handleChangeCheckbox("onlyShape")}
                        name="onlyShape"
                      />
                    }
                    label={t("10282")}
                  />
                </Grid>

                {
                  model.type === "Tracer" ? (
                    <Tracer
                      deviceProtocol={protocol}
                      isCreationModel={true}
                      selectedDeviceData={model}
                      selectedDeviceJSON={model}
                      setDeviceProtocol={setProtocol}
                      setSelectedDeviceJSON={setModel}
                    />
                  ) : (null)
                }
                {
                  model.type === "Blocker" ? (
                    <Blocker
                      deviceProtocol={protocol}
                      isCreationModel={true}
                      selectedDeviceData={model}
                      selectedDeviceJSON={model}
                      setDeviceProtocol={setProtocol}
                      setSelectedDeviceJSON={setModel}
                    />
                  ) : (null)
                }
                {
                  model.type === "Edger" ? (
                    <Edger
                      deviceProtocol={protocol}
                      isCreationModel={true}
                      selectedDeviceData={model}
                      selectedDeviceJSON={model}
                      setDeviceProtocol={setProtocol}
                      setSelectedDeviceJSON={setModel}
                    />
                  ) : (null)
                }
                {
                  model.type === "Software" ? (
                    <Software
                      deviceProtocol={protocol}
                      selectedDeviceJSON={model}
                      setDeviceProtocol={setProtocol}
                      setSelectedDeviceJSON={setModel}
                    />
                  ) : (null)
                }
                {
                  model.type !== "Software" ? (
                    <Grid item xs={12}>
                      {
                        model.type !== "" ? (
                          <Accordion expanded={openPanel} onChange={changeOpenPanel}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>{t("10201")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Card>
                                <Grid
                                  container
                                  bgcolor="background.paper"
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center"
                                    >
                                      <Grid item xs={3}>
                                        <TextField
                                          id={"comunicationPort"}
                                          label={t("10050")}
                                          className={classesText.textField}
                                          value={comunicationPort}
                                          helperText={t("10051")}
                                          margin="normal"
                                          onChange={handleComunicationPort}
                                        //onBlur={onBlurComunicationPort}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          id="speedPort"
                                          label={"speedPort"}
                                          value={settings.speedPort}
                                          onChange={handleChangeSettings("speedPort")}
                                          helperText={t("10052")}
                                        >
                                          {speedPortValue.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>

                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          id="parityBit"
                                          label={t("10053")}
                                          value={settings.parityBit}
                                          onChange={handleChangeSettings("parityBit")}

                                          helperText={t("10053")}
                                        >
                                          {parityBitValue.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>

                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          id="dataBit"
                                          label={t("10203")}
                                          value={settings.dataBit}
                                          onChange={handleChangeSettings("parityBit")}
                                          helperText={t("10203")}
                                        >
                                          {dataBitValue.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>

                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          id="stopBit"
                                          label={t("10055")}
                                          value={settings.stopBit}
                                          onChange={handleChangeSettings("stopBit")}
                                          helperText={t("10055")}
                                        >
                                          {stopBitValue.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="flex-end"
                                  p={1}
                                  className={classes.gridToolbar}
                                >
                                  <Box p={1}>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={changeOpenPanel}
                                    >
                                      {t("10016")}
                                    </Button>
                                  </Box>
                                </Box>
                              </Card>
                            </AccordionDetails>
                          </Accordion>
                        ) : (null)
                      }
                    </Grid>) : null
                }
              </Grid>
            </Grid>
          </Grid>




          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div >
  )
}

// Griglia interna alla finestra del Company
export default function Model(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
