import React, { useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from "@material-ui/core/styles"

import {
  AdminTableElevationScroll,
} from "components/AdminTable/AdminTableEditScreen"

//import CSS

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allScopes,
    creation,
    handleClickClose,
    notFullTableData,
    setAdminTableRows,
    setScopesJSON
  } = props
  //Classes utilizzate per CSS

  const {
    t,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  const routing = (allScopes[0].scopes[0].defaultSettings.routings)
  routing.sort(function (a, b) {
    if (a.supplierCode >= b.supplierCode) {
      return 1
    } else {
      return -1
    }
  })
  const [supplierData, setSupplierData] = useState(creation ?
    {
      supplier: "",
      supplierCode: "",
      clientId: "",
      serialNumber: "",
      companyCode: "",
    } : adminTableRowData
  )
  var isFullRouting = false

  if (routing.length === adminTableRows.length) {
    isFullRouting = true
  }

  //Selezione il supplier a cui inviare il Job
  const handleChangeSupplier = (event) => {
    let route = routing.filter((option) => { return option.supplier === event.target.value })[0]
    setSupplierData({ ...supplierData, supplier: route.supplier, supplierCode: route.supplierCode, companyCode: route.companyCode })
  }

  //Setta il clientID
  const handleChange = (name) => (event) => {
    setSupplierData({ ...supplierData, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (supplier, name) => (event) => {
    setSupplierData({ ...supplierData, [name]: supplier.trim() })
  }

  const handleSave = () => {
    let newAdminTableRows = creation ? adminTableRows.concat(supplierData) :
      adminTableRows.filter((elem) => { return elem.supplierCode !== supplierData.supplierCode }).concat(supplierData)
    setAdminTableRows(newAdminTableRows)
    setScopesJSON({
      ...notFullTableData, labScopes: [{
        scopeId: 8,
        active: true,
        labSettings: { routings: newAdminTableRows }
      }]
    })
    handleClickClose()
  }



  return (
    <div>
      <AdminTableElevationScroll
        {...props}
        handleClickClose={handleClickClose}
      />
      {
        isFullRouting && creation ? (
          <div>
            <Card>
            <p align="center">
              <strong>
                {t("24")}
              </strong>
            </p>
            </Card>
          </div>
        ) : (
            <Grid container spacing={2}>
              <Grid className={classes.gridCurrentValues} item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <p align="center">
                      <u>{t("10150").toUpperCase()}</u>
                    </p>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="supplier"
                      label={"supplier"}
                      value={supplierData.supplier}
                      disabled={!creation}
                      helperText={t("10151")}
                      onChange={handleChangeSupplier}
                    >
                      {routing.map((option) => (
                        <MenuItem key={option.supplierCode} value={option.supplier}>
                          {option.supplierCode +" - "+ option.supplier}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"clientId"}
                      label={t("10148")}
                      className={classesText.textField}
                      value={supplierData.clientId}
                      margin="normal"
                      onChange={handleChange("clientId")}
                      onBlur={onBlurTrim(supplierData.clientId, "clientId")}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"serialNumber"}
                      label={t("10152")}
                      className={classesText.textField}
                      value={supplierData.serialNumber}
                      margin="normal"
                      onChange={handleChange("serialNumber")}
                      onBlur={onBlurTrim(supplierData.serialNumber, "serialNumber")}
                    />
                  </Grid>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    p={1}
                    className={classes.gridToolbar}
                  >
                    <Box p={1}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleSave}
                      >
                        {t("10010")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )
      }
    </div>
  )
}


// Griglia interna alla finestra del Device
export default function JobRoutingToSuppliers(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}