//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

//import CSS
import { useAdminTableEditScreenStyles, } from "styles/adminTable/adminTableEditScreenCss"

//Pegaso component
import SerialDevice from "components/DeviceOption/SerialDevice"
import OptionDevice from "../DeviceOption/OptionDevice"
import UploadOption from "../DeviceOption/UploadOption"

export default function Tracer(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    connectionType,
    deviceProtocol,
    isCreationModel,
    selectedDeviceData,
    selectedDeviceJSON,
    setChangeDevice,
    setSelectedDeviceJSON,
  } = props
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const [optionTracer, setOptionTracer] = useState({
    doLogReceiveData: deviceProtocol.options.doLogReceiveData,
    idProtocol: deviceProtocol.id,
    ignorePinChanged: deviceProtocol.options.ignorePinChanged,
    jobWithoutBarcode: deviceProtocol.options.jobWithoutBarcode,
    offsetMetal: deviceProtocol.options.offsetMetal,
    offsetOther: deviceProtocol.options.offsetOther,
    offsetPlastic: deviceProtocol.options.offsetPlastic,
    nack: deviceProtocol.options.nack,
    singleSide: deviceProtocol.options.singleSide,
    tracerId: deviceProtocol.options.tracerId,
    withoutBarcode: deviceProtocol.options.withoutBarcode,
  })
  const [openPanelOption, setOpenPanelOption] = useState(false)
  const [openPanelSerial, setOpenPanelSerial] = useState(false)
  const [uploadOption, setUploadOption] = useState(deviceProtocol.options.upload)
  const [serialOption, setSerialOption] = useState(isCreationModel || connectionType === "ip" ? {
    discardNull: selectedDeviceData.serial.discardNull,
    dtr: selectedDeviceData.serial.dtr,
    handshake: selectedDeviceData.serial.handshake,
    readBufferSize: selectedDeviceData.serial.readBufferSize,
    readTimeout: selectedDeviceData.serial.readTimeout,
    rts: selectedDeviceData.serial.rts,
    timeout: selectedDeviceData.serial.timeout,
    writeBufferSize: selectedDeviceData.serial.writeBufferSize,
    writeTimeout: selectedDeviceData.serial.writeTimeout
  } : {
    discardNull: selectedDeviceJSON.serial.discardNull,
    dtr: selectedDeviceJSON.serial.dtr,
    handshake: selectedDeviceJSON.serial.handshake,
    readBufferSize: selectedDeviceJSON.serial.readBufferSize,
    readTimeout: selectedDeviceJSON.serial.readTimeout,
    rts: selectedDeviceJSON.serial.rts,
    timeout: selectedDeviceJSON.serial.timeout,
    writeBufferSize: selectedDeviceJSON.serial.writeBufferSize,
    writeTimeout: selectedDeviceJSON.serial.writeTimeout
  })
  //SAVE MODIFICHE 
  const handleEditOption = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      protocol: {
        id: optionTracer.idProtocol,
        options: {
          //download: downloadOption,
          doLogReceiveData: optionTracer.doLogReceiveData,
          ignorePinChanged: optionTracer.ignorePinChanged,
          jobWithoutBarcode: optionTracer.jobWithoutBarcode,
          nack: optionTracer.nack,
          offsetMetal: parseFloat(optionTracer.offsetMetal),
          offsetOther: parseFloat(optionTracer.offsetOther),
          offsetPlastic: parseFloat(optionTracer.offsetPlastic),
          singleSide: optionTracer.singleSide,
          tracerId: optionTracer.tracerId,
          upload: uploadOption,
          withoutBarcode: optionTracer.withoutBarcode,
        }
      }
    })
    setOpenPanelOption(false)
  }

  //Salva le modifiche efettuate
  const handleEditSerial = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        ...selectedDeviceJSON.serial,
        timeout: serialOption.timeout,
        handshake: serialOption.handshake,
        readBufferSize: serialOption.readBufferSize,
        writeBufferSize: serialOption.writeBufferSize,
        readTimeout: serialOption.readTimeout,
        writeTimeout: serialOption.writeTimeout,
        rts: serialOption.rts,
        dtr: serialOption.dtr,
        discardNull: serialOption.discardNull,
      }
    })
    setOpenPanelSerial(false)
  }

  //Chiude il pannello contenten i dati
  const changeOpenPanelOption = (event) => {
    var value = !openPanelOption
    setOpenPanelOption(value)
  }


  //Chiude il pannello contenten i dati della porta seriale
  const changeOpenPanelSerial = (event) => {
    var value = !openPanelSerial
    setOpenPanelSerial(value)
  }

  return (
    <div>
      <Accordion expanded={openPanelOption} onChange={changeOpenPanelOption}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("10013")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row-reverse" justify="space-between">
            <Grid item xs={12}>
              <OptionDevice
                changeDevice={changeDevice}
                deviceProtocol={deviceProtocol}
                optionDevice={optionTracer}
                setChangeDevice={setChangeDevice}
                setOpenPanelOption={setOpenPanelOption}
                setOptionDevice={setOptionTracer}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.heading}>{t("10015")}</Typography>
              <UploadOption
                changeDevice={changeDevice}
                uploadOption={uploadOption}
                setChangeDevice={setChangeDevice}
                setUploadOption={setUploadOption}
                setOpenPanelOption={setOpenPanelOption}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEditOption}
                >
                  {t("10016")}
                </Button>
              </Box>
            </Box>
          </Grid>

        </AccordionDetails>
      </Accordion>

      {
        connectionType === "serial" ? (
          <Accordion expanded={openPanelSerial} onChange={changeOpenPanelSerial}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{t("10017")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <SerialDevice
                  changeDevice={changeDevice}
                  selectedDeviceData={selectedDeviceData}
                  serialOption={serialOption}
                  setChangeDevice={setChangeDevice}
                  setSerialOption={setSerialOption}
                  setOpenPanelSerial={setOpenPanelSerial}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  p={1}
                  className={classes.gridToolbar}
                >
                  <Box p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleEditSerial}
                    >
                      {t("10016")}
                    </Button>
                  </Box>
                </Box>
              </Card>

            </AccordionDetails>
          </Accordion>
        ) : (
          null
        )
      }
    </div>
  )
}