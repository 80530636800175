import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DatesUtils from '../Tools/DatesUtils'
import { titleCase } from "title-case";

export default function FilterDatePicker(props) {
    const { classes, defaultValue, filterField, handleChange, handleClick1, id, label, disabled } = props
    const [value, setValue] = useState(defaultValue)
    const handleChangeEvent = event => {
        const TargetValue = event === null ? null : DatesUtils.formatDateToString(event, false, false, false)
        if (handleChange) {
            handleChange(filterField, TargetValue)
        }
        setValue(TargetValue)
        if (handleClick1) {
            handleClick1()
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                id={id}
                label={titleCase(label)}
                className={classes.textField}
                clearable={true}
                value={value}
                onChange={handleChangeEvent}
                margin="normal"
                format="dd-MM-yyyy"
                orientation="landscape"
                disabled={disabled ? disabled : false}
            />
        </MuiPickersUtilsProvider>
    );
}