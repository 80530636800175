import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import FilterCompany from "components/Filter/FilterCompany"
import ErrorUtils from "components/Tools/ErrorUtils"
import LaboratoryUser from "views/LaboratoryUser/LaboratoryUser"
import UserUtils from "components/Utils/UserUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import IconButton from "@material-ui/core/IconButton"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import VisibilityIcon from "@material-ui/icons/Visibility"

// Nomi dei campi delle colonne
function createData(
  username,
  password,
  lab,
  userDevice,
  language,
  utc,
  email,
  active,
  description,
) {
  return {
    username,
    password,
    lab,
    userDevice,
    language,
    utc,
    email,
    active,
    description,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.username !== rowToDelete
  })
}

// Click View Password
const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
  setRows(
    rows.map((elem) => {
      let newElem = elem
      if (elem === rowToViewPassword) {
        newElem.viewPassword = !newElem.viewPassword
      }
      return newElem
    })
  )
}

export default function LaboratoryUsers() {
  const {
    apiUrl,
    access_token,
    getRetailer,
    refresh_token,
    setAccess_token,
    t,
  } = useContext(RootContext)

  const [allBrandsDevices, setAllBrandsDevices] = useState([])
  const [changeCompany, setChangeCompany] = useState()
  const [companyName, setCompanyName] = useState()
  const [companyId, setCompanyId] = useState()
  const [companyLanguage, setCompanyLanguage] = useState()
  const [retailer] = useState(getRetailer())
  const isIndependent = retailer ? retailer.isIndependent ? retailer.isIndependent : false : false

  const [filter] = useState([
    {
      id: "username",
      value: "",
      label: t("10062"),
    },
    {
      id: "labId",
      value: "",
      label: t("10141"),
    },
    {
      id: "labName",
      value: "",
      label: t("10284"),
    }
  ])

  const headCells = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: t("10062").toUpperCase(),
    },
    {
      id: "password",
      numeric: false,
      disablePadding: false,
      label: t("10063").toUpperCase(),
    },
    {
      id: "labView",
      numeric: false,
      disablePadding: false,
      label: t("10146").toUpperCase(),
    },
    {
      id: "deviceView",
      numeric: false,
      disablePadding: false,
      label: t("10161").toUpperCase(),
    },
    {
      id: "language",
      numeric: false,
      disablePadding: false,
      label: t("10083").toUpperCase(),
    },
    { id: "utc", numeric: true, disablePadding: false, label: t("10111").toUpperCase() },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
    { id: "descriptionView", numeric: false, disablePadding: false, label: t("10110").toUpperCase() },
  ]
  const orderByDefault = "laboratory"
  const [labPhone, setLabPhone] = useState()
  const [urlAccessCode, setUrlAccessCode] = useState()
  const urlGet = `${apiUrl}/companies/${urlAccessCode}/list-users/lab`
  const urlDelete = `${apiUrl}/companies/${urlAccessCode}/users/`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.username
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 8
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse

    const condition1 = !((keyToUse.indexOf("demo") !== -1) && isIndependent)

    return (
      condition1 ? (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.password
            ? row.viewPassword
              ? row.password
              : "*".repeat(row.password.length)
            : ""}
          <Tooltip
            title={row.viewPassword ? t("10112") : t("10113")}
          >
            <IconButton
              aria-label={
                row.viewPassword ? t("10112") : t("10113")
              }
              variant="outlined"
              onClick={() => handleClickViewPassword(rows, setRows, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {UserUtils.formatLab(row)}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {UserUtils.formatDevice(row)}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.language ? row.language : ""}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.utc ? row.utc : row.utc === 0 ? row.utc : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell8-" + key} align="left">
          {UserUtils.formatDescription(row)}
        </TableCell>
        {!isIndependent ? <CellButtonEdit keyToUse={keyToUse} disabled={isIndependent} /> : null}
        {!isIndependent ? <CellButtonDelete keyToUse={keyToUse} disabled={isIndependent} /> : null}
      </TableRow>
      ): null
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.username,
            filter.filter((filter) => { return filter.id === "username" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatLab(row),
            filter.filter((filter) => { return filter.id === "labId" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatLab(row),
            filter.filter((filter) => { return filter.id === "labName" })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/
  //Fetch -  Elenco devices
  const fetchDevices = useCallback(() => {
    const url = `${apiUrl}/brands?excludeSoftware=true`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ brands }) => {
        setAllBrandsDevices(brands)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("25"), err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, t])

  //Fetch -  Dati laboratorio per numero di telefono    
  const fetchLabId = useCallback(
    (username, rows, setData, setActionFetch) => {
      let labId
      let appLab
      let labToFetch

      labToFetch = rows.filter(function (elem) {
        return elem.username === username
      })[0]
      labId = labToFetch.lab.labId
      appLab = labToFetch

      const url = `${apiUrl}/companies/${urlAccessCode}/labs/${labId}`

      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setLabPhone(data.phone)
          setData(appLab)
          setActionFetch(false)
        })
        .catch((err) => {
          ErrorUtils.errorLog(t("23"), err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token, urlAccessCode, t]
  )

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    fetchDevices()
  }, [fetchDevices, changeCompany, setChangeCompany])

  return (
    <div>
      <FilterCompany
        urlAccessCode={urlAccessCode}
        setUrlAccessCode={setUrlAccessCode}
        companyName={companyName}
        companyId={companyId}
        setCompanyName={setCompanyName}
        setCompanyId={setCompanyId}
        setCompanyLanguage={setCompanyLanguage}
        setChangeCompany={setChangeCompany}
      />

      {urlAccessCode ? (
        <AdminTable
          AddEditElement={LaboratoryUser}
          AdminTableCells={AdminTableCells}
          allBrandsDevices={allBrandsDevices}
          changeCompany={changeCompany}
          companyId={companyId}
          companyName={companyName}
          companyLanguage={companyLanguage}
          createData={createData}
          defaultRowsPerPage={10}
          disableAdd={isIndependent}
          disableOpen={isIndependent}
          disableDelete={isIndependent}
          filter={filter}
          FilterAdminTableCells={FilterAdminTableCells}
          getAdminTableKey={getAdminTableKey}
          getAdminTableCellsCount={getAdminTableCellsCount}
          handleClickOpenAction={fetchLabId}
          handleClickViewPassword={handleClickViewPassword}
          headCells={headCells}
          isFullTable={true}
          orderByDefault={orderByDefault}
          phone={labPhone}
          remapDeleteRow={remapDeleteRow}
          remapGetRows={remapGetRows}
          setChangeCompany={setChangeCompany}
          urlAccessCode={urlAccessCode}
          urlDelete={urlDelete}
          urlGet={urlGet}
          useCompany={true}
        />
      ) : null}
    </div>
  )
}
