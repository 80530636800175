import { makeStyles } from '@material-ui/styles'


const useAutocompleteStyles = makeStyles({
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: "#C0C0C0",
        borderColor: 'transparent',
      },
    }
})

export { useAutocompleteStyles }
