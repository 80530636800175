import React from "react"


//pegaso/views/components
import FilterScope from "components/Filter/FilterScope/"

//core components
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

//import CSS

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allScopes,
    creation,
    handleClickClose,
    setAdminTableRows,
    scopesJSON,
    setScopesJSON
  } = props
  //Classes utilizzate per CSS
  
  return (
    <div>
        <FilterScope
          adminTableRows = {adminTableRows}
          allScopes={allScopes}
          creation={creation}
          isHub={true}
          handleClickClose = {handleClickClose}
          userScope={adminTableRowData}
          scopesJSON = {scopesJSON}
          setScopesJSON = {setScopesJSON}
          setAdminTableRows = {setAdminTableRows}
        />
       
    </div>
  )
}


// Griglia interna alla finestra del Device
export default function Scope(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}