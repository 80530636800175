export default class RetailerUtils {
    static getRetailerSuffixLetter = (retailerId) => {
        let result = ""
        switch(retailerId){
            case 2:
                result = "L"
                break;
            case 3:
                result = "F"
                break;
            default:
                result = ""
                break;
        }
        return result;
    }
}