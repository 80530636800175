import React, { useContext } from "react"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import Tooltip from "@material-ui/core/Tooltip"
import { RootContext } from "RootContext"

function AdminTableEditButton(props) {
  const { handleClickOpen, editButton } = props
  const { t } = useContext(RootContext)

  return (
    <div id="contenitore">
      <Tooltip title={t("10008")}>
        <IconButton
          aria-label={t("10008")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(editButton)
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AdminTableEditButton
