export default class ScopeUtils {

  //Conta il numero di scope presenti per la tabella scope
  static countScopeId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (a.hubScopeId > b.hubScopeId ? 1 : -1)
    })

    numberRows = rowsFiltered.length 
    if (numberRows > 0) {
      return rowsFiltered[numberRows-1].hubScopeId + 1
    }
    else {
      return 1
    }

  }

  //Formatta il Json per il salvataggio dell'hub
  static createJsonScopes = (hubScopes) => {
    let scope = hubScopes
    return (
      scope.map((option) => (
        {
          scopeId: option.scopeId,
          active: option.active,
          hubSettings: option.hubSettings,
        }
      ))
    )
  }

  //Formatta il json per la tabella scope
  static saveScopeJSON = (scopesJSON, setScopesJSON, creation,value,hubScopeId) => {
    if (creation) {
      setScopesJSON((scopesJSON => [...scopesJSON, value]))
    }
    else {
      let newJson = scopesJSON.map((elem) => {
        if (elem.hubScopeId === hubScopeId) {
          return value
        }
        else return elem
      })
      setScopesJSON(newJson)
    }
  }

  static checkExportSystem(scopes){
    return scopes.filter((scopes) => {
      return (scopes.scopeId === 2 || scopes.scopeId === 5 || scopes.scopeId === 6)
    })
  }

}