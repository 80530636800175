import React, { useState, useContext, } from "react"
import { RootContext, } from "RootContext"
import jsPDF from "jspdf";
import "jspdf-autotable";
import RobotoNormal from "fonts/Roboto-Regular-normal"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import FilterCompanyWithOpticians from "components/Filter/FilterCompanyWithOpticians"
import OpticianStatisticToolbar from "./OpticianStatisticToolbar";
import { stableSort, getComparator } from "components/AdminTable/AdminTableSort"

//core components
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Grid from "@material-ui/core/Grid"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

import Typography from "@material-ui/core/Typography"


// CSS
import { useAdminTableStyles } from "styles/adminTable/adminTableCss"
// Nomi dei campi delle colonne
function createData(id, licenseKey, retailer, creationDate, activationDate, active, company) {
  return {
    id,
    licenseKey,
    retailer,
    creationDate,
    activationDate,
    active,
    company
  }
}

export default function OpticianStatistics() {
  const { t, apiUrl, } = useContext(RootContext)
  const [defaultYear, setDefaultYear] = useState(new Date().getFullYear())
  const [showYear, setShowYear] = useState(new Date().getFullYear().toString().substr(-2))
  const classes = useAdminTableStyles()
  const [filter, setFilter] = useState(
    { "year": defaultYear })

  const headCells = [
    { id: "id", numeric: false, disablePadding: false, label: t("10175").toUpperCase(), },
    { id: "january", numeric: false, disablePadding: true, label: t("01/") + showYear, },
    { id: "febraury", numeric: false, disablePadding: true, label: t("02/") + showYear },
    { id: "march", numeric: false, disablePadding: true, label: t("03/") + showYear },
    { id: "april", numeric: false, disablePadding: true, label: t("04/") + showYear },
    { id: "may", numeric: false, disablePadding: true, label: t("05/") + showYear },
    { id: "june", numeric: false, disablePadding: true, label: t("06/") + showYear },
    { id: "july", numeric: false, disablePadding: true, label: t("07/") + showYear },
    { id: "august", numeric: false, disablePadding: true, label: t("08/") + showYear },
    { id: "september", numeric: false, disablePadding: true, label: t("09/") + showYear },
    { id: "october", numeric: false, disablePadding: true, label: t("10/") + showYear },
    { id: "november", numeric: false, disablePadding: true, label: t("11/") + showYear },
    { id: "december", numeric: false, disablePadding: true, label: t("12/") + showYear },
    { id: "total", numeric: false, disablePadding: true, label: t("10270").toUpperCase() },
    { id: "price", numeric: false, disablePadding: true, label: t("10271").toUpperCase() },
  ]

  const orderByDefault = "opticianId"
  const [changeCompany, setChangeCompany] = useState(true)
  const [companyName, setCompanyName] = useState()
  const [companyId, setCompanyId] = useState()
  const [companyLanguage, setCompanyLanguage] = useState()
  const [urlAccessCode, setUrlAccessCode] = useState()
  const urlGet = `${apiUrl}/companies/${urlAccessCode}/year/${defaultYear}/annualstatistic`
  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.opticianId
  }
  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 15
  }
  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse

    const styles = {
      globalRow: {
        borderRight: "2px solid rgba(224, 224, 224, 1)",
      },
      totalRow: {
        fontWeight: 'bold',
        borderTop: "5px solid rgba(224, 224, 224, 1)",
        borderRight: "2px solid rgba(224, 224, 224, 1)",
      },
    }
    let totalCost = 0

    if (!row.isTotalRow) {
      totalCost = parseFloat(row.totalMonthUsage * process.env.REACT_APP_OPTICIAN_MOUNTH_COST).toFixed(2)
    }
    else {
      totalCost = parseFloat(row.totalAnnualUsage * process.env.REACT_APP_OPTICIAN_MOUNTH_COST).toFixed(2)
    }

    return (

      !row.isTotalRow ? (
        row.isEmptyRow ? (
          <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
            <TableCell key={"tableBodyCell0-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell1-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell4-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell5-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell6-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell7-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell8-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell9-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell10-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell11-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell12-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell13-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell14-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell15-" + key} align="center" style={styles.globalRow} />
            <TableCell key={"tableBodyCell16-" + key} align="center" style={styles.globalRow} />
          </TableRow>
        ) : (
          <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
            <TableCell
              key={"tableBodyCell0-" + key}
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              style={styles.globalRow}
            >
              {key ? key + " - " + row.opticianName : ""}
            </TableCell>
            <TableCell key={"tableBodyCell1-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[0] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell4-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[1] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell5-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[2] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell6-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[3] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell7-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[4] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell8-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[5] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell9-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[6] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell10-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[7] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell11-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[8] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell12-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[9] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell13-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[10] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell14-" + key} align="center" style={styles.globalRow}>
              {row.annualStatistics[11] > 0 ? "X" : ""}
            </TableCell>
            <TableCell key={"tableBodyCell15-" + key} align="center" style={styles.globalRow}>
              {row.totalMonthUsage > 0 ? row.totalMonthUsage : ""}
            </TableCell>
            <TableCell key={"tableBodyCell16-" + key} align="center" style={styles.globalRow}>
              {row.totalMonthUsage > 0 ? totalCost + " €" : ""}
            </TableCell>
          </TableRow>
        )
      ) : (


        <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
          <TableCell
            key={"tableBodyCell0-" + key}
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            style={styles.totalRow}
          >
            {t("10272")}
          </TableCell>
          <TableCell key={"tableBodyCell1-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell4-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell5-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell6-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell7-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell8-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell9-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell10-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell11-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell12-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell13-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell14-" + key} align="center" style={styles.totalRow} />
          <TableCell key={"tableBodyCell15-" + key} align="center" style={styles.totalRow}>{row.totalAnnualUsage}</TableCell>
          <TableCell key={"tableBodyCell16-" + key} align="center" style={styles.totalRow}>
            {totalCost + " €"}
          </TableCell>
        </TableRow>
      )
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows, order, orderBy) {
    var appRows = rows
    var cloneRowsJson = JSON.parse(JSON.stringify(rows));
    var totalAnnualUsage = 0
    appRows.forEach(element => {
      totalAnnualUsage += element.totalMonthUsage;
    });
    cloneRowsJson = stableSort(appRows, getComparator(order, orderBy))
    cloneRowsJson.push({ "isEmptyRow": true })
    cloneRowsJson.push({ "opticianId": 'total', "isTotalRow": true, "totalAnnualUsage": totalAnnualUsage, })
    return cloneRowsJson
  }

  //Funzione creare il file PDF.
  function exportPDF(rows, order, orderBy) {
    var data
    const unit = "pt"

    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);
  
    doc.setFontSize(15);
    const title = `${(t("10273").toUpperCase())} ${defaultYear} (${companyId} - ${companyName})`
    const headers = [headCells.map((option) => {
      return option.label.toUpperCase()
    })]

    let tableFiltered = FilterAdminTableCells(rows)
    tableFiltered = stableSort(tableFiltered, getComparator(order, orderBy))
    tableFiltered.push(tableFiltered.splice(tableFiltered.map(e => { return e.opticianId }).indexOf('totalOpt'), 1)[0])
    data = tableFiltered.map(elt => {
      if (elt.isEmptyRow) {
        return ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
      }
      else if (!elt.isTotalRow) {
        return [elt.opticianId + " - " + elt.opticianName,
        elt.annualStatistics[0] > 0 ? "X" : "",
        elt.annualStatistics[1] > 0 ? "X" : "",
        elt.annualStatistics[2] > 0 ? "X" : "",
        elt.annualStatistics[3] > 0 ? "X" : "",
        elt.annualStatistics[4] > 0 ? "X" : "",
        elt.annualStatistics[5] > 0 ? "X" : "",
        elt.annualStatistics[6] > 0 ? "X" : "",
        elt.annualStatistics[7] > 0 ? "X" : "",
        elt.annualStatistics[8] > 0 ? "X" : "",
        elt.annualStatistics[9] > 0 ? "X" : "",
        elt.annualStatistics[10] > 0 ? "X" : "",
        elt.annualStatistics[11] > 0 ? "X" : "",
        elt.totalMonthUsage > 0 ? elt.totalMonthUsage : "",
        elt.totalMonthUsage > 0 ? parseFloat(elt.totalMonthUsage * process.env.REACT_APP_OPTICIAN_MOUNTH_COST).toFixed(2) + " €" : ""
        ]
      }
      else {
        return [t("10272").toUpperCase(),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          elt.totalAnnualUsage > 0 ? elt.totalAnnualUsage : "",
          parseFloat(elt.totalAnnualUsage * process.env.REACT_APP_OPTICIAN_MOUNTH_COST).toFixed(2) + " €",
        ]
      }
    })

    doc.text(title, marginLeft, 30)
    doc.setFontSize(10)
    doc.text(t("10274") + " " + process.env.REACT_APP_OPTICIAN_MOUNTH_COST + "€", marginLeft, 50)

    // 2024/12/27 - Per caratteri Greci
    doc.addFileToVFS("Roboto-Regular-normal.ttf", RobotoNormal);
    doc.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal");
    doc.setFont("Roboto-Regular", "normal");
    doc.autoTable({
      head: headers,
      body: data,
      margin: { horizontal: marginLeft },
      startY: 60,
      startX: 20,
      theme: 'grid',
      showHead: true,
      bodyStyles: {
        halign: 'center',
        font: 'Roboto-Regular',
      },
      columnStyles: { 0: { halign: 'left' } },
      /*didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          // data.cell.styles.fillColor = [239, 154, 154];
          //data.cell.styles.fontStyle = 'bold';
        }
      }*/

    });
    doc.save(title + ".pdf")
  }


  // Click when change data con Filter
  function handleChangeFilter(filterField, targetValue) {
    setFilter({ ...filter, [filterField]: targetValue, loading: false });
    if (filterField === "year") {
      setDefaultYear(targetValue)
      setShowYear(targetValue)
      setChangeCompany(true)
    }
  }


  return (
    <div>
      <FilterCompanyWithOpticians
        urlAccessCode={urlAccessCode}
        setUrlAccessCode={setUrlAccessCode}
        companyName={companyName}
        companyId={companyId}
        reducedCompany={true}
        setCompanyName={setCompanyName}
        setCompanyId={setCompanyId}
        setCompanyLanguage={setCompanyLanguage}
        setChangeCompany={setChangeCompany}
        scopeIdCompany={15}
      />
      {
        changeCompany ? (null) :
          (
            <div>
              <Card>
                <CardBody>
                  <Grid container justify="space-between">
                    <Grid item xs={3}>
                      {t("10274") + " " + process.env.REACT_APP_OPTICIAN_MOUNTH_COST + "€"}
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{t("10000")}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Card>
                    <CardHeader color="primary">
                      <OpticianStatisticToolbar
                        filter={filter}
                        // handleCancelFetch={handleCancelFetch}
                        handleChangeFilter={handleChangeFilter}
                      />
                    </CardHeader>
                  </Card>
                </AccordionDetails>
              </Accordion>
            </div>
          )
      }
      {urlAccessCode ? (
        <AdminTable
          AdminTableCells={AdminTableCells}
          createData={createData}
          changeCompany={changeCompany}
          companyId={companyId}
          companyName={companyName}
          companyLanguage={companyLanguage}
          disableAdd={true}
          disableOpen={true}
          disableDelete={true}
          downloadData={true}
          defaultRowsPerPage={10}
          exportPDF={exportPDF}
          filterToolbarExpaned={false}
          filter={filter}
          FilterAdminTableCells={FilterAdminTableCells}
          headCells={headCells}
          isFullTable={true}
          isCustomFilter={true}
          getAdminTableKey={getAdminTableKey}
          getAdminTableCellsCount={getAdminTableCellsCount}
          setChangeCompany={setChangeCompany}
          skipOrderTableFilterd={true}
          orderByDefault={orderByDefault}
          urlAccessCode={urlAccessCode}
          urlGet={urlGet}
          useCompany={true}
        />
      ) : null}
    </div>
  )
}

