const en = {
  translation: {
    1    : "no results found with these filters",
    2    : "the diameter must be greater than 1",
    3    : "the reduced diameter must be numerical",
    4    : "the value of the sphere must be numerical",
    5    : "the value of the sphere is incorrect",
    6    : "value of the sphere out of bounds (-30 / +30)",
    7    : "the cylinder value must be numeric",
    8    : "the cylinder value is incorrect",
    9    : "cylinder value out of limit (-10 / +10)",
    10   : "the axis value must be numeric",
    11   : "the value of the axis must be positive",
    12   : "the axis value must be less than 360",
    13   : "the axis value must be an integer",
    14   : "the value must be numeric",
    15   : "the value must be positive",
    16   : "the value must be greater than",
    17   : "the value must be less than",
    18   : "the value must be between -10 and 10",
    19   : "the diameter must be less than 99",
    20   : "operation failed",
    21   : "the value must be an integer",
    22   : "error save",
    23   : "fetch lab id error",
    24   : "impossible to add new route (no other supplier available)",
    25   : "fetch devices error",
    26   : "fetch optician error",
    27   : "fetch optician id error",
    28   : "user saved error",
    29   : "create demoOptician user",   
    30   : "create demoLab user",        
    31   : "fetch retailers error",  
    32   : "no license assigned", 
    5000 : "aspheric",
    5001 : "toric",
    5002 : "bifocal",
    5003 : "curve top",
    5004 : "double segment",
    5005 : "e-line multifocal",
    5006 : "flat top",
    5007 : "lenticular",
    5008 : "progressive",
    5009 : "quadra focal",
    5010 : "round",
    5011 : "single vision",
    5012 : "trifocal",
    10000: "filters",
    10001: "add new",
    10002: "update list",
    10003: "attention",
    10004: "confirm delete",
    10005: "cancel",
    10006: "confirm",
    10007: "delete",
    10008: "edit",
    10009: "click to close",
    10010: "save",
    10011: "add company logo",
    10012: "search",
    10013: "advanced settings protocol",
    10014: "option download",
    10015: "option upload",
    10016: "save edit",
    10017: "advanced settings serial",
    10018: "ip protocol",
    10019: "job without barcode",
    10020: "nack",
    10021: "offset metal",
    10022: "offset plastic",
    10023: "single side",
    10024: "tracer id",
    10025: "without barcode",
    10026: "req",
    10027: "trcfmtbin",
    10028: "trcfmtpoints",
    10029: "zfmtbin",
    10030: "zfmtpoints",
    10031: "selected",
    10032: "timeout",
    10033: "handshake",
    10034: "readBufferSize",
    10035: "writeBufferSize",
    10036: "readTimeout",
    10037: "writeTimeout",
    10038: "rts",
    10039: "dtr",
    10040: "discardNull",
    10041: "companies",
    10042: "select company",
    10043: "device",
    10044: "brands",
    10045: "select brand",
    10046: "name device",
    10047: "active",
    10048: "serial",
    10049: "lan (tcp/ip)",
    10050: "comunication port",
    10051: "enter only the port number",
    10052: "speed port",
    10053: "parity bit",
    10054: "data bit",
    10055: "stop bit",
    10056: "ip address (PC)",
    10057: "ip port",
    10058: "scope",
    10059: "offset other",
    10060: "select scope",
    10061: "remote support",
    10062: "username",
    10063: "password",
    10064: "login",
    10065: "user",
    10066: "profile",
    10067: "logout",
    10068: "scope data",
    10069: "system",
    10070: "select system",
    10071: "path",
    10072: "topic id",
    10073: "file name type",
    10074: "extension",
    10075: "include subdirectories",
    10076: "internal buffer size",
    10077: "comunication port",
    10078: "enter only the port number",
    10079: "name",
    10080: "english",
    10081: "italian",
    10082: "printer name",
    10083: "language",
    10084: "barcode",
    10085: "none",
    10086: "even",
    10087: "odd",
    10088: "bevel",
    10089: "groove",
    10090: "rimless",
    10091: "automatic",
    10092: "bevp2",
    10093: "50/50",
    10094: "bevp5",
    10095: "bevp1",
    10096: "yes",
    10097: "no",
    10098: "plastic",
    10099: "metal",
    10100: "rimless",
    10101: "optyl",
    10102: "user saved",
    10103: "admin user",
    10104: "data",
    10105: "read only",
    10106: "select language",
    10107: "select utc",
    10108: "email",
    10109: "demo",
    10110: "description",
    10111: "utc",
    10112: "hide password",
    10113: "view password",
    10114: "company id",
    10115: "company jobd",
    10116: "url access code",
    10117: "company name",
    10118: "company url",
    10119: "company saved",
    10120: "company data",
    10121: "street",
    10122: "street number",
    10123: "city",
    10124: "province",
    10125: "state",
    10126: "zip code",
    10127: "vat number",
    10128: "email",
    10129: "reference",
    10130: "ico",
    10131: "png",
    10132: "logo saved",
    10133: "comapany logo",
    10134: "logo extension",
    10135: "device id",
    10136: "model id",
    10137: "model",
    10138: "brand",
    10139: "type",
    10140: "connection",
    10141: "lab id",
    10142: "not exist",
    10143: "hub saved",
    10144: "hub user data",
    10145: "company",
    10146: "lab",
    10147: "hub id",
    10148: "client id",
    10149: "phone",
    10150: "job routing to suppliers",
    10151: "supplier",
    10152: "serial number",
    10153: "supplier code",
    10154: "company code",
    10155: "max internal job id",
    10156: "internal opticianId",
    10157: "lab saved",
    10158: "lab data",
    10159: "job routing to suppliers table",
    10160: "lab user data",
    10161: "user device",
    10162: "code",
    10163: "address",
    10164: "price list",
    10165: "optician list",
    10166: "save",
    10167: "search optician",
    10168: "empty data source message",
    10169: "add",
    10170: "are you sure delete this row?",
    10171: "rows per page",
    10172: "previous page",
    10173: "next page",
    10174: "duplicated optician",
    10175: "optician",
    10176: "chief",
    10177: "technician",
    10178: "ceo",
    10179: "role",
    10180: "person",
    10181: "mobile phone",
    10182: "id person",
    10183: "hub scope id",
    10184: "open",
    10185: "closed",
    10186: "wip",
    10187: "job",
    10188: "date",
    10189: "recent job",
    10190: "new jobs arrived in laboratory",
    10191: "jobs of the year",
    10192: "list of the jobs of the last year",
    10193: "jobs of the week",
    10194: "increased jobs from last week",
    10195: "jobs of the day",
    10196: "list of the jobs inserted today",
    10197: "edit profile",
    10198: "complete your profile",
    10199: "updated",
    10200: "purchased on",
    10201: "advanced connection",
    10202: "clone",
    10203: "data bit",
    10204: "model saved",
    10205: "model type",
    10206: "model name",
    10207: "model data",
    10208: "optician id",
    10209: "optician user data",
    10210: "tracer",
    10211: "edger",
    10212: "blocker",
    10213: "lens type",
    10214: "labs",
    10215: "hubs",
    10216: "lab users",
    10217: "optician users",
    10218: "models",
    10219: "scope types",
    10220: "site",
    10221: "footer site data",
    10222: "export config",
    10223: "licenses",
    10224: "Id",
    10225: "license key",
    10226: "retailer",
    10227: "activation date",
    10228: "attention",
    10229: "you are running out of licenses",
    10230: "total licenses",
    10231: "licenses activated",
    10232: "licenses not activated",
    10233: "admin users",
    10234: "retailer users",
    10235: "retailer user",
    10236: "license",
    10237: "license saved",
    10238: "company license",
    10239: "create license",
    10240: "activate license",
    10241: "disactivate license",
    10242: "activated license",
    10243: "disactivated license",
    10244: "license created",
    10245: "licenses generator",
    10246: "number of licenses",
    10247: "licenses generated",
    10248: "reset company counter",
    10249: "externalJobId updated for all companies",
    10250: "insert the password to update the counter",
    10251: "reset",
    10252: "reset companies counter",
    10253: "to use only the first day of the year",
    10254: "insert the minimum externalJobId",
    10255: "reset counter",
    10256: "wrong password",
    10257: "city - province",
    10258: "report licenses",
    10259: "copy company data",
    10260: "license type",
    10261: "active modules",
    10262: "base",
    10263: "advanced",
    10264: "license advanced modules management",
    10265: "license advanced uploaded successfully",
    10266: "advanced license",
    10267: "license advanced active modules",
    10268: "greek",
    10269: "all in one",
    10270: "num usage month",
    10271: "cost for usage",
    10272: "total",
    10273: "report usage optician for month for the year",
    10274: "monthly cost for single optician:",
    10275: "loading companies...",
    10276: "store usage statistic",
    10277: "active scope",
    10278: "doesn't modify optician edgingData",
    10279: "download setup:",
    10280: "download table data",
    10281: "advanced modules",
    10282: "only shape",
    10283: "days before delete",
    10284: "laboratory name",
    10285: "optician name",
    10286: "of which",
    10287: "free",
    10288: "force log ReceiveData",
    10289: "ignore PinChanged",
    10290: "eltima license",
    10291: "eltima license list company",
    10292: "no licenses found",
    10293: "from activation date",
    10294: "to activation date",
    10295: "copy install key",
    10296: "install key",
    10297: "install key copied",
    10298: "impossible to copy the key in the clipboard",
    10299: "errore durante la copia nella clipboard:",
    10300: "the window isn't active. operation cancelled",
  },
}
export default en
