import React, { useContext, useState, useEffect, useCallback } from "react"
import { RootContext } from "../../RootContext"

//Material UI
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"

//Componenti Pegaso
import { makeStyles } from "@material-ui/core/styles"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import { CircularIndeterminate } from "../Loading"
import DatesUtils from "components/Tools/DatesUtils"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"
import StringUtils from "components/Tools/StringUtils"


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))


export default function LicenseAdvanced(props) {
  const {
    adminTableRows,
    adminTableRowData,
    handleClickClose,
    setAdminTableRows,

  } = props
  const {
    t,
    apiUrl,
    access_token,
    getUtc,
    isRetailerUser,
    refresh_token,
    setAccess_token,
    urlAccessCode,
  } = useContext(RootContext)

  const classes = useStyles()

  const [advancedOption, setAdvancedOption] = useState([])
  const [dialogMsg, setDialogMsg] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [isRetailer] = useState(isRetailerUser())
  const [isSaving, setIsSaving] = useState(false)
  const licenseId = adminTableRowData.id
  const [openDialog, setOpenDialog] = useState(false)


  //GET advanceds option.
  const fetchAdvanceds = useCallback(() => {
    setIsFetching(true)
    const url = `${apiUrl}/advanceds`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ advanced }) => {
        var selectedOption = searchAdvancedSelected(adminTableRowData)
        var advancedOption = advanced.map((option) => {
          return ({
            id: option.id,
            name: option.name,
            active: selectedOption.indexOf(option.id) >= 0,
          })
        }
        )
        setAdvancedOption(advancedOption)
        setIsFetching(false)
      })
  }, [adminTableRowData, access_token, apiUrl, refresh_token, setAccess_token])

  //Handler change checekd
  const handleChangeChecked = (advancedId) => (event) => {
    var appAdvOption = advancedOption.map((option) => {
      return (advancedId === option.id ? (
        {
          id: option.id,
          name: option.name,
          active: event.target.checked,
        }
      ) : (
        option)
      )
    })
    setAdvancedOption(appAdvOption)
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    var advancedToAdd = searchAdvancedToAdd(advancedOption, adminTableRowData)
    var advancedToDelete = searchAdvancedToDelete(advancedOption, adminTableRowData)
    const url = `${apiUrl}/license/${licenseId}/advanced`
    fetchWithToken(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advancedIdToAdd: advancedToAdd,
        advancedIdToDelete: advancedToDelete
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10265")}`
        )
        let updatedLicense = data.license
        let newAdminTableRows = adminTableRows.map((elem) => {
          if (elem.id === updatedLicense.id) {
            return updatedLicense
          } else {
            return elem
          }
        })
        setAdminTableRows(newAdminTableRows)
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  useEffect(() => {
    fetchAdvanceds()
  }, [
    fetchAdvanceds
  ])


  //Cerca licenze advanced selezionate
  function searchAdvancedSelected(licenseData) {
    var advanced = licenseData.licenseAdvanceds
    var advancedSelected = []
    advanced.map((option) => {
      return advancedSelected.push(option.advancedId.id)
    })
    return advancedSelected
  }

  //Prepara le licenze da aggiungere prima di salvare
  function searchAdvancedToAdd(advancedOption, licenseData) {
    var advancedLicense = searchAdvancedSelected(licenseData)
    var newOption = []

    newOption = advancedOption.map((option) => {
      return advancedLicense.indexOf(option.id) < 0 && option.active === true ? (option.id) : (null)
    })
    newOption = newOption.filter((option) => { return option !== null })
    return newOption
  }

  //Prepara le licenze da eliminare prima di salvare
  function searchAdvancedToDelete(advancedOption, licenseData) {
    var advancedLicense = searchAdvancedSelected(licenseData)
    var newOption = []
    newOption = advancedOption.map((option) => {
      return advancedLicense.indexOf(option.id) >= 0 && option.active === false ? (option.id) : (null)
    })
    newOption = newOption.filter((option) => { return option !== null })
    return newOption
  }

  //Restituisce la data di attivazione di una advanced license
  function searchActivationDate(adminTableRowData, advancedId) {
    var filterAdvanced = adminTableRowData.licenseAdvanceds.filter((option) => {
      return option.advancedId.id === advancedId ? option.activationDate : null
    })

    return filterAdvanced.length > 0 ? (
      t("10227") + " " + DatesUtils.formatDateWithUtc(filterAdvanced[0].activationDate, getUtc())
    ) :
      (
        ""
      )
  }

  return (isFetching ?

    <CircularIndeterminate />
    : (
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <b>
            <p align="center">
              <u>{t("10264").toUpperCase()} </u>
            </p>
          </b>

          <Grid container spacing={2}>
            <Grid
              className={classes.gridCurrentValues}
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              {
                advancedOption.map((option) => (
                  <React.Fragment key={"advanced" + option.id} >
                    <Grid item xs={4}>
                      {option.name}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={"advancedActive" + option.id}
                            checked={option.active}
                            disabled={isRetailer}
                            onChange={handleChangeChecked(option.id)}
                            name="active"
                          />
                        }

                      />
                    </Grid>
                    <Grid item xs={4}>
                      {searchActivationDate(adminTableRowData, option.id)}
                    </Grid>
                  </React.Fragment>
                ))
              }
            </Grid>
          </Grid>

          {/*BOTTONI PER SALVATAGGIO*/}
          {
            !isRetailer ?
              <AdminTableSaveButton
                {...props}
                handleSave={handleSave}
                disabled={isRetailer}
              />
              :
              null
          }

          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />

        </CardBody>
      </Card>
    )
  )
}