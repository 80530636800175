//Pannello per la scelta del device
import React, { useContext } from "react"
import { RootContext } from "RootContext"

// core components
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Grid from "@material-ui/core/Grid"

// LicensesCount: Date le righe calcola quante licenze sono rimaste
function LicensesCount(rows) {
  let newNLicense = 0
  let newNLicenseUsed = 0
  let newNLicenseAvailable = 0
  let newNLicenseFree = 0
  rows.forEach((row) => {
    newNLicense = newNLicense + 1
    if (row.active) {
      newNLicenseUsed++
    } else {
      newNLicenseAvailable++
    }
    if(row.company !== undefined  && row.company.indexOf("available") >= 0){
      newNLicenseFree++
    }
  })

  return {
    total: newNLicense,
    used: newNLicenseUsed,
    available: newNLicenseAvailable,
    free: newNLicenseFree,
  }
}

// LicensesCountTable: Componente che espone i totali delle licenze
function LicensesCountTable(props) {
  const { t } = useContext(RootContext)
  const { totLicenses, headerWord } = props
  const minFree = 5
  return (
    <Card>
      <CardHeader color="primary">
        {headerWord}
      </CardHeader>
      <CardBody>
        {totLicenses.total > 0 ? (
          <Grid container justify="space-between">
            <Grid item xs={3}>
              {t("10230")}: {totLicenses.total}
            </Grid>
            <Grid item xs={3}>
              {t("10231")}: {totLicenses.used}
            </Grid>
            <Grid item xs={3}>
              {t("10232")}: {totLicenses.available} ({t("10286")} {totLicenses.free} {t("10287")}) 
            </Grid>
            <Grid item xs={3}>
              <strong style={{ color: "red" }}>
                {
                  totLicenses.free <= minFree ?
                    t("10228") + "! " + t("10229")
                    : null
                }
              </strong>
            </Grid>
          </Grid>
        ) : (
          null
        )}

      </CardBody>
    </Card>
  )
}

export { LicensesCount, LicensesCountTable }