import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

const languageList = UserUtils.languageList

const UTCList = UserUtils.UTCList()

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allRetailers,
    creation,
    handleClickClose,
    setAdminTableRows,
    urlAccessCode,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [errorFetch, setErrorFetch] = useState(false)
  const [isFetchingRetailer, setIsFetchingRetailer] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  //Valori dei Dati
  const [retailerUser, setRetailerUser] = useState(
    creation
      ? {
        username: undefined,
        password: "",
        retailer: {
          retailerId: 2,
          name: "Visionix"
        },
        language: "it-it",
        utc: 1,
        active: true,
        //email: UserUtils.generatePasswordLettersNumbers() + "@test.com",
        email: undefined,
        demo: false,
        description: undefined,
        telephone: "",
        roles: [5]
      }
      : {
        username: adminTableRowData.username,
        password: adminTableRowData.password,
        retailer: {
          retailerId: adminTableRowData.retailer.retailerId,
          name: adminTableRowData.retailer.name
        },
        language: adminTableRowData.language,
        utc: adminTableRowData.utc,
        active: adminTableRowData.active,
        email: adminTableRowData.email,
        demo: adminTableRowData.demo ? adminTableRowData.demo : false,
        description: adminTableRowData.description,
        telephone: adminTableRowData.telephone,
        roles: adminTableRowData.roles
      }
  )

  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch dati ottico qui dentro vengono settati/creati numero di telefono, nomeottico, password in fase di creazione
  const fetchRetailerId = useCallback(() => {
    setIsFetchingRetailer(true)
    setErrorFetch(false)
    const url = `${apiUrl}/retailer/${retailerUser.retailer.retailerId}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se RetailerUser esiste
        setRetailerUser({
          ...retailerUser,
          retailer: {
            ...retailerUser.retailer,
            name: data.name,
          },
        })
        setIsFetchingRetailer(false)
      })
      .catch((err) => {
        // Se RetailerUser non esiste
        if (err.body.code === "E4") {
          setDialogMsg(t("10226") + " " + t("10142"))
          setErrorFetch(true)
        }
        setIsFetchingRetailer(false)
        ErrorUtils.errorLog("Fetch Retailer Id Error", err)
      })
  }, [
    access_token,
    apiUrl,
    retailerUser,
    refresh_token,
    setAccess_token,
    t,
    urlAccessCode,
  ])

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setRetailerUser({ ...retailerUser, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (adminParameter, name) => (event) => {
    setRetailerUser({ ...retailerUser, [name]: adminParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    if (name === "roles") {
      var value = event.target.checked ? [5] : [6]
      setRetailerUser({ ...retailerUser, [name]: value })
    }
    else {
      setRetailerUser({ ...retailerUser, [name]: event.target.checked })
    }
  }

  //Cambia RetailerUser.Retail field
  const handleChangeRetailer = (name) => (event) => {
    setRetailerUser({
      ...retailerUser,
      retailer: { ...retailerUser.retailer, [name]: event.target.value },
    })
  }

  const onBlurRetailerId = () => {
    if (retailerUser.retailer.retailerId === "") {
      setRetailerUser({
        ...retailerUser,
        retailer: {
          ...retailerUser.retailer,
          name: "",
        },
      })
    } else {
      fetchRetailerId()
    }
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/retailer-users/${retailerUser.username}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: retailerUser.username,
        password: retailerUser.password,
        retailer: retailerUser.retailer.retailerId,
        email: retailerUser.email,
        active: retailerUser.active,
        language: retailerUser.language,
        utc: retailerUser.utc,
        demo: retailerUser.demo,
        description: retailerUser.description,
        telephone: retailerUser.telephone,
        roles: retailerUser.roles,
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10102")}\n\nUsername: ${retailerUser.username}\nPassword: ${retailerUser.password
          }`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)

        // Aggiorna la lista utenti
        let newElem = {
          username: retailerUser.username,
          password: retailerUser.password,
          retailer: retailerUser.retailer,
          email: retailerUser.email !== "" ? retailerUser.email : undefined,
          active: retailerUser.active,
          language: retailerUser.language,
          utc: retailerUser.utc,
          demo: retailerUser.demo,
          viewPassword: false,
          description: retailerUser.description,
          telephone: retailerUser.telephone,
          roles: retailerUser.roles,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.username === retailerUser.username) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22") + err)
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !retailerUser.username ||
      !retailerUser.password ||
      !retailerUser.retailer.retailerId ||
      isFetchingRetailer
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10235").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DELL'UTENTE*/}

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"username"}
                    label={t("10062")}
                    onChange={handleChange("username")}
                    onBlur={retailerUser.username ? onBlurTrim(retailerUser.username, "username") : null}
                    className={classesText.textField}
                    value={retailerUser.username || ""}
                    margin="normal"
                    helperText={creation ? null : t("10105")}
                    InputProps={{
                      readOnly: !creation,
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"password"}
                    label={t("10063")}
                    onChange={handleChange("password")}
                    onBlur={onBlurTrim(retailerUser.password, "password")}
                    className={classesText.textField}
                    value={retailerUser.password || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id="language"
                    label={t("10083")}
                    value={retailerUser.language}
                    onChange={handleChange("language")}
                    helperText={t("10106")}
                  >
                    {languageList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id="utc"
                    label={"UTC"}
                    value={retailerUser.utc}
                    onChange={handleChange("utc")}
                    helperText={t("10107")}
                  >
                    {UTCList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailerUser.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"email"}
                    label={t("10108")}
                    className={classesText.textField}
                    value={retailerUser.email || ""}
                    margin="normal"
                    onChange={handleChange("email")}
                    onBlur={retailerUser.email ? onBlurTrim(retailerUser.email, "email") : null}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailerUser.demo}
                        onChange={handleChangeChecked("demo")}
                        name="demo"
                      />
                    }
                    label={t("10109")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"description"}
                    label={t("10110")}
                    className={classesText.textField}
                    value={retailerUser.description || ""}
                    margin="normal"
                    onChange={handleChange("description")}
                    onBlur={retailerUser.description ? onBlurTrim(retailerUser.description, "description") : null}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    error={errorFetch ? true : false}
                    id="retailerId"
                    label={t("10226")}
                    value={retailerUser.retailer.retailerId}
                    onChange={handleChangeRetailer("retailerId")}
                    onBlur={onBlurRetailerId}
                  >
                    {allRetailers
                      .sort(function compare(a, b) {
                        if (a.retailerId < b.retailerId) {
                          return -1
                        }
                        if (a.retailerId > b.retailerId) {
                          return 1
                        }
                        return 0
                      })
                      .map((option) => (
                        <MenuItem key={option.retailerId} value={option.retailerId}>
                          {option.retailerId + " - " + option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"phone"}
                    label={t("10149")}
                    className={classesText.textField}
                    value={retailerUser.telephone || ""}
                    margin="normal"
                    helperText={t("10149")}
                    onChange={handleChange("telephone")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailerUser.roles[0] === 5}
                        onChange={handleChangeChecked("roles")}
                        name="roles"
                      />
                    }
                    label={t("admin user")}
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del OpticianUser
export default function RetailerUser(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
