import { createMuiTheme } from "@material-ui/core/styles";
import { indigo, pink } from "@material-ui/core/colors";

export default createMuiTheme({
  palette: {
    secondary: {
      main: pink[600]
    },
    primary: {
      main: indigo[700]
    }
  },
  typography: {
    fontFamily: ['"Lato"', "sans-serif"].join(",")
  }
});
