/***************************************************************************
                   FUNZIONE PER CONTROLLO VALORI NEI CAMPI
 ***************************************************************************/
import JobUtils from "components/Utils/JobUtils"

export default class JobControlUtils {
  static controlDiameter(valDiametro, t) {
    if (!valDiametro) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (valDiametro.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (valDiametro < 1) {
        return {
          error: true,
          msgError: t("2")
        }
      } else if (valDiametro > 99) {
        return {
          error: true,
          msgError: t("19")
        }
      } else if (!JobUtils.isNumeric(valDiametro)) {
        return {
          error: true,
          msgError: t("3")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlSphere(valSfero, t) {
    if (valSfero.trim() !== "") {
      if (!JobUtils.isNumeric(valSfero)) {
        return {
          error: true,
          msgError: t("4")
        }
      } else if (!JobUtils.zeroTwentyFive(valSfero)) {
        return {
          error: true,
          msgError: t("5")
        }
      } else if (valSfero > 30 || valSfero < -30) {
        return {
          error: true,
          msgError: t("6")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlCylinder(valcilindro, t) {
    if (valcilindro.trim() !== "") {
      if (!JobUtils.isNumeric(valcilindro)) {
        return {
          error: true,
          msgError: t("7")
        }
      } else if (!JobUtils.zeroTwentyFive(valcilindro)) {
        return {
          error: true,
          msgError: t("8")
        }
      } else if (valcilindro >= 10 || valcilindro <= -10) {
        return {
          error: true,
          msgError: t("9")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlAxis(valAsse, t) {
    if (valAsse) {
      if (valAsse.trim() !== "") {
        if (!JobUtils.isNumeric(valAsse)) {
          return {
            error: true,
            msgError: t("10")
          }
        } else if (valAsse < 0) {
          return {
            error: true,
            msgError: t("11")
          }
        } else if (valAsse > 360) {
          return {
            error: true,
            msgError: t("12")
          }
        } else if (!JobUtils.numberIsIntger(valAsse)) {
          return {
            error: true,
            msgError: t("13")
          }
        }
      }
      return {
        error: false,
        msgError: ""
      }
    } else {
      return {
        error: false,
        msgError: ""
      }
    }
  }

  static controlEdgingField(val, min, max, t) {
    if (val.trim() !== "") {
      if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("14")
        }
      } else if (val < min) {
        if (min === 0) {
          return {
            error: true,
            msgError: t("15")
          }
        } else {
          return {
            error: true,
            msgError: `${t("16")} ${min}`
          }
        }
      } else if (val > max) {
        return {
          error: true,
          msgError: `${t("17")} ${max}`
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlAddition(val, t) {
    return JobControlUtils.controlEdgingField(val, 0, 9.99, t)
  }

  static controlPd(val, t) {
    return JobControlUtils.controlEdgingField(val, 10, 45, t)
  }

  static controlEdgingHeight(val, t) {
    return JobControlUtils.controlEdgingField(val, 5, 40, t)
  }

  static controlBox(val, t) {
    return JobControlUtils.controlEdgingField(val, 15, 85, t)
  }

  static controlDbl(val, t) {
    return JobControlUtils.controlEdgingField(val, 4, 30, t)
  }

  static controlOffset(valOffset, t) {
    if (valOffset !== 0) {
      if (valOffset < -10.0 || valOffset > 10.0) {
        return {
          error: true,
          msgError: t("18")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlBevm(valBevp, valBevr, t) {
    if (valBevp && valBevr !== "") {
      if (valBevp.trim() !== "") {
        if (valBevr === "(mm)") {
          return JobControlUtils.controlEdgingField(valBevp, 0, 5, t)
        } else if (!JobUtils.isNumeric(valBevp)) {
          return {
            error: true,
            msgError: t("14")
          }
        } else if (valBevp < 0) {
          return {
            error: true,
            msgError: t("15")
          }
        } else if (valBevp > 99) {
          return {
            error: true,
            msgError: t("17") + " 99"
          }
        } else if (!JobUtils.numberIsIntger(valBevp)) {
          return {
            error: true,
            msgError: t("21")
          }
        }
      }
      return {
        error: false,
        msgError: ""
      }
    } else {
      return {
        error: false,
        msgError: ""
      }
    }
  }
}
