import { useTranslation } from "react-i18next"

//pegaso views/components
import CompressUtils from "components/Tools/CompressUtils"
import ErrorUtils from "components/Tools/ErrorUtils"
import JobsUtils from "components/Utils/JobsUtils"
import JobControlUtils from "components/Utils/JobControlUtils"

/*************************************************************************
                  VALORI PER SELECT
 *************************************************************************/

export default class JobUtils {
  // Array con i codici e le descrizioni del TIPO LENTE in Oma
  static lensTypeValues() {
    return [
      {
        id: "AS",
        description: "5000"
      },
      {
        id: "AT",
        description: "5001"
      },
      {
        id: "BI",
        description: "5002"
      },
      {
        id: "CT",
        description: "5003"
      },
      {
        id: "DS",
        description: "5004"
      },
      {
        id: "EX",
        description: "5005"
      },
      {
        id: "FT",
        description: "5006"
      },
      {
        id: "LT",
        description: "5007"
      },
      {
        id: "PR",
        description: "5008"
      },
      {
        id: "QD",
        description: "5009"
      },
      {
        id: "RD",
        description: "5010"
      },
      {
        id: "SV",
        description: "5011"
      },
      {
        id: "TR",
        description: "5012"
      }
    ]
  }
  // Array con i codici e le descrizioni del TIPO BORDO in Oma  ETYP
  static edgingTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: ""
      },
      {
        value: "1",
        label: t("10088")
      },
      {
        value: "3",
        label: t("10089")
      },
      {
        value: "2",
        label: t("10090")
      }
    ]
  }
  // Array con i codici e le descrizioni del POSIZIONE BORDO in Oma
  static positionTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: ""
      },
      {
        value: "7",
        label: t("10091")
      },
      {
        value: "2",
        label: t("10092")
      },
      {
        value: "4",
        label: t("10093")
      },
      {
        value: "5",
        label: t("10094")
      },
      {
        value: "1",
        label: t("10095")
      }
    ]
  }
  // Array con i codici e le descrizioni della LUCIDATURA in Oma
  static polishValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: ""
      },
      {
        value: "0",
        label: t("10097")
      },
      {
        value: "1",
        label: t("10096")
      }
    ]
  }

  // Array con i codici e le descrizioni del MATERIALE MONTATURA in Oma FTYP
  static frameTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: ""
      },
      {
        value: "1",
        label: t("10098")
      },
      {
        value: "2",
        label: t("10099")
      },
      {
        value: "3",
        label: t("10100")
      },
      {
        value: "4",
        label: t("10101")
      }
    ]
  }

  /*************************************************************************
                               FUNZIONE OMA
   *************************************************************************/

  // Estrae la label da un record OMA
  static extractOmaLabel(omaString) {
    omaString = omaString.trim()
    if (omaString !== "")
      return omaString.substring(0, omaString.indexOf("=", 0))
  }

  /*************************************************************************
                    FUNZIONE RECUPERO DATI ORDINE
   *************************************************************************/

  static GetStandardShape(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { standard } = shape
      if (standard) {
        result = CompressUtils.zlibDecompress(standard)
      }
    }
    return result
  }

  static GetEdgingData(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { edgingData } = shape
      if (edgingData) {
        result = edgingData
      }
    }
    return result
  }

  static GetOffset(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { offset } = shape
      if (offset) {
        result = offset
      }
    }
    return result
  }

  // Crea lo stato iniziale della schermata
  static createDefaultState(jobData) {
    const edgingData = JobUtils.GetEdgingData(jobData)
    const arrayValue = edgingData.split("\r\n")
    var omaLabel = ""
    var omaValue = ""
    var dxSx = []
    var i

    const left = {
      side: "L",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: ""
    }

    const right = {
      side: "R",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: ""
    }

    const edging = {
      etyp: "",
      bevp: "",
      bevm: "",
      polish: "",
      ftyp: "",
      dbl: "",
      offset: JobUtils.GetOffset(jobData)
    }

    const { notes } = jobData
    const { created, updated } = jobData

    if (created) {
      let { hub } = created
      let { user } = created
      var insertby = ""
      if (hub) {
        insertby = `(lab ${jobData.lab.name})`
      }

      if (user) {
        insertby = `(${user.username})`
      }
    }

    if (updated) {
      var { hub: hubUpdate } = updated
      var { user: userUpdate } = updated

      var updateby = ""
      if (hubUpdate) {
        updateby = `(lab ${jobData.lab.name})`
      }

      if (userUpdate) {
        updateby = `(${userUpdate.username})`
      }
    }

    const { jobDevices } = jobData
    if (jobDevices) {
      var { uploader, downloader } = jobDevices

      if (uploader) {
        uploader = uploader.model
      }

      if (downloader) {
        downloader = downloader.model
      }
    }
    const { company } = jobData
    if (company) {
      var head = {
        company: {
          id: jobData.company.id,
          name: jobData.company.name
        },
        lab: {
          id: jobData.lab.id,
          name: jobData.lab.name
        },
        optician: {
          id: jobData.optician.id,
          name: jobData.optician.name
        },
        jobnumber: jobData.jobId,
        date: JobsUtils.formatJobCreationDateFromString(jobData),
        update: JobsUtils.formatJobUpdateDateFromString(jobData),
        status: jobData.status,
        insertby: insertby,
        updateby: updateby,
        deviceUploader: uploader,
        deviceDownloader: downloader
      }
    }

    for (i = 0; i < arrayValue.length; i++) {
      omaLabel = arrayValue[i].substring(0, arrayValue[i].indexOf("=", 0))
      omaValue = arrayValue[i].substring(arrayValue[i].indexOf("=", 0) + 1)
      switch (omaLabel) {
        // Valori singoli
        case "DBL":
          edging.dbl = omaValue
          break
        case "ETYP":
          edging.etyp = omaValue
          break
        case "BEVP":
          edging.bevp = omaValue
          break
        case "BEVM":
          edging.bevm = omaValue
          break
        case "POLISH":
          edging.polish = omaValue
          break
        case "FTYP":
          edging.ftyp = omaValue
          break
        // Valori DX;SX
        case "LTYP":
          dxSx = omaValue.split(";")
          right.ltyp = dxSx[0]
          left.ltyp = dxSx[1]
          break
        case "DIA":
          dxSx = omaValue.split(";")
          right.dia = dxSx[0]
          left.dia = dxSx[1]
          break
        case "SPH":
          dxSx = omaValue.split(";")
          right.sph = JobUtils.formatSphereCylinder(dxSx[0])
          left.sph = JobUtils.formatSphereCylinder(dxSx[1])
          break
        case "CYL":
          dxSx = omaValue.split(";")
          right.cyl = JobUtils.formatSphereCylinder(dxSx[0])
          left.cyl = JobUtils.formatSphereCylinder(dxSx[1])
          break
        case "AX":
          dxSx = omaValue.split(";")
          right.ax = JobUtils.formatAxis(dxSx[0])
          left.ax = JobUtils.formatAxis(dxSx[1])
          break
        case "ADD":
          dxSx = omaValue.split(";")
          right.add = JobUtils.formatGeneric(dxSx[0])
          left.add = JobUtils.formatGeneric(dxSx[1])
          break
        case "IPD":
          dxSx = omaValue.split(";")
          right.ipd = JobUtils.formatGeneric(dxSx[0])
          left.ipd = JobUtils.formatGeneric(dxSx[1])
          break
        case "OCHT":
          dxSx = omaValue.split(";")
          right.ocht = JobUtils.formatGeneric(dxSx[0])
          left.ocht = JobUtils.formatGeneric(dxSx[1])
          break
        case "HBOX":
          dxSx = omaValue.split(";")
          right.hbox = JobUtils.formatGeneric(dxSx[0])
          left.hbox = JobUtils.formatGeneric(dxSx[1])
          break
        case "VBOX":
          dxSx = omaValue.split(";")
          right.vbox = JobUtils.formatGeneric(dxSx[0])
          left.vbox = JobUtils.formatGeneric(dxSx[1])
          break
        default:
          break
      }
    }

    const status = company
      ? {
        rightValues: right,
        leftValues: left,
        edgingValues: edging,
        notesValues: notes,
        headValues: head
      }
      : {
        rightValues: right,
        leftValues: left,
        edgingValues: edging
      }

    return status
  }

  // Creo array con i nuovi dati montaggio in OMA dei dati con label=dx;sx
  static createRightLeftOmaEdgingData(rightData, leftData) {
    var newOmaData = []

    newOmaData.push(
      JobUtils.createOmaRecord("LTYP", rightData.ltyp, leftData.ltyp, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("DIA", rightData.dia, leftData.dia, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("SPH", rightData.sph, leftData.sph, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("CYL", rightData.cyl, leftData.cyl, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("AX", rightData.ax, leftData.ax, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("ADD", rightData.add, leftData.add, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("IPD", rightData.ipd, leftData.ipd, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("OCHT", rightData.ocht, leftData.ocht, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("HBOX", rightData.hbox, leftData.hbox, false)
    )
    newOmaData.push(
      JobUtils.createOmaRecord("VBOX", rightData.vbox, leftData.vbox, false)
    )

    return newOmaData.filter(function (el) {
      return el !== ""
    })
  }

  // Creo array con i nuovi dati montaggio in OMA dei dati con label=val
  static createSingleOmaEdgingData(rightData) {
    var newOmaData = []

    newOmaData.push(JobUtils.createOmaRecord("ETYP", rightData.etyp, "", true))
    newOmaData.push(JobUtils.createOmaRecord("BEVP", rightData.bevp, "", true))
    if (rightData.bevp !== "7" && rightData.bevp !== "") {
      newOmaData.push(
        JobUtils.createOmaRecord("BEVM", rightData.bevm, "", true)
      )
    }
    newOmaData.push(
      JobUtils.createOmaRecord("POLISH", rightData.polish, "", true)
    )
    newOmaData.push(JobUtils.createOmaRecord("FTYP", rightData.ftyp, "", true))
    newOmaData.push(JobUtils.createOmaRecord("DBL", rightData.dbl, "", true))

    return newOmaData.filter(function (el) {
      return el !== ""
    })
  }

  // Crea un record Oma confrontando i valori
  // Se singleValue = true -> crea il record per le label che accettano solo un valore (considera solo rightValue)
  // Se singleValue = false -> crea il record per le label che accettano due valori (se vuoti entrambi restituisce stringa vuota)
  static createOmaRecord(omaLabel, rightValue, leftValue, singleValue) {
    var strRightValue = rightValue.toString()
    var strLeftValue = leftValue.toString()
    switch (singleValue) {
      case false:
        if (strRightValue.trim() === "" && strLeftValue.trim() === "") {
          return ""
        } else {
          if (strRightValue.trim() !== "" && strLeftValue.trim() === "") {
            return omaLabel + "=" + strRightValue + ";" + strRightValue
          } else if (
            strRightValue.trim() === "" &&
            strLeftValue.trim() !== ""
          ) {
            return omaLabel + "=" + strLeftValue + ";" + strLeftValue
          } else {
            return omaLabel + "=" + strRightValue + ";" + strLeftValue
          }
        }
      case true:
      default:
        if (strRightValue.trim() === "") {
          return ""
        } else {
          return omaLabel + "=" + strRightValue
        }
    }
  }

  // Crea un array sostituendo i vecchi valori Oma con i nuovi, e inserendo quelli non modificati
  static searchAndReplaceOmaData(arrayOldData, arrayGuiData) {
    var arrayNewData = []

    const checkLabel = (a, b) =>
      JobUtils.extractOmaLabel(a) === JobUtils.extractOmaLabel(b)
    const addElement = element => arrayNewData.push(element)

    // Aggiungo elementi dell old al new
    arrayOldData.map(elemOld => addElement(elemOld))

    // Aggiungo elementi del gui al new (sostituisco se trovo)
    arrayGuiData.map(elemGui => {
      const labelFound = arrayNewData.find(elemNew =>
        checkLabel(elemGui, elemNew)
      )

      return labelFound
        ? (arrayNewData = arrayNewData.map(newArrayElem =>
          checkLabel(newArrayElem, elemGui) ? elemGui : newArrayElem
        ))
        : addElement(elemGui)
    })
    return arrayNewData
  }

  /*************************************************************************
                    FUNZIONE FORMATTAZIONE/CONTROLLO NUMERI
   *************************************************************************/

  // Formattazione Numero
  static number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)
        return "" + (Math.round(n * k) / k).toFixed(prec)
      }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || ""
      s[1] += new Array(prec - s[1].length + 1).join("0")
    }
    return s.join(dec)
  }

  // Controlla che un numero sia numerico
  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  // Controlla che un numero sia intero
  static numberIsIntger(n) {
    if (String(n).indexOf(".") !== -1 || String(n).indexOf(",") !== -1) {
      return false
    }
    return true
  }

  // Controlla che un numero sia divisibile per 0.25
  static zeroTwentyFive(n) {
    if (n % 0.25 > 0) {
      return false
    }
    return true
  }

  // Formatta sfero e cilindro
  static formatSphereCylinder(value) {
    var newValue = ""
    var segno = ""
    if (value === "?" || value.trim() === "") {
      return ""
    } else {
      if (value.indexOf("+") >= 0 || value.indexOf("-") >= 0) {
        segno = value.charAt(0)
        value = value.substr(1)
      } else {
        segno = "+"
      }
      if (value.charAt(0) === "0") {
        if (value.indexOf(".") < 0) {
          value = value.charAt(0) + "." + value.substr(1)
        }
        value = JobUtils.number_format(parseFloat(value), 2, ".", "") + ""
        newValue = segno + value
      } else {
        value = parseFloat(value)
        if (value >= 100) {
          value = value / 100
        }
        value = JobUtils.number_format(value, 2, ".", "") + ""
        newValue = segno + value
      }
    }
    return newValue
  }

  // Formatta asse
  static formatAxis(value) {
    var newValue = value
    if (newValue.trim() !== "") {
      if (value === "?") {
        newValue = ""
      } else {
        newValue = JobUtils.number_format(newValue, 0)
      }
    }
    return newValue
  }

  // Formattazione generica che sostituisce la , con il punto e usa 2 cifre decimali
  static formatGeneric(value) {
    var newValue = value
    if (newValue.trim() !== "") {
      if (newValue === "?") {
        newValue = ""
      } else {
        newValue = JobUtils.number_format(
          newValue.replace(",", "."),
          2,
          ".",
          ""
        )
      }
    }
    return newValue
  }

  /*************************************************************************
                  FUNZIONE REQUIRED + CHECKS AUTOMATICI
   *************************************************************************/

  static GetBiggestSuffix(a, b) {
    let result = a
    let numPortionA = a.substring(1)
    let numPortionB = undefined
    if (b !== undefined) {
      if (b !== "") {
        numPortionB = b.substring(1)
        if (this.isNumeric(numPortionB)) {
          if (Number(numPortionB) >= Number(numPortionA)) {
            result = a.substring(0, 1) + (Number(numPortionB) + 1).toString().padStart(3, "0")
          }
        }
      }
    }
    return result
  }

  static fieldIsRequired(id, requiredFields, isGlasant) {
    let result = false
    requiredFields.forEach(element => {
      if (!((element === "bevm" || element === "bevp") && isGlasant)) {
        var posUnderscore = element.indexOf("_")
        var first, second
        if (posUnderscore >= 0) {
          first = element.substr(0, posUnderscore)
          second = element.substr(posUnderscore + 1)
          if (id.indexOf(first) >= 0) {
            result = true
          } else if (id.indexOf(second) >= 0) {
            result = true
          }
        } else {
          if (id.indexOf(element) >= 0) {
            result = true
          }
        }
      }
    })
    return result
  }

  static getBevpMeasure(fieldsToCheck) {
    var result = " (mm)"
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      switch (key) {
        case "bevp":
          if (value === "2" || value === "4") {
            result = " (%)"
          }
          break
        default:
          break
      }
    }
    return result
  }

  static checkAllRequiredCompiled(fieldsToCheck, requiredFields, isGlasant) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      if (JobUtils.fieldIsRequired(key, requiredFields, isGlasant)) {
        if (value === "" || value === null) {
          result = false
          return result
        }
      }
    }
    return result
  }

  static checkFormatEdgingData(fieldsToCheck, isGlasant, t) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      let check
      switch (key) {
        case "bevm":
          if (!isGlasant) {
            const unitaDiMisura = JobUtils.getBevpMeasure(fieldsToCheck).trim()
            check = (v, t) =>
              JobControlUtils.controlBevm(
                v.substr(0, v.indexOf(";")),
                unitaDiMisura,
                t
              )
          } else {
            check = (v, t) => true
          }
          break
        case "dbl":
          check = JobControlUtils.controlDbl
          break
        case "offset":
          check = JobControlUtils.controlOffset
          break
        default:
          check = (v, t) => true
          break
      }

      const c = check(value, t)
      const { error, msgError } = c
      if (error) {
        ErrorUtils.errorLog("checkFormatEdgingData", key + " -> " + msgError)
        result = false
        return result
      }
    }
    return result
  }

  static checkFormatLens(fieldsToCheck, t) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      let check
      switch (key) {
        case "dia":
          check = JobControlUtils.controlDiameter
          break
        case "sph":
          check = JobControlUtils.controlSphere
          break
        case "cyl":
          check = JobControlUtils.controlCylinder
          break
        case "ax":
          check = JobControlUtils.controlAxis
          break
        case "add":
          check = JobControlUtils.controlAddition
          break
        case "ipd":
          check = JobControlUtils.controlPd
          break
        case "ocht":
          check = JobControlUtils.controlEdgingHeight
          break
        case "hbox":
          check = JobControlUtils.controlBox
          break
        case "vbox":
          check = JobControlUtils.controlBox
          break
        default:
          check = (v, t) => true
          break
      }
      const c = check(value, t)
      const { error, msgError } = c
      if (error) {
        ErrorUtils.errorLog("checkFormatLens", key + " -> " + msgError)
        result = false
        return result
      }
    }
    return result
  }
}
