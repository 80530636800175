import React, { useState, useContext, Fragment } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableOkSaved,
} from "components/AdminTable/AdminTableEditScreen"
//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from '@material-ui/core/Typography';

// CSS
import { useAdminTableStyles } from "styles/adminTable/adminTableCss"
import { useStylesText, } from "styles/adminTable/adminTableEditScreenCss"

function CheckPassword(props) {
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const {
    classes,
    openPasswordDialog,
    openConfirmDialog,
    setOpenPasswordDialog,
    setOpenConfirmDialog
  } = props

  const classesText = useStylesText()

  const [dialogMsg, setDialogMsg] = useState("")
  const [isPasswordErrata, setIsPasswordErrata] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [password, setPassword] = useState("")
  //Handler per textarea password
  const handleChange = (event) => {
    setPassword(event.target.value)
  }

  //Handler per bottone azzera
  const handleSave = (event) => {
    const encodedString = Buffer.from(password).toString('base64');
    if (encodedString === process.env.REACT_APP_COUNTER_RESET) {
      setIsSaving(true)
      setOpenPasswordDialog(false)
      const url = `${apiUrl}/reset-externaljobids`
      fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body
        })
        .then((data) => {
          setDialogMsg(t("10249"))
          setOpenConfirmDialog(true)
        })
        .catch((err) => {
          ErrorUtils.errorLog(t("1"), err)
          setDialogMsg(t("1"))
          setOpenConfirmDialog(true)
          setIsSaving(false)
        })
    }
    else {
      setIsPasswordErrata(true)
    }
  }

  //Close
  const handleClose = () => {
    setIsSaving(false)
    setOpenConfirmDialog(false)
    setOpenPasswordDialog(false)
  }

  return (
    <Dialog
      open={openPasswordDialog || isSaving}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
      {
        openPasswordDialog ? (
          <Fragment>
            <DialogContent>
              <DialogTitle id="alert-dialog-slide-title">
                {t("10250")}
                <Grid container spacing={2}>
                  <Grid className={classes.gridCurrentValues} item xs={12}></Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id={"password"}
                      onChange={handleChange}
                      label={t("10063")}
                      className={classesText.textField}
                      helperText={isPasswordErrata ? t("10256") : null}
                      value={password}
                      margin="normal"
                      type="password"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      p={1}
                      className={classes.gridToolbar}
                    >
                      <Box p={1}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleSave}
                        >
                          {t("10251")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  {t("10005")}
                </Button>
              </DialogActions>
            </DialogContent>
          </Fragment>

        ) : (
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openConfirmDialog}
          />
        )
      }
    </Dialog>
  )
}


export default function ResetCounter() {
  const {
    t,
  } = useContext(RootContext)


  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const classes = useAdminTableStyles()

  const handleSave = (event) => {
    setOpenPasswordDialog(true)
  }

  return (

    <Card>
      <CardBody>
        <Paper className={classes.paper}>
          <Typography variant="h2">
            {t("10252").toUpperCase()}
          </Typography>
          <Typography variant="subtitle1">
            ({t("10253")})
          </Typography>
        </Paper>

        <Grid container spacing={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.gridToolbar}
          >
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSave}
              >
                {t("10255")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </CardBody>

      <CheckPassword
        openPasswordDialog={openPasswordDialog}
        classes={classes}
        setOpenPasswordDialog={setOpenPasswordDialog}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
      />

    </Card>

  )

}
