import React, { useContext } from "react"
import IconButton from "@material-ui/core/IconButton"
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from "@material-ui/core/Tooltip"
import { RootContext } from "RootContext"

function AdminTableCloneButton(props) {
  const { handleClickOpen, cloneButton } = props
  const { t } = useContext(RootContext)

  return (
    <div id="contenitore">
      <Tooltip title={t("10202")}>
        <IconButton
          aria-label={t("10202")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(cloneButton)
          }}
        >
          <FileCopyIcon/>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AdminTableCloneButton
