import React, { useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Grid from "@material-ui/core/Grid"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import ErrorUtils from "components/Tools/ErrorUtils"

//import CSS
import {
  useAdminTableEditScreenStyles,
} from "styles/adminTable/adminTableEditScreenCss"


export default function CompanyLicense(props) {
  const {
    currentLicense,
    handleClickClose,
    rows,
    setRows,
    urlAccessCode,
  } = props

  const {
    t,
    isRetailerUser,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()

  //Variabili
  const [isRetailer] = useState(isRetailerUser())
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [companyLicense, setCompanyLicense] = useState(currentLicense !== null ?
    currentLicense
    :
    {
      id: undefined,
      activationDate: undefined,
      active: false,
      creationDate: undefined,
      licenseKey: undefined,
      retailer: {
        availableBrands: [],
        companyDefaultScopes: [],
        name: undefined,
        retailerId: undefined
      }
    }
  )

  // Click - Activate/Disactivate License
  const handleActivateLicenseClick = () => {
    setIsSaving(true)
    const newActivate = !companyLicense.active
    const url = `${apiUrl}/license/${companyLicense.id}`
    fetchWithToken(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active: newActivate,
      }),
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = (newActivate ? t("10242") : t("10243")) + "\r\n" + companyLicense.licenseKey
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        setCompanyLicense({
          ...companyLicense,
          active: newActivate
        })
        // Aggiorna la lista utenti
        let newLicense = {
          ...companyLicense,
          active: newActivate
        }
        let newAdminTableRows = rows.map((elem) => {
          if (elem.urlAccessCode === urlAccessCode) {
            return {
              ...elem,
              license: newLicense
            }
          } else {
            return elem
          }
        })
        setRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disabelSave() {
    let result =
      !companyLicense.licenseKey
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10238").toUpperCase()} </u>
          </p>
          {
            companyLicense.licenseKey !== undefined ?
              <Grid container >
                <Grid className={classes.gridCurrentValues} item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    {/*   RIGA 1   */}
                    <Grid item xs={5}>
                      <strong>{t("10225")}</strong>
                    </Grid>
                    <Grid item xs={2}>
                      <strong>{t("10226")}</strong>
                    </Grid>
                    <Grid item xs={5}>
                      <strong>{t("10047")}</strong>
                    </Grid>

                    {/*   RIGA 2   */}
                    <Grid item xs={5}>
                      {companyLicense.licenseKey}
                    </Grid>
                    <Grid item xs={2}>
                      {companyLicense.retailer.retailerId.toString() + " - " + companyLicense.retailer.name}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>{companyLicense.active.toString().toUpperCase()}</strong>
                    </Grid>
                    <Grid item xs={2}>
                      {/* TASTO - Attiva/Disattiva License */}
                      {
                        !isRetailer ?
                          <AdminTableSaveButton
                            {...props}
                            buttonLabel={companyLicense.active ? t("10241") : t("10240")}
                            handleSave={handleActivateLicenseClick}
                            disabled={disabelSave()}
                          />
                          :
                          null
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              :
              t("32")
          }
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}