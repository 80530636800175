import React, { useState } from 'react';

// Core components
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

export default function FilterSelect({ classes, defaultValue, handleChange1, handleChange2, handleClick1, id, label, options, filterField, disabled }) {
    const [value, setValue] = useState(
        defaultValue,
    );
    const handleChange = event => {
        const targetValue = event.target.value
        if (handleChange1) {
            handleChange1(filterField, targetValue)
        }
        if (handleChange2) {
            handleChange2()
        }
        setValue(targetValue);
        if (handleClick1) {
            handleClick1()
        }
    };

    return (
        <FormControl className={classes.formControl} disabled={disabled ? disabled : false}>
            <TextField
                select
                id={id}
                label={label}
                value={value}
                defaultValue={value}
                disabled={disabled ? disabled : false}
                onChange={handleChange}
                helperText={label}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl >)
}