import React from "react"
import PrintJobTickets from "./PrintJobTickets"
import ExportJobsToExternalSystem from "./ExportJobsToExternalSystem"
import ImportJobsFromExternalSystem from "./ImportJobsFromExternalSystem"
import ImportJobsStatusFromExternalSystem from "./ImportJobsStatusFromExternalSystem"
import JobToSuppliers from "./JobToSuppliers"



export default function FilterScopeById(props) {
  const {
    adminTableRows,
    creation,
    handleClickClose,
    scopesJSON,
    scopeTypeId,
    selectedScopeData,
    setSelectedScopeData,
    setAdminTableRows,
    setScopesJSON,
    userScope,
  } = props
  if (scopeTypeId === 1) {
    return <PrintJobTickets
      adminTableRows={adminTableRows}
      creation={creation}
      handleClickClose={handleClickClose}
      scopesJSON={scopesJSON}
      userScope={userScope}
      selectedScopeData={selectedScopeData}
      setSelectedScopeData={setSelectedScopeData}
      setAdminTableRows={setAdminTableRows}
      setScopesJSON={setScopesJSON}
    />
  }
  else if (scopeTypeId === 2 || scopeTypeId === 5 || scopeTypeId === 6) {
    return <ExportJobsToExternalSystem
      adminTableRows={adminTableRows}
      creation={creation}
      handleClickClose={handleClickClose}
      scopesJSON={scopesJSON}
      userScope={userScope}
      selectedScopeData={selectedScopeData}
      setSelectedScopeData={setSelectedScopeData}
      setAdminTableRows={setAdminTableRows}
      setScopesJSON={setScopesJSON}
    />
  }
  else if (scopeTypeId === 3) {
    return <ImportJobsFromExternalSystem
      adminTableRows={adminTableRows}
      creation={creation}
      handleClickClose={handleClickClose}
      scopesJSON={scopesJSON}
      userScope={userScope}
      selectedScopeData={selectedScopeData}
      setSelectedScopeData={setSelectedScopeData}
      setAdminTableRows={setAdminTableRows}
      setScopesJSON={setScopesJSON}
    />
  }
  else if (scopeTypeId === 4) {
    return <JobToSuppliers
      adminTableRows={adminTableRows}
      creation={creation}
      handleClickClose={handleClickClose}
      scopesJSON={scopesJSON}
      userScope={userScope}
      selectedScopeData={selectedScopeData}
      setSelectedScopeData={setSelectedScopeData}
      setAdminTableRows={setAdminTableRows}
      setScopesJSON={setScopesJSON}
    />
  }
  else if (scopeTypeId === 8) {
    return <ImportJobsStatusFromExternalSystem
      adminTableRows={adminTableRows}
      creation={creation}
      handleClickClose={handleClickClose}
      scopesJSON={scopesJSON}
      userScope={userScope}
      selectedScopeData={selectedScopeData}
      setSelectedScopeData={setSelectedScopeData}
      setAdminTableRows={setAdminTableRows}
      setScopesJSON={setScopesJSON}
    />
  }
  else return null

}