import JobUtils from "components/Utils/JobUtils"
export default class CompanyUtils {

  //Conta il numero di lab presenti per la tabella labs
  static countCompanyId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (parseInt(a.companyId) > parseInt(b.companyId) ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return parseInt(rowsFiltered[numberRows - 1].companyId) + 1
    }
    else {
      return 1
    }

  }

  static getArrayLens = (lensTypes) => {
    var newLens = []
    if (lensTypes.length > 0) {
      lensTypes.map((lens) => newLens.push(lens.id))
      return newLens
    }
    else return newLens
  }

  static createArrayTable = (companyLens) => {
    const lens =
      JSON.parse(window.sessionStorage.getItem("lens")) ||
      JobUtils.lensTypeValues()
    var newLens = []
    if (companyLens.length > 0) {
      lens.map((option) =>
        companyLens.map((lens) => {
          if (option.id === lens) {
            return newLens.push(option)
          }
          return undefined
        })
      )
      return newLens
    }
    else return newLens
  }
}