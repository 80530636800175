import React, { useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"

// Pegaso components
import {
  AdminTableElevationScroll,
} from "components/AdminTable/AdminTableEditScreen"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from "@material-ui/core/styles"


function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    companyLabs,
    creation,
    handleClickClose,
    notFullTableData,
    setAdminTableRows,
    setCompany
  } = props
  //Classes utilizzate per CSS
  const {
    t,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  const countPersonId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (a.id > b.id ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return rowsFiltered[numberRows - 1].id + 1
    }
    else {
      return 1
    }

  }

  const [referenceData, setReferenceData] = useState(creation ?
    {
      id: countPersonId(adminTableRows),
      role: "",
      person: "",
      telephone: "",
      cellphone: "",
      email: undefined,
      labId: (companyLabs !== undefined && companyLabs.length > 0) ? "" : "1",
    } : adminTableRowData
  )

  const role = [
    {
      id: "chief",
      value: t("10176")
    },
    {
      id: "technician",
      value: t("10177")
    },
    {
      id: "CEO",
      value: t("10178")
    },
  ]

  //Setta il clientID
  const handleChange = (name) => (event) => {
    var referenceValue = event.target.value
    if (name !== "labId") {
      if (name === "email") {
        referenceValue = referenceValue.trim().length > 0 ? referenceValue : undefined
      }
      else {
        referenceValue = referenceValue.trim()
      }
    }
    setReferenceData({ ...referenceData, [name]: referenceValue })
  }


  //Elimina gli spazi finali dai campi
  const onBlurTrim = (reference, name) => (event) => {
    setReferenceData({ ...referenceData, [name]: reference.trim() })
  }


  const handleSave = () => {
    let newAdminTableRows = creation ? adminTableRows.concat(referenceData) :
      adminTableRows.filter((elem) => { return elem.id !== referenceData.id }).concat(referenceData)
    setAdminTableRows(newAdminTableRows)
    setCompany({
      ...notFullTableData, references: newAdminTableRows
    })
    handleClickClose()
  }

  return (
    <div>
      <AdminTableElevationScroll
        {...props}
        handleClickClose={handleClickClose}
      />

      <Grid container spacing={2}>
        <Grid className={classes.gridCurrentValues} item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p align="center">
                <u>{t("10129").toUpperCase()}</u>
              </p>
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                id="role"
                label={"role"}
                value={referenceData.role}
                helperText={t("10179")}
                onChange={handleChange("role")}
              >
                {role.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"person"}
                label={t("10180")}
                className={classesText.textField}
                value={referenceData.person}
                margin="normal"
                onChange={handleChange("person")}
                onBlur={referenceData.person ? onBlurTrim(referenceData.person, "person") : null}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"telephone"}
                label={t("10149")}
                className={classesText.textField}
                value={referenceData.telephone}
                margin="normal"
                onChange={handleChange("telephone")}
                onBlur={referenceData.telephone ? onBlurTrim(referenceData.telephone, "telephone") : null}

              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"cellphone"}
                label={t("10181")}
                className={classesText.textField}
                value={referenceData.cellphone}
                margin="normal"
                onChange={handleChange("cellphone")}
                onBlur={referenceData.cellphone ? onBlurTrim(referenceData.cellphone, "cellphone") : null}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"email"}
                label={t("10108")}
                className={classesText.textField}
                value={referenceData.email === undefined ? "" : referenceData.email}
                margin="normal"
                onChange={handleChange("email")}
                onBlur={referenceData.email ? onBlurTrim(referenceData.email, "email") : null}

              />
            </Grid>
            {
              (companyLabs !== undefined && companyLabs.length > 0) ? (
                <Grid item xs={3}>
                  <TextField
                    select
                    id="labId"
                    label={t("10141")}
                    value={referenceData.labId}
                    helperText={t("10141")}
                    onChange={handleChange("labId")}
                  >
                    {companyLabs.map((option) => (
                      <MenuItem key={option.labId} value={option.labId}>
                        {option.labId + "-" + option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

              ) : (
                <Grid item xs={3}>
                  <TextField
                    id={"labId"}
                    label={t("10141")}
                    className={classesText.textField}
                    value={referenceData.labId}

                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )
            }


            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={referenceData.labId === ""}
                  onClick={handleSave}
                >
                  {t("10010")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}


// Griglia interna alla finestra del Device
export default function Referece(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}