//Crea un pannello per la scelta della company sulla quale lavorare
import React, { useContext, useState, useEffect } from "react"
import { RootContext } from "../../RootContext"

//Material UI
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Card from "components/Card/Card.js"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import Typography from "@material-ui/core/Typography"

//Componenti Pegaso
import { CircularIndeterminate } from "../../views/Loading"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default function FilterCompany(props) {
  const {
    urlAccessCode,
    setUrlAccessCode,
    setCompanyName,
    setCompanyId,
    setChangeCompany,
    setCompanyLanguage,
    setCompanyScopes,
    setRetailerCompany,
  } = props

  const {
    allFilterCompanies,
    t,
  } = useContext(RootContext)

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [companyUrl, setCompanyUrl] = useState()

  useEffect(() => {
    if (urlAccessCode) {
      setUrlAccessCode(urlAccessCode)
    }
  }, [
    urlAccessCode,
    setUrlAccessCode,
    setChangeCompany,
  ])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return !allFilterCompanies ? (
    <div className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem>
          <ListItemText primary={t("10275").toUpperCase()} />
          <CircularIndeterminate />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      ></Menu>
    </div>
  ) : (
    <div>
      <Card>
        <div className={classes.root}>
          <Autocomplete
            id="filterCompany"
            style={{ width: 300 }}
            disableClearable={true}
            options={allFilterCompanies}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.companyId + "-" + option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("10042").toUpperCase()}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(event, newValue) => {
              const hostName = window.location.hostname
              if (
                hostName.indexOf("localhost") >= 0 ||
                hostName.indexOf("pre-admin") >= 0
              ) {
                setCompanyUrl(
                  "https://pre-mini.pegasoplatform.com/#/" +
                  newValue.urlAccessCode
                )
              } else {
                setCompanyUrl(
                  "https://mini.pegasoplatform.com/#/" + newValue.urlAccessCode
                )
              }
              setChangeCompany(true)
              setUrlAccessCode(newValue.urlAccessCode)
              if (setRetailerCompany) {
                setRetailerCompany(newValue.license.retailer.retailerId)
              }
              if (setCompanyScopes) {
                setCompanyScopes(newValue.companyScopes)
              }
              setCompanyName(newValue.name)
              setCompanyId(newValue.companyId)
              if (setCompanyLanguage) {
                setCompanyLanguage(newValue.language)
              }
              setAnchorEl(null)
            }}
          />
          {urlAccessCode ? (
            <Typography>
              <Link
                //href="#"
                onClick={() => {
                  window.open(companyUrl, "_blank")
                }}
                variant="body2"
              >
                {companyUrl}
              </Link>
            </Typography>
          ) :
            null}
        </div>
      </Card>
    </div>
  )
}
