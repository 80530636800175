import React, { useContext, useState } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import Device from "views/DevicesTable/Device"

//core components
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"


// Nomi dei campi delle colonne
function createData(
  idModel,
  model,
  brand,
  connection,
  active,

) {
  return {
    idModel,
    model,
    brand,
    connection,
    active,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.deviceId !== rowToDelete
  })
}


export default function DevicesTable(props) {
  const {
    allBrandsDevices,
    hubDevices,
    hubDevicesCount,
    selectedDeviceJSON,
    setSelectedDeviceJSON
  } = props

  const {
    t,
    isRetailerUser,
  } = useContext(RootContext)

  //Variabili
  const [isRetailer] = useState(isRetailerUser())

  const [filter] = useState([
    {
      id: "deviceId",
      value: ""
    },
    {
      id: "idModel",
      value: ""
    },
    {
      id: "model",
      value: ""
    },
    {
      id: "brand",
      value: ""
    },
    {
      id: "type",
      value:""
    },
    {
      id: "connection",
      value: ""
    },
  ])

  const headCells = [
    {
      id: "deviceId ",
      numeric: false,
      disablePadding: false,
      label: t("10135"),
    },
    {
      id: "idModel ",
      numeric: false,
      disablePadding: false,
      label: t("10136"),
    },
    {
      id: "model",
      numeric: false,
      disablePadding: false,
      label: t("10137"),
    },
    {
      id: "brand",
      numeric: false,
      disablePadding: false,
      label: t("10138"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: t("10139"),
    },
    {
      id: "connection",
      numeric: false,
      disablePadding: false,
      label: t("10140"),
    },
    { id: "active", numeric: false, disablePadding: false, label: t("10047") },
  ]
  const orderByDefault = "deviceId"


  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.deviceId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 6
  }


  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {row.model.modelId}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.model.description}
        </TableCell>
        <TableCell key={"tableBodyCell4" + key} align="left">
          {row.model.brand.brand}
        </TableCell>
        <TableCell key={"tableBodyCell5" + key} align="left">
          {t(row.model.type)}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {t(row.connection)}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.modelId,
            filter.filter((filter) => { return filter.id === "idModel" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.description,
            filter.filter((filter) => { return filter.id === "model" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.clientId,
            filter.filter((filter) => { return filter.id === "brand" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.lab ? row.lab.labId : "",
            filter.filter((filter) => { return filter.id === "connection" })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/

  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    var deleteRow
    setAdminTableRows(
      remapDeleteRow
        ? remapDeleteRow(adminTableRows, rowToDelete)
        : adminTableRows
    )
    deleteRow = selectedDeviceJSON.filter((device) => device.deviceId !== rowToDelete);
    setSelectedDeviceJSON(deleteRow)
  }

  const getModelData = (
    (deviceId, rows, setData) => {
      let deviceToEdit
      deviceToEdit = rows.filter(function (elem) {
        return elem.deviceId === deviceId
      })[0]
      setData(deviceToEdit)
    }
  )

  return (
    <div>
      <AdminTable
        AddEditElement={Device}
        AdminTableCells={AdminTableCells}
        allBrandsDevices={allBrandsDevices}
        createData={createData}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        disableAdd={isRetailer && hubDevicesCount >= 4}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getModelData}
        headCells={headCells}
        notFullTableData={hubDevices}
        isFullTable={false}
        isDevice = {true}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        useCompany={true}
        selectedDeviceJSON={selectedDeviceJSON}
        setSelectedDeviceJSON={setSelectedDeviceJSON}
      />
    </div>
  )
}
