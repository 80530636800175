//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import UserUtils from "components/Utils/UserUtils"

//pegaso/views/components

import { AdminTableElevationScroll } from "components/AdminTable/AdminTableEditScreen"
import DeviceUtils from "components/Utils/DeviceUtils"
import FilterDeviceType from "../Device/FilterDeviceType"

export default function FilterDevice(props) {
  const { t, } = useContext(RootContext)

  const {
    adminTableRows,
    allBrandsDevices,
    creation,
    isHub,
    handleClickClose,
    handleChangeSettings,
    hubDevicesJSON,
    userData,
    userDevice,
    selectedDeviceJSON,
    setAdminTableRows,
    setHubDevicesJSON,
    setSelectedDeviceJSON,
  } = props

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()


  //Funzioni per settari i valori
  const ipPortValue = () => {
    if (connectionType === "ip") {
      return userDevice.ip.port
    } else {
      return ""
    }
  }
  const portValue = () => {
    if (userDevice.connection === "serial") {
      let app = userDevice.serial.port.split("COM")
      return app[1]
    } else {
      return ""
    }
  }
  const ipValue = () => {
    if (connectionType === "ip") {
      return userDevice.ip.address
    } else {
      return ""
    }
  }
  const settingValue = () => {
    if (connectionType === "serial") {
      let appData = userDevice.serial.settings.split(",")
      return {
        speedPort: appData[0],
        parityBit: appData[1],
        dataBit: appData[2],
        stopBit: appData[3],
      }
    } else {
      return {
        speedPort: "",
        parityBit: "",
        dataBit: "",
        stopBit: "",
      }
    }
  }

  //Valori dei dati

  var hubDeviceId = 0
  if (isHub) {
    hubDeviceId =
      creation || !userDevice
        ? DeviceUtils.countDeviceId(adminTableRows)
        : userDevice.deviceId
  }

  const [active, setActive] = useState(
    creation || !userDevice ? false : userDevice.active
  )
  const [allInOne, setAllInOne] = useState(
    creation || !userDevice ? false : userDevice.allInOne
  )
  const [brandId, setBrandId] = useState(
    creation || !userDevice ? "" : userDevice.model.brand.brandId
  )
  const [brandModels, setBrandModels] = useState(
    creation || !userDevice
      ? []
      : allBrandsDevices.filter((option) => {
        return option.brandId === brandId
      })[0].models
  )
  const [changeDevice, setChangeDevice] = useState(false)
  const [comunicationPort, setComunicationPort] = useState(
    creation || !userDevice ? undefined : portValue
  )
  const [connectionType, setConnectionType] = useState(
    creation || !userDevice ? undefined : userDevice.connection
  )
  const [ipAddress, setIpAddress] = useState(ipValue)
  const [ipPort, setIpPort] = useState(ipPortValue)
  const [onlyShape, setOnlyShape] = useState(
    creation || !userDevice ? false : userDevice.onlyShape
  )
  const [protocol, setProtocol] = useState(
    creation || !userDevice ? undefined : userDevice.protocol
  )
  const [selectedDeviceId, setSelectedDeviceId] = useState(
    creation || !userDevice ? "" : userDevice.model.modelId
  )
  const [selectedDeviceData, setSelectedDeviceData] = useState(
    creation || !userDevice
      ? {}
      : brandModels.filter((option) => {
        return option.modelId === selectedDeviceId
      })[0]
  )
  const [settings, setSettings] = useState(settingValue)

  var editableBrand = true
  if (isHub && !creation) {
    editableBrand = false
  }

  //Valori setting porta di comunicazione (DA ESPORTARE IN UN FILE UTILS)
  const speedPortValue = DeviceUtils.speedPortValue()

  const dataBitValue = DeviceUtils.dataBitValue()

  const stopBitValue = DeviceUtils.stopBitValue()

  const parityBitValue = DeviceUtils.parityBitValue()
  /*************************************
   *
   * Funzioni
   *
   *************************************/

  //Inserisce in brandModels i device del brand selezionato
  function changeBrandModels(brandId) {
    setBrandModels(
      allBrandsDevices.filter((option) => {
        return option.brandId === brandId
      })[0].models
    )
  }

  function getSettings(device) {
    let appData = device.serial.settings.split(",")
    return {
      speedPort: appData[0],
      parityBit: appData[1],
      dataBit: appData[2],
      stopBit: appData[3],
    }
  }

  function getServicePort(userData) {
    if (userData.settings)
      if (userData.settings.servicePort) return userData.settings.servicePort
    return ""
  }

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia brand utente
  const handleChangeBrandId = (event) => {
    let value = event.target.value
    setBrandId(value)
    setSelectedDeviceId("")
    changeBrandModels(value)
    setSelectedDeviceJSON(undefined)
    setConnectionType(undefined)
  }

  //Cambia il device selezionato
  const handleSelectDevice = (event) => {
    let value, selected
    value = event.target.value
    setSelectedDeviceId(value)
    selected = brandModels.filter((option) => {
      return option.modelId === value
    })[0]
    setSelectedDeviceData(selected)
    setComunicationPort(selected.serial.port.split("COM")[1])
    setSettings(getSettings(selected))
    setChangeDevice(true)
    setProtocol(selected.protocol)
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      model: value,
      protocol: selected.protocol,
      allInOne: selected.allInOne,
      serial: selected.serial,
    })
  }

  //Attiva il device
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
    setSelectedDeviceJSON({ ...selectedDeviceJSON, active: value })
  }

  //Attiva il AllInOne
  const handleChangeOnlyShape = (event) => {
    let value = event.target.checked
    setOnlyShape(value)
    setSelectedDeviceJSON({ ...selectedDeviceJSON, onlyShape: value })
  }

  //Attiva il AllInOne
  const handleChangeAllInOne = (event) => {
    let value = event.target.checked
    setAllInOne(value)
    setSelectedDeviceJSON({ ...selectedDeviceJSON, allInOne: value })
  }

  //Cambia il tipo di connessione, SERIALE
  const handleChangeSerial = (event) => {
    let value = event.target.checked
    if (value) {
      setConnectionType("serial")
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: "serial",
        serial: selectedDeviceData.serial,
      })
    } else {
      setConnectionType(undefined)
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: undefined,
        serial: undefined,
        ip: undefined,
      })
    }
  }

  //Cambia il tipo di connessione, IP
  const handleChangeIp = (event) => {
    let value = event.target.checked
    if (value) {
      setConnectionType("ip")
      setSelectedDeviceJSON({ ...selectedDeviceJSON, connection: "ip" })
    } else {
      setConnectionType(undefined)
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: undefined,
        serial: undefined,
        ip: undefined,
      })
    }
  }

  //Cambia porta com o indirizzo IP
  const handleComunicationPort = (event) => {
    let value = event.target.value
    setComunicationPort(value)
    if (connectionType === "serial") {
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        serial: {
          port: "COM" + value,
          timeout: selectedDeviceData.serial.timeout,
          settings:
            settings.speedPort +
            "," +
            settings.parityBit +
            "," +
            settings.dataBit +
            "," +
            settings.stopBit,
          handshake: selectedDeviceData.serial.handshake,
          readBufferSize: selectedDeviceData.serial.readBufferSize,
          writeBufferSize: selectedDeviceData.serial.writeBufferSize,
          readTimeout: selectedDeviceData.serial.readTimeout,
          writeTimeout: selectedDeviceData.serial.writeTimeout,
          rts: selectedDeviceData.serial.rts,
          dtr: selectedDeviceData.serial.dtr,
          discardNull: selectedDeviceData.serial.discardNull,
        },
      })
    } else {
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        ip: {
          address: value,
        },
      })
    }
  }

  //Modifica indirizzo/porta nel JSON
  const onBlurComunicationPort = () => {
    /*if (opticianId === "") {
       setUsername("")
       setOpticianName("")
       setOpticianPhone("")
     } else {
       fetchOpticianId()
     }*/
  }

  //Modifica velocità porta
  const handleChangeSpeedPort = (event) => {
    let value = event.target.value
    setSettings({ ...settings, speedPort: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          value +
          "," +
          settings.parityBit +
          "," +
          settings.dataBit +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica parityBit
  const handleChangeParityBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, parityBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          value +
          "," +
          settings.dataBit +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica dataBit
  const handleChangeDataBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, dataBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          settings.parityBit +
          "," +
          value +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica stopBit
  const handleChangeStopBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, stopBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          settings.parityBit +
          "," +
          settings.dataBit +
          "," +
          value,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica address indirizzo ip
  const handleAddressIp = (event) => {
    let value = event.target.value
    setIpAddress(value)
  }

  const onBlurAddressIp = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      ip: {
        address: ipAddress,
        port: ipPort,
        timeout: 0,
      },
    })
  }

  //Modifica porta IP
  const handleIpPort = (event) => {
    let value = event.target.value
    setIpPort(value)
  }

  const onBlurIpPort = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      ip: {
        address: ipAddress,
        port: ipPort,
        timeout: 0,
      },
    })
  }

  //Associata al bottone save, utilizzata solo da Hub salva i dati del device aggiunto
  const handleSave = () => {
    let newJson
    if (creation) {
      var device = UserUtils.getBrandModel(
        selectedDeviceJSON,
        allBrandsDevices,
        hubDeviceId
      )
      setHubDevicesJSON((hubDevicesJSON) => [...hubDevicesJSON, device])
    } else {
      newJson = hubDevicesJSON.map((elem) => {
        if (elem.deviceId === hubDeviceId) {
          return selectedDeviceJSON
        } else return elem
      })
      setHubDevicesJSON(newJson)
    }

    let newAdminTableRows = creation ? adminTableRows.concat(device) : newJson
    setAdminTableRows(newAdminTableRows)
    handleClickClose()
  }


  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      brandId === "" ||
      selectedDeviceId === "" ||
      !connectionType
    return result
  }

  return (
    <div>
      {isHub ? (
        <AdminTableElevationScroll
          {...props}
          handleClickClose={handleClickClose}
        />
      ) : null}
      <Grid container spacing={2}>
        <Grid className={classes.gridCurrentValues} item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p align="center">
                <u>{t("10043").toUpperCase()}</u>
              </p>
            </Grid>

            {/*Riga 1*/}

            <Grid item xs={5}>
              <TextField
                select
                id="brands"
                label={t("10044")}
                value={brandId}
                style={{ width: 150 }}
                onChange={handleChangeBrandId}
                helperText={t("10045")}
                disabled={!editableBrand}
              >
                {allBrandsDevices
                  .sort(function compare(a, b) {
                    if (a.brand < b.brand) {
                      return -1
                    }
                    if (a.brand > b.brand) {
                      return 1
                    }
                    return 0
                  })
                  .map((option) => (
                    <MenuItem key={option.brandId} value={option.brandId}>
                      {option.brand}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={selectedDeviceData.type === "Blocker" ? 4 : 5}>
              <TextField
                select
                id="nameDevice"
                label={t("10046")}
                style={{ width: 150 }}
                value={selectedDeviceId ? selectedDeviceId : ""}
                onChange={handleSelectDevice}
                helperText={t("10046")}
                disabled={!editableBrand}
              >
                {brandModels
                  .sort(function compare(a, b) {
                    if (a.description < b.description) {
                      return -1
                    }
                    if (a.description > b.description) {
                      return 1
                    }
                    return 0
                  })
                  .map((option) => (
                    <MenuItem key={option.modelId} value={option.modelId}>
                      {option.description}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            {
              selectedDeviceData.type === "Blocker" ? (
                <Grid item xs={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allInOne}
                        onChange={handleChangeAllInOne}
                        name="allInOne"
                      />
                    }
                    label={t("10269")}
                  />
                </Grid>

              ) : (null)
            }

            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyShape}
                    onChange={handleChangeOnlyShape}
                    name="onlyShape"
                  />
                }
                label={t("10282")}
              />
            </Grid>

            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={handleChangeActive}
                    disabled={!brandId ? true : false}
                    name="active"
                  />
                }
                label={t("10047")}
              />
            </Grid>

            {/*Riga 2*/}

            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={connectionType === "serial"}
                    disabled={
                      connectionType === "ip" || selectedDeviceId === ""
                    }
                    onChange={handleChangeSerial}
                    name="serial"
                  />
                }
                label={t("10048")}
              />
            </Grid>

            <Grid item xs={7}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={connectionType === "ip"}
                    disabled={
                      connectionType === "serial" || selectedDeviceId === ""
                    }
                    onChange={handleChangeIp}
                    name="ip"
                  />
                }
                label={t("10049")}
              />
            </Grid>

            {connectionType === undefined ? null : connectionType ===
              "serial" ? (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <TextField
                      id={"comunicationPort"}
                      label={t("10050")}
                      className={classesText.textField}
                      style={{ width: 150 }}
                      value={comunicationPort}
                      helperText={t("10051")}
                      disabled={selectedDeviceId === ""}
                      margin="normal"
                      onChange={handleComunicationPort}
                      onBlur={onBlurComunicationPort}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="speedPort"
                      label={t("10052")}
                      disabled={selectedDeviceId === ""}
                      style={{ width: 150 }}
                      value={settings.speedPort}
                      onChange={handleChangeSpeedPort}
                      helperText={t("10052")}
                    >
                      {speedPortValue.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="parityBit"
                      label={t("10053")}
                      value={settings.parityBit}
                      style={{ width: 150 }}
                      disabled={selectedDeviceId === ""}
                      onChange={handleChangeParityBit}
                      helperText={t("10053")}
                    >
                      {parityBitValue.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="dataBit"
                      label={t("10054")}
                      style={{ width: 150 }}
                      value={settings.dataBit}
                      disabled={selectedDeviceId === ""}
                      onChange={handleChangeDataBit}
                      helperText={t("10054")}
                    >
                      {dataBitValue.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="stopBit"
                      label={t("10055")}
                      style={{ width: 150 }}
                      value={settings.stopBit}
                      disabled={selectedDeviceId === ""}
                      onChange={handleChangeStopBit}
                      helperText={t("10055")}
                    >
                      {stopBitValue.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {
                    isHub === false ? (
                      <Grid item xs={3}>
                        <TextField
                          id={"servicePort"}
                          label={t("service port")}
                          className={classesText.textField}
                          value={getServicePort(userData)}
                          margin="normal"
                          onChange={handleChangeSettings("servicePort")}
                        />
                      </Grid>
                    ) : (
                      null
                    )
                  }

                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <TextField
                      id={"ipAddress"}
                      label={t("10056")}
                      className={classesText.textField}
                      value={ipAddress}
                      disabled={selectedDeviceId === ""}
                      margin="normal"
                      onChange={handleAddressIp}
                      onBlur={onBlurAddressIp}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"ipPort"}
                      label={t("10057")}
                      className={classesText.textField}
                      value={ipPort}
                      disabled={selectedDeviceId === ""}
                      margin="normal"
                      onChange={handleIpPort}
                      onBlur={onBlurIpPort}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {selectedDeviceId && active && connectionType !== undefined ? (
            <FilterDeviceType
              changeDevice={changeDevice}
              connectionType={connectionType}
              deviceProtocol={protocol}
              isCreationModel={creation}
              selectedDeviceData={selectedDeviceData}
              setDeviceProtocol={setProtocol}
              selectedDeviceJSON={selectedDeviceJSON}
              setChangeDevice={setChangeDevice}
              setSelectedDeviceJSON={setSelectedDeviceJSON}
            />
          ) : null}
          {isHub ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                  disabled={disableSave()}
                >
                  {t("10010")}
                </Button>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  )
}
