import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//core components

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

//pegaso/views/components
import ScopeUtils from "components/Utils/ScopeUtils";


export default function JobToSuppliers(props) {

  const {
    adminTableRows,
    creation,
    handleClickClose,
    scopesJSON,
    selectedScopeData,
    setAdminTableRows,
    setScopesJSON,
    userScope,
  } = props

  const {
    t,
    getRole,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  const portValue = () => {
    let app = userScope.hubSettings.supplier.comPort.split("COM")
    return app[1]
  }

  //Variabili dello scope
  const [active, setActive] = useState((creation || !userScope) ? true : userScope.active)
  const hubScopeId = ((creation || !userScope) ? ScopeUtils.countScopeId(adminTableRows) : userScope.hubScopeId)
  const [comunicationPort, setComunicationPort] = useState((creation || !userScope) ? "" : portValue)
  const [name, setName] = useState((creation || !userScope) ? "" : userScope.hubSettings.supplier.name)
  const [eltimaLicense, setEltimaLicense] = useState((creation || !userScope) ? "" : userScope.hubSettings.supplier.eltimaLicense)
  const scopesData = ((creation || !userScope) ? selectedScopeData.scopes[0] : userScope.scopeFull)
  var userRole = getRole()


  let scopeFull
  if (creation) {
    scopeFull = {
      active: scopesData.active,
      createdOn: scopesData.createdOn,
      defaultSettings: scopesData.defaultSettings,
      scopeId: scopesData.scopeId,
      scopeType: {
        description: creation ? selectedScopeData.description : userScope.scopeFull.scopeType.description,
        scopeTypeId: creation ? selectedScopeData.scopeTypeId : userScope.scopeFull.scopeType.scopeTypeId,
      }
    }
  }


  /*************************************
     *
     * Handler per modificare i valori
     *
     *************************************/

  //Stato attivo scope
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
  }

  //Setta porta di comunicazione
  const handleComunicationPort = (event) => {
    let value = event.target.value
    setComunicationPort(value)
  }

  //Nome del servizio 
  const handleName = (event) => {
    let value = event.target.value
    setName(value)
  }

  //Setta porta di comunicazione
  const handleEltimaLicense = (event) => {
    let value = event.target.value
    setEltimaLicense(value)
  }

  //Salva le impostazioni
  const handleSave = () => {
    let value
    value = {
      hubScopeId: hubScopeId,
      scopeId: scopesData.scopeId,
      active: active,
      hubSettings: {
        supplier: {
          name: name,
          comPort: "COM" + comunicationPort,
          eltimaLicense: eltimaLicense
        }
      },
      scopeFull: creation ? scopeFull : scopesData

    }
    ScopeUtils.saveScopeJSON(scopesJSON, setScopesJSON, creation, value, hubScopeId)
    handleClickClose()
    let newAdminTableRows = creation
      ? adminTableRows.concat(value)
      : adminTableRows.map((elem) => {
        if (elem.hubScopeId === userScope.hubScopeId) {
          return value
        } else {
          return elem
        }
      })
    setAdminTableRows(newAdminTableRows)
  }

   // Campi obbligatori per abilitare il tasto SAVE
   function disableSave() {
    let result = comunicationPort === "" || name === ""

    return result
  }

  return (
    <Grid className={classes.gridCurrentValues} item xs={12}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10068").toUpperCase()}</u>
          </p>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"comunicationPort"}
            label={t("10077")}
            className={classesText.textField}
            value={comunicationPort}
            helperText={t("10078")}
            margin="normal"
            onChange={handleComunicationPort}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"name"}
            label={t("10079")}
            className={classesText.textField}
            value={name}
            margin="normal"
            onChange={handleName}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id={"eltimaLicense"}
            label={t("10290")}
            className={classesText.textFieldLarge}
            value={eltimaLicense}
            margin="normal"
            onChange={handleEltimaLicense}
            disabled={userRole===6}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={handleChangeActive}
                name="active"
              />
            }
            label={t("10047")}
          />
        </Grid>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          p={1}
          className={classes.gridToolbar}
        >
          <Box p={1}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={disableSave()}
              onClick={handleSave}
            >
              {t("10010")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}