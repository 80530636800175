import React, { useState } from "react"


//pegaso/views/components
import FilterDevice from "components/Filter/FilterDevice/"

//core components
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

//import CSS

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allBrandsDevices,
    creation,
    handleClickClose,
    setAdminTableRows,
    selectedDeviceJSON,
    setSelectedDeviceJSON
  } = props

  //Variabili
  const [addDevice, setAddDevice] = useState(creation ? undefined : adminTableRowData)
  if (selectedDeviceJSON === undefined)
    setSelectedDeviceJSON([])


  return (
    <div>
      <FilterDevice
        adminTableRows={adminTableRows}
        allBrandsDevices={allBrandsDevices}
        creation={creation}
        isHub={true}
        handleClickClose={handleClickClose}
        hubDevicesJSON={selectedDeviceJSON}
        userDevice={adminTableRowData}
        selectedDeviceJSON={addDevice}
        setSelectedDeviceJSON={setAddDevice}
        setAdminTableRows={setAdminTableRows}
        setHubDevicesJSON={setSelectedDeviceJSON}
      />

    </div>
  )
}


// Griglia interna alla finestra del Device
export default function Device(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}