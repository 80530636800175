import React, { useContext, Fragment } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import AdminTableUtils from "components/AdminTable/AdminTableUtils"

//core components
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"

//import CSS
import {
  useAdminTableEditScreenStyles
} from "styles/adminTable/adminTableEditScreenCss"

function AdminTableElevationScroll(props) {
  const { t } = useContext(RootContext)
  const { handleClickClose } = props
  const ElevationScroll = AdminTableUtils.ElevationScroll

  return (
    <ElevationScroll {...props}>
      <AppBar
        style={{ background: "transparent", boxShadow: "none" }}
        position="sticky"
      >
        <Box display="flex" flexDirection="row-reverse" p={1}>
          <Box p={1}>
            <IconButton
              aria-label={t("10009")}
              variant="outlined"
              onClick={handleClickClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
    </ElevationScroll>
  )
}

function AdminTableOkSaved(props) {
  const { dialogMsg, handleClose, isSaving, openDialog } = props

  return (
    <Dialog
      open={isSaving || openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
      {openDialog ? (
        <Fragment>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogContent>
            <DialogContent>
              <CircularProgress size={42} style={{ color: "primary" }} />
            </DialogContent>
          </DialogContent>
        </Fragment>
      )}
    </Dialog>
  )
}

function AdminTableSaveButton(props) {
  const { t } = useContext(RootContext)
  const { handleSave, disabled, buttonLabel } = props

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()

  /*Da Verificare se i due bottoni deveno essere sempre attivi o meno
    e se servono entrambi
    */

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      p={1}
      className={classes.gridToolbar}
    >
      <Box p={1}>
        <Button
          disabled={disabled}
          variant="outlined"
          color="secondary"
          onClick={handleSave}
        >
          {buttonLabel? buttonLabel : t("10010")}
        </Button>
      </Box>
    </Box>
  )
}

export { AdminTableElevationScroll, AdminTableOkSaved, AdminTableSaveButton }
