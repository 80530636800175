import React, { useContext, useState } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import HubUtils from "components/Utils/HubUtils"
import Scope from "views/ScopesTable/Scope"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

// Nomi dei campi delle colonne
function createData(
  hubScopeId,
  description,
  active,

) {
  return {
    hubScopeId,
    description,
    active,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.hubScopeId !== rowToDelete
  })
}


export default function ScopesTable(props) {
  const {
    allScopes,
    hubId,
    hubScopes,
    scopesJSON,
    urlAccessCode,
    setScopesJSON,
  } = props


  const {
    t,
  } = useContext(RootContext)

  const [filter] = useState([
    {
      id: "hubScopeId",
      value: ""
    },
    {
      id: "description",
      value: ""
    },
    {
      id: "active",
      value: ""
    },
  ])

  const headCells = [
    {
      id: "hubScopeId",
      numeric: false,
      disablePadding: false,
      label: t("10183"),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: t("10110"),
    },
    { id: "active", numeric: false, disablePadding: false, label: t("10047") },
  ]
  const orderByDefault = "hubScopeId"

  /*************************************
  *
  * Cells
  *
  *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.hubScopeId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 3
  }


  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.scopeFull.scopeType.description +  checkSupplierAndSystem(row)}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  function checkSupplierAndSystem(row){
    if(row.scopeFull.scopeType.scopeTypeId === 2 || row.scopeFull.scopeType.scopeTypeId === 3){
      return " - " + row.hubSettings.system
    }
    else if(row.scopeFull.scopeType.scopeTypeId === 4){
      return " - " + row.hubSettings.supplier.name
    }
    else if(row.scopeFull.scopeType.scopeTypeId === 8){
      return " - " + row.hubSettings.system.substring(0, row.hubSettings.system.indexOf("STATUS"))
    }

    else return ""
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.hubScopeId,
            filter.filter((filter) => { return filter.id === "hubScopeId" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.scopeFull.scopeType.description,
            filter.filter((filter) => { return filter.id === "description" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.active,
            filter.filter((filter) => { return filter.id === "active" })[0].value
          )
        })
      : rows
  }


  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    var deleteRow
    setAdminTableRows(
      remapDeleteRow
        ? remapDeleteRow(adminTableRows, rowToDelete)
        : adminTableRows
    )
    var rowToDeleteData = adminTableRows[rowToDelete-1]
    if (rowToDeleteData.hubSettings.topicId !== undefined){
      HubUtils.deleteAwsQueue(urlAccessCode,  hubId, rowToDeleteData.hubSettings.topicId)
    }
    deleteRow = scopesJSON.filter((scope) => scope.hubScopeId !== rowToDelete)
    setScopesJSON(deleteRow)
  }

  const getRowData = (
    (hubScopeId, rows, setData) => {
      let scopeToEdit
      scopeToEdit = rows.filter(function (elem) {
        return elem.hubScopeId === hubScopeId
      })[0]
      setData(scopeToEdit)
    }
  )

  return (
    <div>
      <AdminTable
        AddEditElement={Scope}
        AdminTableCells={AdminTableCells}
        allScopes={allScopes}
        createData={createData}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getRowData}
        headCells={headCells}
        notFullTableData={hubScopes}
        isFullTable={false}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        useCompany={true}
        urlAccessCode={urlAccessCode}
        scopesJSON={scopesJSON}
        setScopesJSON={setScopesJSON}
      />
    </div>
  )
}
