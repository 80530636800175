import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import LabUtils from "components/Utils/LabUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import JobRoutingToSuppliersTable from "views/JobRoutingToSuppliers/JobRoutingToSuppliersTable"

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allScopes,
    creation,
    companyId,
    companyName,
    companyScopes,
    handleClickClose,
    setAdminTableRows,
    urlAccessCode,
  } = props

  const {
    apiUrl,
    access_token,
    getRetailer,
    refresh_token,
    setAccess_token,
    t,
  } = useContext(RootContext)

  const UTCList = UserUtils.UTCList()


  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [retailer] = useState(getRetailer())
  const isIndependent = retailer ? retailer.isIndependent ? retailer.isIndependent : false : false

  //Valori dei Dati
  const [lab, setLab] = useState(
    creation
      ? {
        labId: LabUtils.countLabId(adminTableRows),
        name: "",
        phone: "",
        email: "",
        address: {
          street: "",
          number: "",
          city: "",
          province: "",
          state: "",
          zipCode: "",
        },
        maxInternalJobId: "2000",
        internalOpticianId: "LAB" + LabUtils.countLabId(adminTableRows).toString(),
        active: true,
        labScopes: [],
        utc: 1,
      }
      : {
        labId: adminTableRowData.labId,
        name: adminTableRowData.name,
        phone: adminTableRowData.phone,
        email: adminTableRowData.email,
        address: {
          street: adminTableRowData.address.street,
          number: adminTableRowData.address.number,
          city: adminTableRowData.address.city,
          province: adminTableRowData.address.province,
          state: adminTableRowData.address.state,
          zipCode: adminTableRowData.address.zipCode,
        },
        maxInternalJobId: adminTableRowData.maxInternalJobId,
        internalOpticianId: adminTableRowData.internalOpticianId,
        active: adminTableRowData.active,
        labScopes: adminTableRowData.labScopes,
        utc: adminTableRowData.utc ? adminTableRowData.utc : 1
      }
  )
  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setLab({ ...lab, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (labParameter, name) => (event) => {
    setLab({ ...lab, [name]: labParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setLab({ ...lab, [name]: event.target.checked })
  }

  //Cambia Lab.Address field
  const handleChangeAddress = (name) => (event) => {
    setLab({ ...lab, address: { ...lab.address, [name]: event.target.value } })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrimAddress = (addressParameter, name) => (event) => {
    setLab({ ...lab, address: { ...lab.address, [name]: addressParameter.trim() } })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${urlAccessCode}/labs/${lab.labId}`
    var prepareMail = undefined
    if(lab.email){
      if(lab.email.trim().length>0){
        prepareMail = lab.email
      }
    }
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        labId: lab.labId,
        name: lab.name,
        phone: lab.phone,
        email: prepareMail,
        address: {
          street: lab.address.street,
          number: lab.address.number,
          city: lab.address.city,
          province: lab.address.province,
          state: lab.address.state,
          zipCode: lab.address.zipCode,
        },
        maxInternalJobId: lab.maxInternalJobId,
        internalOpticianId: lab.internalOpticianId,
        active: lab.active,
        labScopes: lab.labScopes,
        utc: lab.utc
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10157")}\n\nLab: ${lab.labId}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Aggiorna la lista utenti
        let newElem = {
          labId: lab.labId,
          name: lab.name,
          phone: lab.phone,
          email: prepareMail,
          address: {
            street: lab.address.street,
            number: lab.address.number,
            city: lab.address.city,
            province: lab.address.province,
            state: lab.address.state,
            zipCode: lab.address.zipCode,
          },
          maxInternalJobId: lab.maxInternalJobId,
          internalOpticianId: lab.internalOpticianId,
          active: lab.active,
          labScopes: lab.labScopes,
          utc: lab.utc,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.labId === lab.labId) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  const handleCopyCompanyData = () => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${urlAccessCode}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        setLab({
          ...lab,
          name: data.name,
          email: data.email,
          address: data.address,
        })
        setIsSaving(false)
      })
      .catch((err) => {
        setDialogMsg(t("20"))
        ErrorUtils.errorLog(t("20"), err)
      })
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !lab.labId ||
      !lab.name ||
      !lab.maxInternalJobId ||
      !lab.internalOpticianId
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10158").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid
              className={classes.gridCurrentValues}
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              {/* prima riga - la somma degli xs non deve superare 12  */}
              <Grid item xs={6}>
                <p>
                  <strong>
                    {t("10145")}: {companyId + " - " + companyName}
                  </strong>
                </p>

              </Grid>
              <Grid item xs={6}>
                <Box p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCopyCompanyData}
                  >
                    {t("10259")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DEL LABORATORIO*/}

                {/*RIGA 1*/}
                <Grid item xs={3}>
                  <TextField
                    required
                    id={"labId"}
                    label={t("10141")}
                    value={lab.labId || ""}
                    margin="normal"
                    helperText={t("10105")}
                    onChange={handleChange("labId")}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"name"}
                    label={t("10079")}
                    onChange={handleChange("name")}
                    onBlur={onBlurTrim(lab.name, "name")}
                    className={classesText.textField}
                    value={lab.name || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"phone"}
                    label={t("10149")}
                    onChange={handleChange("phone")}
                    onBlur={onBlurTrim(lab.phone, "phone")}
                    className={classesText.textField}
                    value={lab.phone || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"email"}
                    label={t("10108")}
                    onChange={handleChange("email")}
                    onBlur={onBlurTrim(lab.email, "email")}
                    className={classesText.textField}
                    value={lab.email || ""}
                    margin="normal"
                  />
                </Grid>

                {/*RIGA 2*/}
                <Grid item xs={3}>
                  <TextField
                    id={"street"}
                    label={t("10121")}
                    onChange={handleChangeAddress("street")}
                    onBlur={onBlurTrimAddress(lab.address.street, "street")}
                    className={classesText.textField}
                    value={lab.address.street || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"number"}
                    label={t("10122")}
                    onChange={handleChangeAddress("number")}
                    onBlur={onBlurTrimAddress(lab.address.number, "number")}
                    className={classesText.textField}
                    value={lab.address.number || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"city"}
                    label={t("10123")}
                    onChange={handleChangeAddress("city")}
                    onBlur={onBlurTrimAddress(lab.address.city, "city")}
                    className={classesText.textField}
                    value={lab.address.city || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"province"}
                    label={t("10124")}
                    onChange={handleChangeAddress("province")}
                    onBlur={onBlurTrimAddress(lab.address.province, "province")}
                    className={classesText.textField}
                    value={lab.address.province || ""}
                    margin="normal"
                  />
                </Grid>

                {/*RIGA 3*/}

                <Grid item xs={3}>
                  <TextField
                    id={"state"}
                    label={t("10125")}
                    onChange={handleChangeAddress("state")}
                    onBlur={onBlurTrimAddress(lab.address.state, "state")}
                    className={classesText.textField}
                    value={lab.address.state || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"zipCode"}
                    label={t("10126")}
                    onChange={handleChangeAddress("zipCode")}
                    onBlur={onBlurTrimAddress(lab.address.zipCode, "zipCode")}
                    className={classesText.textField}
                    value={lab.address.zipCode || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id="utc"
                    label={"UTC"}
                    value={lab.utc}
                    onChange={handleChange("utc")}
                    helperText={t("10107")}
                  >
                    {UTCList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lab.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                {/*RIGA 5*/}
                <Grid item xs={6}>
                  <TextField
                    required
                    id={"maxInternalJobId"}
                    label={t("10155")}
                    helperText={isIndependent ? t("10105") : ""}
                    onChange={handleChange("maxInternalJobId")}
                    onBlur={onBlurTrim(lab.maxInternalJobId, "maxInternalJobId")}
                    className={classesText.textField}
                    value={lab.maxInternalJobId || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: isIndependent,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    id={"internalOpticianId"}
                    label={t("10156")}
                    helperText={t("10105")}
                    onChange={handleChange("internalOpticianId")}
                    onBlur={onBlurTrim(lab.internalOpticianId, "internalOpticianId")}
                    className={classesText.textField}
                    value={lab.internalOpticianId || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>


              </Grid>
            </Grid>
          </Grid>

          {
            allScopes.length > 0 ? (
              companyScopes.length > 0 ? (
                companyScopes.some(item => item.scopeId === parseInt(8) && item.active) ? (
                  <div>
                    {/*GRID CONTENENTE LE SCOPES DELL'UTENTE*/}
                    <Grid item xs={12}>
                      <p align="center">
                        <u>{t("10159").toUpperCase()} </u>
                      </p>
                    </Grid>
                    <JobRoutingToSuppliersTable
                      allScopes={allScopes.filter((option) => { return option.scopeTypeId === 6 })}
                      lab={lab}
                      setLab={setLab}
                    />
                  </div>
                ) : (
                  null
                )
              ) : (
                null
              )
            ) : (
              null
            )
          }

          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del Laboratory
export default function Laboratory(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
