import React, { useState, useContext, useCallback, useEffect } from "react"
import { RootContext } from "RootContext"
import jsPDF from "jspdf";
import "jspdf-autotable";
//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"
import Model from "views/Model/Model"
import { stableSort, getComparator } from "components/AdminTable/AdminTableSort"
import UserUtils from "components/Utils/UserUtils"
import DeviceUtils from "components/Utils/DeviceUtils"


// core components

import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"


// Nomi dei campi delle colonne
function createData(modelId, brand, description, type) {
  return {
    modelId,
    brand,
    description,
    type,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.modelId !== rowToDelete
  })
}

export default function Models() {
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)


  const [allBrands, setAllBrands] = useState([])

  const headCells = [
    {
      id: "modelId",
      numeric: true,
      disablePadding: false,
      label: t("10136").toUpperCase(),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: t("10079").toUpperCase(),
    },
    {
      id: "brand",
      numeric: false,
      disablePadding: false,
      label: t("10138").toUpperCase(),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: t("10139").toUpperCase(),
    }
  ]
  const [isFetching, setIsFetching] = useState(true)
  const orderByDefault = "description"
  const urlGet = `${apiUrl}/models`
  const urlDelete = `${apiUrl}/models/`
  const [selectBrands, setSelectBrands] = useState([])

  const [filter, setFilter] = useState([
    {
      id: "modelId",
      value: "",
      label: t("10136"),
    }, {
      id: "brand",
      value: "",
      defaultValue: "",
      isSelect: true,
      selectOption: selectBrands,
      label: t("10138")
    },
    {
      id: "name",
      value: "",
      label: t("10206"),
    },
    {
      id: "type",
      value: "",
      defaultValue: "",
      isSelect: true,
      selectOption: DeviceUtils.allDeviceType(),
      label: t("10139")
    }
  ])

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.modelId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 4
  }



  // Contenuto Celle
  function AdminTableCells(props) {
    const { CellButtonEdit, CellButtonDelete, CellButtonClone, keyToUse, labelId, row } = props
    const key = keyToUse
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {row.modelId ? row.modelId : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.description ? row.description : ""}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {UserUtils.formatBrand(row)}
        </TableCell><TableCell key={"tableBodyCell3-" + key} align="left">
          {row.type ? row.type : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonClone keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow >
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.modelId,
            filter.filter((filter) => {
              return filter.id === "modelId"
            })[0].value
          )
        })
        .filter((row) => {
          return filterFieldBrand(
            row,
            UserUtils.formatBrand(row),
            filter.filter((filter) => {
              return filter.id === "brand"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.description,
            filter.filter((filter) => {
              return filter.id === "name"
            })[0].value
          )
        })
        .filter((row) => {
          return filterFieldType(
            row,
            row,
            filter.filter((filter) => {
              return filter.id === "type"
            })[0].value
          )
        })
      : rows
  }

  //Filtro per select customizzata del tipo tracciatore
  function filterFieldType(row, rowField, filterField) {
    if (filterField !== null && filterField !== undefined) {
      filterField = String.prototype.trim.call(filterField)
      if (filterField !== "") {
        if (rowField !== null && rowField !== undefined) {
          rowField = String.prototype.trim.call(rowField)
          if (filterField !== "") {
            if (filterField.toUpperCase() === "BLOCKER" && row.type.toUpperCase() === "BLOCKER") {
              return row
            }
            else if (filterField.toUpperCase() === "EDGER" && row.type.toUpperCase() === "EDGER") {
              return row
            }
            else if (filterField.toUpperCase() === "TRACER" && row.type.toUpperCase() === "TRACER") {
              return row
            }
            else if (filterField.toUpperCase() === "SOFTWARE" && row.type.toUpperCase() === "SOFTWARE") {
              return row
            }
          } else {
            return row
          }
        } else {
          // Elemento da scartare
        }
      } else {
        return row
      }
    } else {
      return row
    }
  }

  //Filtro per select customizzata del brand
  function filterFieldBrand(row, rowField, filterField) {
    if (filterField !== null && filterField !== undefined) {
      filterField = String.prototype.trim.call(filterField)
      if (filterField !== "") {
        if (rowField !== null && rowField !== undefined) {
          rowField = String.prototype.trim.call(rowField)
          if (filterField !== "") {
            if (
              filterField === String.prototype.trim.call(row.brand)
            ) {
              return row
            }
          } else {
            return row
          }
        } else {
          // Elemento da scartare
        }
      } else {
        return row
      }
    } else {
      return row
    }
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch -  Elenco devices
  const fetchBrands = useCallback(() => {
    //setIsFetching(true)
    const url = `${apiUrl}/brands`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ brands }) => {

        var optionBrands = brands
        var newFilter = filter
        setAllBrands(brands)
        optionBrands = optionBrands.map(option => {
          return {
            label: option.brandId + " - " + option.brand,
            value: option.brandId
          }
        }
        )
        optionBrands.push(
          {
            label: "",
            value: 0
          })
        optionBrands = optionBrands.sort(function (a, b) {
          return (parseInt(a.value) > parseInt(b.value) ? 1 : -1)
        })
        newFilter = newFilter.map(option => {
          return option.id === "brand" ? ({
            id: "brand",
            value: "",
            defaultValue: "",
            isSelect: true,
            selectOption: optionBrands,
            label: t("10138")
          }) : option
        })
        setFilter(newFilter)
        setSelectBrands(optionBrands)
        setIsFetching(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchBrands  Error", err)
        setIsFetching(false)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, t, filter])

  //Fetch -  Dati Device selezionato
  const fetchModel = ((modelId, rows, setData, setActionFetch) => {
    let appDevice
    appDevice = rows.filter(function (elem) {
      return elem.modelId === modelId
    })[0]
    setData(appDevice)
    setActionFetch(false)
  }
  )

  //Fetch -  Dati Device da clonare
  const cloneModel = ((modelId, rows, setData, setActionFetch) => {
    let appDevice
    appDevice = rows.filter(function (elem) {
      return elem.modelId === modelId
    })[0]
    //appDevice.modelId = rows.length+1
    setData(appDevice)
    setActionFetch(false)
  }
  )


  function exportPDF(rows, order, orderBy) {
    const unit = "pt";

    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = t("10218").toUpperCase()
    const headers = [[
      t("10136").toUpperCase(),  //Id modello
      t("10138").toUpperCase(),  //Marca
      t("10046").toUpperCase(),  //Nome dispositivo
      t("10205").toUpperCase(),  //Tipo modello
    ]]

    let tableFiltered = FilterAdminTableCells(rows)

    tableFiltered = stableSort(tableFiltered, getComparator(order, orderBy))

    const data = tableFiltered.map(elt =>
      [elt.modelId, elt.brandView, elt.description, elt.type]
    )
    let content = {
      startY: 50,
      head: headers,
      body: data
    }

    doc.text(title, marginLeft, 30)
    doc.setFontSize(10)
    doc.autoTable(content)
    doc.save(title + ".pdf")
  }


  /*************************************
    *
    * UseEffect
    *
    *************************************/
  useEffect(() => {
    if (isFetching) {
      fetchBrands()
    }
  }, [fetchBrands, isFetching])

  return (
    <div>
      <AdminTable
        AddEditElement={Model}
        AdminTableCells={AdminTableCells}
        allBrandsDevices={allBrands}
        createData={createData}
        defaultRowsPerPage={25}
        downloadData={true}
        exportPDF={exportPDF}
        filter={filter}
        filterToolbarExpaned={false}
        FilterAdminTableCells={FilterAdminTableCells}
        handleClickOpenAction={fetchModel}
        handleClickCloneAction={cloneModel}
        headCells={headCells}
        isFullTable={true}
        getAdminTableKey={getAdminTableKey}
        getAdminTableCellsCount={getAdminTableCellsCount}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        urlDelete={urlDelete}
        urlGet={urlGet}
        useCompany={false}
      />
    </div>
  )
}
