//Pannello per la scelta del device
import React, { useContext, useEffect, } from "react"
import { RootContext } from "../../RootContext"

//core components
import Autocomplete from '@material-ui/lab/Autocomplete';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useStylesText } from "styles/adminTable/adminTableEditScreenCss"
import { useAutocompleteStyles } from "styles/autocomplete/autocompleteCss"

//Componenti Pegaso
import DeviceUtils from "components/Utils/DeviceUtils"

export default function DownloadOption(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    downloadLabel,
    downloadOption,
    setChangeDevice,
    setOpenPanelOption,
    setDownloadLabel,
    setDownloadOption
  } = props

  const classesText = useStylesText()
  const classesAutocomplete = useAutocompleteStyles()
  const omaLabels = DeviceUtils.omaLabels
  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    if (changeDevice) {
      setOpenPanelOption(false)
      setDownloadLabel(downloadOption.omaLabels)
      //setDownloadOption(downloadOption)
      setChangeDevice(false)
    }
  }, [changeDevice, downloadOption.omaLabels, setChangeDevice, setOpenPanelOption, setDownloadLabel, setDownloadOption])


  //Cambia field
  const handleChange = (name) => (event) => {
    let value = event.target.value.trim()
    value = (value.length === 0 ? 0 : parseInt(value))
    setDownloadOption({ ...downloadOption, [name]: value })
  }
 
  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"reqDownload"}
            label={t("10026")}
            className={classesText.textField}
            value={downloadOption.req}
            margin="normal"
            onChange={handleChange("req")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtOptbinDownload"}
            label={t("10027")}
            className={classesText.textField}
            value={downloadOption.trcfmtbin}
            margin="normal"
            onChange={handleChange("trcfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtpointsDownload"}
            label={t("10028")}
            className={classesText.textField}
            value={downloadOption.trcfmtpoints}
            margin="normal"
            onChange={handleChange("trcfmtpoints")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtbinDownload"}
            label={t("10029")}
            className={classesText.textField}
            value={downloadOption.zfmtbin}
            margin="normal"
            onChange={handleChange("zfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtpointsDownload"}
            label={t("10030")}
            className={classesText.textField}
            value={downloadOption.zfmtpoints}
            margin="normal"
            onChange={handleChange("zfmtpoints")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="omaLabels"
            classes={classesAutocomplete}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            multiple
            onChange={(event, newValue) => {
              setDownloadLabel(newValue)
              setDownloadOption({
                ...downloadOption, omaLabels:
                  newValue
              });
            }}
            options={omaLabels}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="omaLabels" placeholder={t("10031")} />
            )}
            style={{ width: 500 }}
            value={downloadLabel}
          />
        </Grid>
      </Grid>
    </div>
  )
}