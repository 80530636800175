import React, { useState, useEffect, useContext, useCallback, useRef } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTableBody from "components/AdminTable/AdminTableBody"
import AdminTableFooter from "components/AdminTable/AdminTableFooter"
import AdminTableHead from "components/AdminTable/AdminTableHead"
import AdminTableToolbar from "components/AdminTable/AdminTableToolbar"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"
import HubUtils from "components/Utils/HubUtils"
import ScopeUtils from "components/Utils/ScopeUtils";

//core components
import AccessTime from "@material-ui/icons/AccessTime"
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AddIcon from "@material-ui/icons/Add"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import RefreshIcon from "@material-ui/icons/Refresh"
import Table from "@material-ui/core/Table"
import TableContainer from "@material-ui/core/TableContainer"
import TimeAgo from "react-timeago"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

// CSS
import { useAdminTableStyles } from "styles/adminTable/adminTableCss"

export default function AdminTable(props) {
  const {
    AddEditElement,
    changeCompany,
    defaultRowsPerPage,
    deleteRow,
    disableAdd,
    disableOpen,
    disableDelete,
    downloadData,
    exportPDF,
    filter,
    filterToolbarExpaned,
    handleClickOpenAction,
    handleClickCloneAction,
    headCells,
    isFullTable,
    isCustomFilter,
    isHub,
    orderByDefault,
    notFullTableData,
    remapDeleteRow,
    remapGetRows,
    setChangeCompany,
    urlGet,
    urlDelete,
    urlAccessCode,
    useCompany,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)
  const classes = useAdminTableStyles()
  const abortController = useRef (null)
  const [actionFetch, setActionFetch] = useState(false)
  const [adminTableRowData, setAdminTableRowData] = useState("")
  const [adminTableRows, setAdminTableRows] = useState(
    isFullTable ? [] : notFullTableData ? (
      remapGetRows ? remapGetRows(notFullTableData) : notFullTableData) : (
      ([])
    )
  )
  const [adminTableRowsCount, setAdminTableRowsCount] = useState(0)
  const [creation, setCreation] = useState(false)
  const [fetching, setFetching] = useState({
    cancel: false,
    isFetching: false,
    error: false,
  })
  const [loading, setLoading] = useState(isFullTable ? true : false)
  const [openAdminTableRow, setOpenAdminTableRow] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState(orderByDefault)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [rowToDelete, setRowToDelete] = useState()
  const [updateAgo, setUpdateAgo] = useState()

  /*************************************
   *
   * Fetch
   *
   *************************************/

  const fetchGetRows = useCallback(() => {
    if (fetching.isFetching === false) {
      abortController.current = new AbortController()
      setFetching({ ...fetching, isFetching: true })
      setPage(0)
      fetchWithToken(urlGet, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          // Se le righe nella tabella esistono -> riempio la tabella
          setLoading(false)
          setAdminTableRows(remapGetRows ? remapGetRows(data) : data)
          setUpdateAgo(Date.now())
        })
        .catch((err) => {
          if(err.message.includes("abort") < 0 ){
            setLoading(false)
          }
          // Se le righe nella tabella non esistono
          ErrorUtils.errorLog(`${urlGet} => FetchGetRows  Error`, err.message)
        })
    }
  }, [
    access_token,
    apiUrl,
    fetching,
    refresh_token,
    remapGetRows,
    setAccess_token,
    setAdminTableRows,
    setUpdateAgo,
    urlAccessCode,
    urlGet,
    
  ])



  //Fetch  - DELETE riga tabella
  function fetchDeleteRow() {
    var hubDelete = adminTableRows.filter((option) => option.hubId === rowToDelete)[0]
    setActionFetch(true)
    fetchWithToken(urlDelete + rowToDelete, {
      method: "DELETE",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se riga esiste
        if (isHub) {
          if (hubDelete.hubScopes) {
            var scopes = ScopeUtils.createJsonScopes(hubDelete.hubScopes)
            scopes.map((option) => option.hubSettings.topicId !== undefined ? HubUtils.deleteAwsQueue(urlAccessCode, rowToDelete, option.hubSettings.topicId) : null)
          }
        }
        setAdminTableRows(
          remapDeleteRow
            ? remapDeleteRow(adminTableRows, rowToDelete)
            : adminTableRows
        )
        setActionFetch(false)
        setOpenConfirmDelete(false)
      })
      .catch((err) => {
        // Se riga non esiste
        setActionFetch(false)
        ErrorUtils.errorLog("fetchDeleteRow Error", err)
      })
  }

  function deleteRowNotFullTable() {
    deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete)
    setOpenConfirmDelete(false)
  }


  /*************************************
   *
   * UseEffect
   *
   *************************************/

  useEffect(() => {
    // Se tabella ha bisogno di COMPANY, quando cambia COMPANY fà di nuovo la FETCH GET
    if (useCompany) {
      if (changeCompany) {
        setLoading(true)
        if(fetching.isFetching){
          abortController.current.abort()
          setAdminTableRows([])
        }
        setChangeCompany(false)
        setFetching({ ...fetching, isFetching: false })
      }
    }
    // FETCH GET
    if (!fetching.isFetching && isFullTable) {
      fetchGetRows()
    }
  }, [fetching, fetchGetRows, changeCompany, setChangeCompany, useCompany, isFullTable])

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  // Cancels Fecth if Filter changes
  function handleCancelFetch() {
    setFetching({ ...fetching, cancel: true })
  }

  // Click when change data con Filter
  function handleChangeFilter(filterField, targetValue) {
    filter.map((option) =>
      (option.id === filterField) ? (option.value = targetValue) : (null)
    )
    setPage(0)
  }

  // Click sort by coloumn
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  // Click Add Row
  function handleClickAdd(row) {
    setCreation(true)
    setAdminTableRowData("")
    setOpenAdminTableRow(true)
  }

  // Click Edit Row
  function handleClickEdit(row) {
    if (isFullTable) {
      setActionFetch(true)
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData, setActionFetch)
      }
      setOpenAdminTableRow(true)
    }
    else {
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData)
      }
      setOpenAdminTableRow(true)
    }
  }

  // Click clone Row
  function handleClickClone(row) {
    setCreation(true)
    if (isFullTable) {
      setActionFetch(true)
      if (handleClickCloneAction) {
        handleClickCloneAction(row, adminTableRows, setAdminTableRowData, setActionFetch)
      }
      setOpenAdminTableRow(true)
    }
    else {
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData)
      }
      setOpenAdminTableRow(true)
    }
  }

  // Click Apri finestra DELETE Row
  function handleClickDelete(row) {
    setOpenConfirmDelete(true)
    setRowToDelete(row)
  }

  // Click Chiudi finestra DELETE Row
  function handleCloseConfirmDelete() {
    setOpenConfirmDelete(false)
  }

  // Click Close Add/Edit
  function handleClickClose() {
    setAdminTableRowData("")
    setOpenAdminTableRow(false)
    setCreation(false)
  }

  // Click refresh
  function handleClickRefresh() {
    setLoading(true)
    setAdminTableRows([])
    setFetching({ ...fetching, isFetching: false })
  }

  // Click change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Click rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function handleClickDownload() {
    exportPDF(adminTableRows, order, orderBy)
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, adminTableRows.length - page * rowsPerPage)


  return (
    <div>
      {isFullTable ? (
        isCustomFilter ? (null) : (
          <Accordion defaultExpanded={filterToolbarExpaned}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{t("10000")}</Typography>
            </AccordionSummary>
            {
              changeCompany ? (null) :
                (
                  <AccordionDetails>
                    <Card>
                      <CardHeader color="primary">
                        <AdminTableToolbar
                          filter={filter}
                          handleCancelFetch={handleCancelFetch}
                          handleChangeFilter={(filterField, targetValue) => {
                            handleChangeFilter(filterField, targetValue)
                          }}
                        />
                      </CardHeader>
                    </Card>
                  </AccordionDetails>
                )
            }
          </Accordion>
        )
      ) : (
        null
      )
      }

      <Card>
        <CardBody>
          <div className={classes.tableWrapper}>
            <Paper className={classes.paper}>
              <Grid container direction="row-reverse" justify="space-between">
                {/* TASTO NUOVO */}
                <Grid item xs={1}>
                  {" "}
                  <Fab
                    color="primary"
                    onClick={handleClickAdd}
                    aria-label="add"
                    disabled={loading || disableAdd ? true : false}
                    align="right"
                  >
                    <Tooltip title={t("10001")}>
                      <AddIcon />
                    </Tooltip>
                  </Fab>
                </Grid>
                {/* TASTO REFRESH*/}
                {isFullTable ?
                  (<Grid item xs={1}>
                    <Tooltip title={t("10002")}>
                      <IconButton
                        onClick={handleClickRefresh}
                        aria-label="refresh"
                        align="left"
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  ) : (
                    null
                  )
                }
                {/*TASTO DOWNLOAD*/}
                {
                  downloadData ? (
                    <Grid item xs={1}>
                      {" "}
                      <Fab
                        color="primary"
                        onClick={handleClickDownload}
                        aria-label="add"
                        disabled={adminTableRows.length > 0 ? false : true}
                        align="right"
                      >
                        <Tooltip title={t("10280")}>
                          <CloudDownloadIcon />
                        </Tooltip>
                      </Fab>
                    </Grid>
                  ) : (
                    null
                  )
                }
                <Grid item xs={9}></Grid>
              </Grid>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                >
                  <AdminTableHead
                    classes={classes}
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <AdminTableBody
                    {...props}
                    classes={classes}
                    emptyRows={emptyRows}
                    handleClickOpen={handleClickEdit}
                    handleClickDelete={handleClickDelete}
                    handleClickClone={handleClickClone}
                    //handleClickImage = {handleClickImage}
                    loading={loading}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rows={adminTableRows}
                    rowsPerPage={rowsPerPage}
                    setRows={setAdminTableRows}
                    setRowsCount={setAdminTableRowsCount}
                    t={t}
                  />
                </Table>
                {/* DIALOG - OPEN ADD/EDIT ROW */}
                {!disableOpen ?
                  <Dialog
                    onClose={handleClickClose}
                    aria-labelledby="customized-dialog-title"
                    open={openAdminTableRow}
                    maxWidth="xl"
                  >
                    <DialogContent>
                      <AddEditElement
                        {...props}
                        creation={creation}
                        adminTableRowData={adminTableRowData}
                        adminTableRows={adminTableRows}
                        handleClickClose={handleClickClose}
                        handleClickRefresh={handleClickRefresh}
                        setAdminTableRows={setAdminTableRows}
                      />
                    </DialogContent>
                  </Dialog> : null
                }
                {/* DIALOG - OPEN DELETE ROW */}
                {!disableDelete ?
                  <Dialog
                    open={openConfirmDelete}
                    onClose={handleCloseConfirmDelete}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {t("10003")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {t("10170")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseConfirmDelete} color="primary">
                        {t("10005")}
                      </Button>
                      <Button onClick={isFullTable ? fetchDeleteRow : deleteRowNotFullTable} color="primary">
                        {t("10006")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  : null
                }
              </TableContainer>
              {/* DIALOG LOADING */}
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={actionFetch}
                maxWidth="xl"
              >
                <DialogContent>
                  <CircularProgress size={42} style={{ color: "primary" }} />
                </DialogContent>
              </Dialog>

              <AdminTableFooter
                count={adminTableRowsCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Paper>
          </div>
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            {updateAgo && <AccessTime />}
            {updateAgo && t("10199") + " "}
            {updateAgo && <TimeAgo date={updateAgo} />}
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}
