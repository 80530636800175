import React, { useContext } from "react"

//pegaso/views/components
import { RootContext } from "../../RootContext"

//core components
import FilterSelect from "components/Filter/FilterSelect"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

// CSS
import {
  useAdminTableToolbarSelectStyles,
  useAdminTableToolbarStyles,
} from "styles/adminTable/adminTableToolbarCss"


// Toolbar -> FILTERS
function OpticianStatisticToolbarFilters(props) {
  const { handleCancelFetch, handleChangeFilter,filter } = props
  const { t } = useContext(RootContext)
  const classesSelect = useAdminTableToolbarSelectStyles()
  const selectYear = [
    {
      value: "",
      label: "",
    },
    {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
    {
      value: new Date().getFullYear() - 1,
      label: new Date().getFullYear() - 1,
    },
    {
      value: new Date().getFullYear() - 2,
      label: new Date().getFullYear() - 2,
    },
    {
      value: new Date().getFullYear() - 3,
      label: new Date().getFullYear() - 3,
    },
    {
      value: new Date().getFullYear() - 4,
      label: new Date().getFullYear() - 4,
    },
  ]

  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justify="flex-start"
        alignItems="center"
      >

        <Grid item xs={3} key={selectYear.id}>
          <FilterSelect
            classes={classesSelect}
            id="year"
            label={t("Seleziona anno")}
            defaultValue={filter.year}
            handleClick1={handleCancelFetch}
            handleChange1={handleChangeFilter}
            options={selectYear}
            filterField={"year"}
          />
        </Grid>

      </Grid>

    </div>
  )
}

export default function OpticianStatisticToolbar(props) {
  const classes = useAdminTableToolbarStyles()
  const { handleCancelFetch, handleChangeFilter, filter } = props

  return (
    <div>
      <Paper className={classes.paper}>
        <OpticianStatisticToolbarFilters
          className={classes.root}
          handleCancelFetch={handleCancelFetch}
          handleChangeFilter={handleChangeFilter}
          filter={filter}
        />
      </Paper>
    </div>
  )
}
