import React, { useContext, useState, useEffect, useCallback } from "react"
import { RootContext } from "../../RootContext"

//Material UI
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Grid from "@material-ui/core/Grid"

//Componenti Pegaso
import { makeStyles } from "@material-ui/core/styles"
import {
  AdminTableElevationScroll,
} from "components/AdminTable/AdminTableEditScreen"
import { CircularIndeterminate } from "../Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))


export default function EltimaLicense(props) {
  const {
    adminTableRowData,
    handleClickClose,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const classes = useStyles()

  const [eltimaKeys, setEltimaKeys] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const licenseId = adminTableRowData.id

  //GET advanceds option.
  const fetchHubs = useCallback(() => {
    setIsFetching(true)
    const url = `${apiUrl}/hubs/license/${licenseId}/scope/4`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ scopes }) => {
        var eltimaKeys = scopes
          .map((option) => {
            if (option.hubSettings.supplier.eltimaLicense !== undefined) {
              return {
                eltimaLicense: option.hubSettings.supplier.eltimaLicense
              };
            }
            return null; // Restituisci null se la condizione non è soddisfatta
          }).filter(item => item !== null);
        setEltimaKeys(eltimaKeys)
        setIsFetching(false)
      })
      .catch((err) => {
        setIsFetching(false)
      })
  }, [access_token, apiUrl, refresh_token, setAccess_token, licenseId])

  useEffect(() => {
    fetchHubs()
  }, [
    fetchHubs
  ])
  return (isFetching ?

    <CircularIndeterminate />
    : (
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <b>
            <p align="center">
              <u>{t("10291").toUpperCase()} </u>
            </p>
          </b>
          <Grid container spacing={2}>
            <Grid
              className={classes.gridCurrentValues}
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              {
                eltimaKeys.length > 0 ? (
                  eltimaKeys.map((option) => (
                    <Grid item xs={12} key={option.eltimaLicense}>
                      {option.eltimaLicense}
                    </Grid>
                  ))) : (
                  <p align="center">
                    <u>{t("10292").toUpperCase()} </u>
                  </p>
                )
              }
            </Grid>
          </Grid>

        </CardBody>
      </Card>
    )
  )
}