import React from "react"

export default class StringUtils {
  static stringFromUTF8Array(data) {
    const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0]
    var count = data.length
    var str = ""

    for (var index = 0; index < count; ) {
      var ch = data[index++]
      if (ch & 0x80) {
        var extra = extraByteMap[(ch >> 3) & 0x07]
        if (!(ch & 0x40) || !extra || index + extra > count) return null

        ch = ch & (0x3f >> extra)
        for (; extra > 0; extra -= 1) {
          var chx = data[index++]
          if ((chx & 0xc0) !== 0x80) return null

          ch = (ch << 6) | (chx & 0x3f)
        }
      }

      str += String.fromCharCode(ch)
    }

    return str
  }

  // Check if element is not undefined or null
  static checkIfNotNull(field) {
    return field === undefined ? "" : field === null ? "" : field
  }

  // Decodes Stream to Jspon
  static decodeJsonStreamingChunk(value) {
    const byteString = StringUtils.stringFromUTF8Array(value)
    const preJSON = "[" + byteString.replace(/}{/g, "},{") + "]"
    try {
      return JSON.parse(preJSON)
    } catch (error) {
      console.error("SyntaxError: JSON.parse")
      return {}
    }
  }

  // Decodes Stream to Jspon
  static decodeJsonStreamingChunkFromString(value) {
    const byteString = value
    const preJSON = "[" + byteString.replace(/}{/g, "},{") + "]"
    try {
      return JSON.parse(preJSON)
    } catch (error) {
      console.error("SyntaxError: JSON.parse")
      return {}
    }
  }

  // Generates newlines in string for html
  static generateNewLines(text) {
    let result = text.split("\n").map((item, i) => {
      return <span key={i}>{item}<br/></span>
    })
    return result
  }
}
