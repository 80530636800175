import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//core components
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { useAutocompleteStyles } from "styles/autocomplete/autocompleteCss"


//pegaso/views/components
import ScopeUtils from "components/Utils/ScopeUtils";
import DeviceUtils from "components/Utils/DeviceUtils"

export default function ImportJobsFromExternalSystem(props) {

  const {
    adminTableRows,
    creation,
    handleClickClose,
    scopesJSON,
    userScope,
    selectedScopeData,
    setAdminTableRows,
    setScopesJSON,
  } = props

  const {
    t,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()
  const classesAutocomplete = useAutocompleteStyles()


  //Varibili per lo scope
  const [active, setActive] = useState((creation || !userScope) ? true : userScope.active)
  const [filter, setFilter] = useState(((creation || !userScope) ? "" : userScope.hubSettings.filter))
  const [daysBeforeDelete, setDaysBeforeDelete] = useState(daysBeforeDeleteSetter(userScope, creation))
  const [internalBufferSize, setInternalBufferSize] = useState(((creation || !userScope) ? "" : userScope.hubSettings.internalBufferSize))
  const [includeSubdirectories, setIncludeSubdirectories] = useState(((creation || !userScope) ? true : userScope.hubSettings.includeSubdirectories))
  const hubScopeId = ((creation || !userScope) ? ScopeUtils.countScopeId(adminTableRows) : userScope.hubScopeId)
  const omaLabels = DeviceUtils.omaLabels
  const [password, setPassword] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.password))
  const [path, setPath] = useState(((creation || !userScope) ? "" : userScope.hubSettings.file.path))
  const [scopeFull, setScopeFull] = useState(((creation || !userScope) ? undefined : userScope.scopeFull))
  const scopesType = selectedScopeData.scopes
  const [system, setSystem] = useState(((creation || !userScope) ? "" : userScope.scopeId))
  const [username, setUsername] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.username))
  const [uploadLabels, setUploadLabels] = useState(((creation || !userScope) ? [] : userScope.hubSettings.file.upload.omaLabels))

  const systemValue = scopesType.map((option) => {
    return {
      value: option.defaultSettings.system,
      scopeId: option.scopeId
    }
  })

  //Setta i giorni prima della cancellazione dei file da hub
  function daysBeforeDeleteSetter(userScope, creation) {
    if (creation || !userScope || !userScope.hubSettings.file.hasOwnProperty('daysBeforeDelete')) {
      return 182
    }
    if (userScope.hubSettings.file.hasOwnProperty('daysBeforeDelete')) {
      return userScope.hubSettings.file.daysBeforeDelete
    }
  }

  //Attiva il device
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
  }

  //Attiva IncludeSubdirectories
  const handleChangeIncludeSubdirectories = (event) => {
    let value = event.target.checked
    setIncludeSubdirectories(value)
  }

  //Setta i valori del sistema selezionato
  const handleChangeSystem = (event) => {
    let value, selected
    value = event.target.value
    setSystem(value)
    selected = scopesType.filter((option) => {
      return value === option.scopeId
    })[0]
    setFilter(selected.defaultSettings.filter)
    setIncludeSubdirectories(selected.defaultSettings.includeSubdirectories)
    setInternalBufferSize(selected.defaultSettings.internalBufferSize)
    setPassword(selected.defaultSettings.file.password)
    setPath(selected.defaultSettings.file.path)
    setScopeFull({
      defaultSettings: selected.defaultSettings,
      scopeType: {
        description: creation ? selectedScopeData.description : userScope.scopeFull.scopeType.description,
        scopeTypeId: creation ? selectedScopeData.scopeTypeId : userScope.scopeFull.scopeType.scopeTypeId,
      }
    })
    setUploadLabels(selected.defaultSettings.file.upload.omaLabels)
    setUsername(selected.defaultSettings.file.username)
    setDaysBeforeDelete(selected.defaultSettings.file.daysBeforeDelete)
  }

  //Setta la path
  const handleChangePath = (event) => {
    setPath(event.target.value)
  }

  //Setta i filter
  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
  }

  //Setta internalBufferSize
  const handleChangeInternalBufferSize = (event) => {
    setInternalBufferSize(parseInt(event.target.value))
  }

  //Setta username path
  const handleChangeUsername = (event) => {
    setUsername(event.target.value)
  }

  //Setta username path
  const handleChangePassword = (event) => {
    setPassword(event.target.value)
  }

  //Setta daysbeforedelete 
  const handleChangeDaysbeforedelete = (event) => {
    setDaysBeforeDelete(parseInt(event.target.value))
  }

  //Salva lo scope
  const handleSave = () => {
    let selectedSystemData = scopesType.filter((option) => {
      return system === option.scopeId
    })[0]
    let value
    value = {
      hubScopeId: hubScopeId,
      scopeId: system,
      active: active,
      hubSettings: {
        system: selectedSystemData.defaultSettings.system,
        filter: filter,
        includeSubdirectories: includeSubdirectories,
        internalBufferSize: internalBufferSize,
        file: {
          brand: selectedSystemData.defaultSettings.file.brand,
          model: selectedSystemData.defaultSettings.file.model,
          upload: {
            omaLabels: uploadLabels
          },
          path: path,
          password: password !== undefined ? password : "",
          username: username !== undefined ? username : "",
          daysBeforeDelete: daysBeforeDelete !== undefined ? daysBeforeDelete : 182
        }
      },
      scopeFull: scopeFull
    }
    ScopeUtils.saveScopeJSON(scopesJSON, setScopesJSON, creation, value, hubScopeId)
    let newAdminTableRows = creation
      ? adminTableRows.concat(value)
      : adminTableRows.map((elem) => {
        if (elem.hubScopeId === userScope.hubScopeId) {
          return value
        } else {
          return elem
        }
      })
    setAdminTableRows(newAdminTableRows)
    handleClickClose()
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result = system === ""
    return result
  }


  return (
    <Grid className={classes.gridCurrentValues} item xs={12}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10068").toUpperCase()}</u>
          </p>
        </Grid>

        {/*RIGA 1 */}

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={handleChangeActive}
                name="active"
              />
            }
            label={t("10047")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            id="system"
            disabled={!creation}
            label={t("10069")}
            value={system}
            onChange={handleChangeSystem}
            helperText={t("10070")}
          >
            {systemValue.map((option) => (
              <MenuItem key={option.scopeId} value={option.scopeId}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"path"}
            label={t("10071")}
            className={classesText.textField}
            value={path || ""}
            margin="normal"
            onChange={handleChangePath}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"filter"}
            label={t("10000")}
            className={classesText.textField}
            value={filter || ""}
            margin="normal"
            onChange={handleChangeFilter}
          />
        </Grid>

        {/*RIGA 2*/}

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeSubdirectories}
                onChange={handleChangeIncludeSubdirectories}
                name="includeSubdirectories"
              />
            }
            label={t("10075")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"internalBufferSize"}
            label={t("10076")}
            className={classesText.textField}
            value={internalBufferSize || ""}
            margin="normal"
            onChange={handleChangeInternalBufferSize}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"username"}
            label={t("10062")}
            className={classesText.textField}
            value={username || ""}
            margin="normal"
            onChange={handleChangeUsername}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"password"}
            label={t("10063")}
            className={classesText.textField}
            value={password || ""}
            margin="normal"
            onChange={handleChangePassword}
          />
        </Grid>

        {/*RIGA 3*/}

        <Grid item xs={3}>
          <TextField
            id={"daysBeforeDelete"}
            label={t("10283")}
            className={classesText.textField}
            value={daysBeforeDelete || ""}
            margin="normal"
            onChange={handleChangeDaysbeforedelete}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            classes={classesAutocomplete}
            multiple
            id="omaLabels"
            options={omaLabels}
            getOptionLabel={(option) => option}
            value={uploadLabels}
            onChange={(event, newValue) => {
              setUploadLabels(newValue)

            }}
            disableCloseOnSelect
            /*renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Multiple values"
                placeholder="Favorites"
              />
            )}*/
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="omaLabels" placeholder={t("10031")} />
            )}
          />
        </Grid>

      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        p={1}
        className={classes.gridToolbar}
      >
        <Box p={1}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={disableSave()}
            onClick={handleSave}
          >
            {t("10010")}
          </Button>
        </Box>
      </Box>


    </Grid>
  )
}