import React, { useContext, useState } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import JobRoutingToSuppliers from "views/JobRoutingToSuppliers/JobRoutingToSuppliers"

import IconButton from "@material-ui/core/IconButton"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import VisibilityIcon from "@material-ui/icons/Visibility"

// Nomi dei campi delle colonne
function createData(
  supplierCode,
  companyCode,
  clientId,
  serialNumber

) {
  return {
    supplierCode,
    companyCode,
    clientId,
    serialNumber
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  let value = rows.labScopes.filter((option) => option.scopeId === 8)[0]
  if (value) {
    return value.labSettings.routings
  }
  else return []

}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.hubScopeId !== rowToDelete
  })
}

// Click View Password
const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
  setRows(
    rows.map((elem) => {
      let newElem = elem
      if (elem === rowToViewPassword) {
        newElem.viewSerialNumber = !newElem.viewSerialNumber
      }
      return newElem
    })
  )
}


export default function JobRoutingToSuppliersTable(props) {
  const {
    allScopes,
    lab,
    urlAccessCode,
    setLab
  } = props

  const {
    t,
  } = useContext(RootContext)


  const [filter] = useState([
    {
      id: "supplierCode",
      value: ""
    },
    {
      id: "supplier",
      value: ""
    },
    {
      id: "companyCode",
      value: ""
    },
    {
      id: "clientId",
      value: ""
    },
    {
      id: "serialNumber",
      value: ""
    },
  ])

  const headCells = [
    {
      id: "supplierCode",
      numeric: false,
      disablePadding: false,
      label: t("10153"),
    },
    {
      id: "supplier",
      numeric: false,
      disablePadding: false,
      label: t("10151"),
    },
    {
      id: "companyCode",
      numeric: false,
      disablePadding: false,
      label: t("10154"),
    },
    {
      id: "clientId",
      numeric: false,
      disablePadding: false,
      label: t("10148")
    },
    {
      id: "serialNumber",
      numeric: false,
      disablePadding: false,
      label: t("10152")
    },
  ]
  const orderByDefault = "supplierCode"

  /*************************************
  *
  * Cells
  *
  *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.supplierCode
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 3
  }


  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.supplier}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {row.companyCode}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.clientId}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.serialNumber
            ? row.viewSerialNumber
              ? row.serialNumber
              : "*".repeat(row.serialNumber.length)
            : ""}
          <Tooltip
            title={row.viewSerialNumber ? t("10112") : t("10113")}
          >
            <IconButton
              aria-label={
                row.viewPassword ? t("10112") : t("10113")
              }
              variant="outlined"
              onClick={() => handleClickViewPassword(rows, setRows, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.supplierCode,
            filter.filter((filter) => { return filter.id === "supplierCode" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.supplierCode,
            filter.filter((filter) => { return filter.id === "supplier" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.companyCode,
            filter.filter((filter) => { return filter.id === "companyCode" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.clientId,
            filter.filter((filter) => { return filter.id === "clientId" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.clientId,
            filter.filter((filter) => { return filter.id === "serialNumber" })[0].value
          )
        })
      : rows
  }

  //Cancella la riga dalla tabella
  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    let newAdminTableRows = adminTableRows.filter((option) => { return option.supplierCode !== rowToDelete })
    setAdminTableRows(newAdminTableRows)
    setLab({
      ...lab, labScopes: [{
        scopeId: 8,
        active: true,
        labSettings: { routings: newAdminTableRows }
      }]
    })
  }

  const getRowData = (
    (hubScopeId, rows, setData) => {
      let scopeToEdit
      scopeToEdit = rows.filter(function (elem) {
        return elem.supplierCode === hubScopeId
      })[0]
      setData(scopeToEdit)
    }
  )



  return (
    <div>
      <AdminTable
        AddEditElement={JobRoutingToSuppliers}
        AdminTableCells={AdminTableCells}
        allScopes={allScopes}
        createData={createData}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getRowData}
        headCells={headCells}
        notFullTableData={lab !== undefined ? lab : []}
        isFullTable={false}
        isDevice={false}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        useCompany={true}
        urlAccessCode={urlAccessCode}
        setScopesJSON={setLab}
      />
    </div>
  )
}
