import React from "react"

// @material-ui/core components
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Favicon from "react-favicon"
import Paper from "@material-ui/core/Paper"
// Pegaso components
import { useLoginStyles } from "styles/loginCss"
import LoginFooter from "components/Footer/LoginFooter"



export default (props) => {
  const classes = useLoginStyles()
  const companyFooter = {
    site: "",
    email: "",
    telephoneNumber: "",
    mobilePhone: "",
  }
  var language = window.navigator.userLanguage || window.navigator.language

  return (
    <div>
      <Favicon url={'favicon.ico'} />
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <CardMedia component="img" image={'/resources/images/administrator.png'} />
          {
            language === 'it-IT' || language === 'it' ? (
              <div>
                <center>
                  <h3 style={{color: "red"}}>SERVER IN MANUTENZIONE</h3>
                </center>
                <center>
                  <h5>Ci scusiamo per la momentanea interruzione del servizio.</h5>
                </center>
                <center>
                  <h6>Si prega di riprovare più tardi.</h6>
                </center>
              </div>
            ) : (
              <div>
                <center>
                  <h3  style={{color: "red"}}>SERVER UNDER MAINTENANCE</h3>
                </center>
                <center>
                  <h5>We apologize for the temporary interruption of the service.</h5>
                </center>
                <center>
                  <h6>Please try again later.</h6>
                </center>
              </div>
             
            )
          }
        </Paper>

        {<LoginFooter
          companyFooter={companyFooter}
        />}
      </main>
    </div>
  )
}
