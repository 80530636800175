import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { CircularIndeterminate } from "views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import FilterDevice from "components/Filter/FilterDevice/"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

const languageList = UserUtils.languageList

const UTCList = UserUtils.UTCList()

function searchDemoOptician(adminTableRows) {
  let app = adminTableRows.filter(option => { return option.username === "demoOptician" })
  if (app.length > 0) {
    return true
  }
  return false
}

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    creation,
    companyId,
    companyName,
    companyLanguage,
    handleClickClose,
    phone,
    setAdminTableRows,
    urlAccessCode,
    allBrandsDevices,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [errorFetch, setErrorFetch] = useState(false)
  const [isFetchingOptician, setIsFetchingOptician] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const isDemoOptician = searchDemoOptician(adminTableRows)
  const [openDialog, setOpenDialog] = useState(false)


  //Valori dei Dati
  const [opticianUser, setOpticianUser] = useState(
    isDemoOptician ? (
      creation
        ? {
          username: undefined,
          password: "",
          optician: {
            opticianId: undefined,
            name: undefined,
          },
          language: companyLanguage ? companyLanguage : "it-it",
          utc: 1,
          active: true,
          //email: UserUtils.generatePasswordLettersNumbers() + "@test.com",
          email: undefined,
          demo: false,
          opticianPhone: "",
          description: undefined,
          settings:undefined,
        }
        : {
          username: adminTableRowData.username,
          password: adminTableRowData.password,
          optician: {
            opticianId: adminTableRowData.optician.opticianId,
            name: adminTableRowData.optician.name,
          },
          language: adminTableRowData.language,
          utc: adminTableRowData.utc,
          active: adminTableRowData.active,
          email: adminTableRowData.email,
          demo: adminTableRowData.demo ? adminTableRowData.demo : false,
          opticianPhone: phone,
          description: adminTableRowData.description,
          settings: adminTableRowData.settings    
        }
    ) : (
      {
        username: "demoOptician",
        password: "demo",
        optician: {
          opticianId: "OPT1",
          name: "",
        },
        language: "it-it",
        utc: 1,
        active: true,
        email: undefined,
        demo: true,
        opticianPhone: "",
        description: "Profilo uso Pegaso",
        settings: {
          servicePort: 3001,
        },
      }
    )

  )

  const [selectedDeviceJSON, setSelectedDeviceJSON] = useState(
    adminTableRowData.userDevice
      ? adminTableRowData.userDevice
      : undefined
  )

  //Crea la codifica esatta dell'username
  const createUsername = useCallback(
    (userId, labOrStore) =>
      UserUtils.createUsernameUtil(
        userId,
        labOrStore,
        adminTableRows,
        companyId
      ),
    [adminTableRows, companyId]
  )

  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch dati laboratorio qui dentro vengono settati/creati numero di telefono, nomeottico, password in fase di creazione
  const fetchLabId = useCallback((opticianData) => {
    setErrorFetch(false)
    const url = `${apiUrl}/companies/${urlAccessCode}/labs/${opticianData.lab}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se Lab esiste
        setOpticianUser({
          ...opticianUser,
          optician: {
            ...opticianUser.optician,
            name: opticianData.name,
          },
          opticianPhone: opticianData.phone,
          password: UserUtils.generatePasswordLettersNumbers(),
          username: createUsername(opticianUser.optician.opticianId, "S"),
          utc: data.utc
        })
        setIsFetchingOptician(false)

      })
      .catch((err) => {
        // Se Lab non esiste
        if (err.body.code === "E4") {
          setDialogMsg(t("10141") + " " + t("10142"))
          setErrorFetch(true)
        }
        ErrorUtils.errorLog(t("23"), err)
      })
  }, [
    access_token,
    apiUrl,
    createUsername,
    opticianUser,
    refresh_token,
    setAccess_token,
    t,
    urlAccessCode,
  ])

  //Fetch dati ottico qui dentro vengono settati/creati numero di telefono, nomeottico, password in fase di creazione
  const fetchOpticianId = useCallback(() => {
    setIsFetchingOptician(true)
    setErrorFetch(false)
    const url = `${apiUrl}/companies/${urlAccessCode}/opticians/${opticianUser.optician.opticianId}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        fetchLabId(data)
        // Se OpticianUser esiste
      })
      .catch((err) => {
        // Se OpticianUser non esiste
        if (err.body.code === "E4") {
          setDialogMsg(t("10208") + " " + t("10142"))
          setErrorFetch(true)
        }
        setIsFetchingOptician(false)
        ErrorUtils.errorLog("Fetch Optician Id Error", err)
      })
  }, [
    access_token,
    apiUrl,
    fetchLabId,
    opticianUser,
    refresh_token,
    setAccess_token,
    t,
    urlAccessCode,
  ])




  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setOpticianUser({ ...opticianUser, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (opticianUserParameter, name) => (event) => {
    setOpticianUser({ ...opticianUser, [name]: opticianUserParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setOpticianUser({ ...opticianUser, [name]: event.target.checked })
  }

  //Cambia OpticianUser.Optician field
  const handleChangeOptician = (name) => (event) => {
    setOpticianUser({
      ...opticianUser,
      optician: { ...opticianUser.optician, [name]: event.target.value },
    })
  }

  //Cambia OpticianUser.Settings field
  const handleChangeSettings = (name) => (event) => {
    setOpticianUser({
      ...opticianUser,
      settings: { ...opticianUser.settings, [name]: event.target.value },
    })
  }
  const onBlurOpticianId = () => {
    if (opticianUser.optician.opticianId === "") {
      setOpticianUser({
        ...opticianUser,
        optician: {
          ...opticianUser.optician,
          name: "",
        },
        opticianPhone: "",
        password: "",
        username: "",
      })
    } else {
      fetchOpticianId()
    }
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    var device = selectedDeviceJSON
      ? (selectedDeviceJSON.model.modelId === undefined ? UserUtils.getBrandModel(selectedDeviceJSON, allBrandsDevices) : selectedDeviceJSON)
      : undefined
    var deviceToBody = device
      ? UserUtils.createJsonDevices(device, false)
      : undefined
    setIsSaving(true)
    var companyUrl = ""
    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      companyUrl = 'https://pre-mini.pegasoplatform.com/#/' + urlAccessCode
    }
    else {
      companyUrl = 'https://mini.pegasoplatform.com/#/' + urlAccessCode
    }
    const url = `${apiUrl}/companies/${urlAccessCode}/users/${opticianUser.username}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: opticianUser.username,
        password: opticianUser.password,
        email: opticianUser.email,
        opticianId: opticianUser.optician.opticianId,
        active: opticianUser.active,
        language: opticianUser.language,
        utc: opticianUser.utc,
        demo: opticianUser.demo,
        userDevice: deviceToBody,
        description: opticianUser.description,
        settings: opticianUser.settings,
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10102")}\n\nUsername: ${opticianUser.username}\nPassword: ${opticianUser.password}\n${companyUrl}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Aggiorna la lista utenti
        let newElem = {
          username: opticianUser.username,
          password: opticianUser.password,
          email: opticianUser.email !== "" ? opticianUser.email : undefined,
          optician: {
            opticianId: opticianUser.optician.opticianId,
            name: opticianUser.optician.name,
          },
          active: opticianUser.active,
          language: opticianUser.language,
          utc: opticianUser.utc,
          demo: opticianUser.demo,
          viewPassword: false,
          userDevice: device,
          description: opticianUser.description,
          settings: opticianUser.settings,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.username === opticianUser.username) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("28"), err)
        setDialogMsg(t("28"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !opticianUser.username ||
      !opticianUser.password ||
      !opticianUser.optician.opticianId
    return result
  }

  return (
    <div className={classes.root}>
      {isDemoOptician ? (
        <Card>
          <CardBody>
            <AdminTableElevationScroll
              {...props}
              handleClickClose={handleClickClose}
            />
            <p align="center">
              <u>{t("10209").toUpperCase()} </u>
            </p>
            <Grid container spacing={2}>
              <Grid
                className={classes.gridCurrentValues}
                item
                xs={12}
                container
                justify="flex-start"
                alignItems="center"
              >
                {/* prima riga - la somma degli xs non deve superare 12  */}
                <Grid item xs={12}>
                  <p>
                    <strong>
                      {t("10145")}: {companyName}
                    </strong>
                  </p>
                </Grid>
                {creation ? null : (
                  <Grid item xs={11}>
                    <p>
                      <strong>
                        {t("10208")}: {adminTableRowData.optician.opticianId} -{" "}
                        {adminTableRowData.optician.name}
                      </strong>
                    </p>
                  </Grid>
                )}
              </Grid>

              <Grid className={classes.gridCurrentValues} item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <p align="center">
                      <u>{t("10104").toUpperCase()}</u>
                    </p>
                  </Grid>

                  {/*GRID CONTENENTE I DATI DELL'UTENTE*/}

                  <Grid item xs={3}>
                    <TextField
                      required
                      id={"username"}
                      label={t("10062")}
                      value={opticianUser.username || ""}
                      margin="normal"
                      helperText={t("10105")}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      required
                      id={"password"}
                      label={t("10063")}
                      onChange={handleChange("password")}
                      className={classesText.textField}
                      value={opticianUser.password || ""}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    {creation ? (
                      isFetchingOptician ? (
                        <CircularIndeterminate />
                      ) : (
                        <TextField
                          required
                          error={errorFetch ? true : false}
                          id={"opticianId"}
                          label={t("10208")}
                          onChange={handleChangeOptician("opticianId")}
                          onBlur={onBlurOpticianId}
                          className={classesText.textField}
                          value={opticianUser.optician.opticianId || ""}
                          margin="normal"
                          helperText={errorFetch ? dialogMsg : ""}
                        />
                      )
                    ) : (
                      <TextField
                        required
                        id={"opticianId"}
                        label={t("10208")}
                        className={classesText.textField}
                        value={opticianUser.optician.opticianId || ""}
                        margin="normal"
                        helperText={t("10105")}
                        InputProps={{
                          readOnly: creation ? false : true,
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"name"}
                      label={t("10079")}
                      className={classesText.textField}
                      value={opticianUser.optician.name || ""}
                      margin="normal"
                      helperText={t("10105")}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="language"
                      label={t("10083")}
                      value={opticianUser.language}
                      onChange={handleChange("language")}
                      helperText={t("10106")}
                    >
                      {languageList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      select
                      id="utc"
                      label={"UTC"}
                      value={opticianUser.utc}
                      onChange={handleChange("utc")}
                      helperText={t("10107")}
                    >
                      {UTCList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opticianUser.active}
                          onChange={handleChangeChecked("active")}
                          name="active"
                        />
                      }
                      label={t("10047")}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"email"}
                      label={t("10108")}
                      className={classesText.textField}
                      value={opticianUser.email || ""}
                      margin="normal"
                      onChange={handleChange("email")}
                      onBlur={opticianUser.email ? onBlurTrim(opticianUser.email, "email") : null}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opticianUser.demo}
                          onChange={handleChangeChecked("demo")}
                          name="demo"
                        />
                      }
                      label={"demo"}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"phone"}
                      label={t("10149")}
                      className={classesText.textField}
                      value={opticianUser.opticianPhone || ""}
                      margin="normal"
                      helperText={t("10105")}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id={"description"}
                      label={t("10110")}
                      className={classesText.textField}
                      value={opticianUser.description || ""}
                      margin="normal"
                      onChange={handleChange("description")}
                      onBlur={opticianUser.description ? onBlurTrim(opticianUser.description, "description") : null}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*GRID CONTENENTE IL DEVICE DELL'UTENTE*/}

            <FilterDevice
              allBrandsDevices={allBrandsDevices}
              creation={creation}
              isHub={false}
              handleChangeSettings={handleChangeSettings}
              userDevice={adminTableRowData.userDevice}
              userData={opticianUser}
              selectedDeviceJSON={selectedDeviceJSON}
              setSelectedDeviceJSON={setSelectedDeviceJSON}
            />

            {/*BOTTONI PER SALVATAGGIO*/}
            <AdminTableSaveButton
              {...props}
              handleSave={handleSave}
              disabled={disableSave()}
            />
            <AdminTableOkSaved
              {...props}
              dialogMsg={dialogMsg}
              handleClose={handleClose}
              isSaving={isSaving}
              openDialog={openDialog}
            />
          </CardBody>
        </Card>
      ) : (
        <Card>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.gridToolbar}
          >
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSave}
              >
                {t("29")}
              </Button>
            </Box>
            <AdminTableOkSaved
              {...props}
              dialogMsg={dialogMsg}
              handleClose={handleClose}
              isSaving={isSaving}
              openDialog={openDialog}
            />
          </Box>
        </Card>
      )
      }

    </div>
  )
}

// Griglia interna alla finestra del OpticianUser
export default function OpticianUser(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
