import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import AdminTableImageButton from "components/AdminTable/AdminTableImageButton"
import AdminTableLicenseButton from "components/AdminTable/AdminTableLicenseButton"
import Company from "views/Company/Company"
import CompanyImage from "views/Company/CompanyImage"
import CompanyLicense from "views/Company/CompanyLicense"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Link from '@material-ui/core/Link';
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Typography from "@material-ui/core/Typography"

// Nomi dei campi delle colonne
function createData(companyId, companySuffix, urlAccessCode, name, active, licenseActive) {
  return {
    companyId,
    companySuffix,
    urlAccessCode,
    name,
    active,
    licenseActive,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.urlAccessCode !== rowToDelete
  })
}

export default function Companies() {
  const {
    t,
    isRetailerUser,
    getRetailerId,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
    allRetailers
  } = useContext(RootContext)

  // Variabili
  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())
  const [allScopes, setAllScopes] = useState([])

  const [filter, setFilter] = useState([
    {
      id: "companyId",
      value: "",
      label: t("10114"),
    },
    {
      id: "name",
      value: "",
      label: t("10079"),
    },
    {
      id: "urlAccessCode",
      value: "",
      label: "urlAccessCode",
    },
    
    {
      id: "retailerId",
      value: retailerId ? retailerId : "",
      isSelect: true,
      selectOption: [],
      label: t("10226"),
      disabled: isRetailer,
    }
    /*{
      id: "advancedLicense",
      value: "",
      defaultValue: "",
      isSelect: true,
      selectOption: [],
      label: t("10277"),
      disabled: isRetailer
    }*/
  ])
  const headCells = [
    {
      id: "companyId",
      numeric: true,
      disablePadding: false,
      label: t("10114").toUpperCase(),
    },
    {
      id: "companySuffix",
      numeric: false,
      disablePadding: false,
      label: t("10115").toUpperCase(),
    },
    {
      id: "urlAccessCode",
      numeric: false,
      disablePadding: false,
      label: t("10116").toUpperCase(),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("10117").toUpperCase(),
    },
    {
      id: "active",
      numeric: false,
      disablePadding: false,
      label: t("10047").toUpperCase()
    },
    {
      id: "licenseActive",
      numeric: false,
      disablePadding: false,
      label: t("10242").toUpperCase()
    },
    {
      id: "companyUrl",
      numeric: false,
      disablePadding: false,

      label: t("10118").toUpperCase()
    },
    {
      id: "subscribedOn",
      numeric: false,
      disablePadding: false,
      label: t("10200").toUpperCase()
    }
  ]
  const orderByDefault = "companyId"
  const getCompaniesParameter = "companyId,companySuffix,urlAccessCode,name,active,license,subscribedOn"
  const urlGet = retailerId ?
    `${apiUrl}/list-companies?retailer=${retailerId}&get_Parameter=${getCompaniesParameter}`
    //: `${apiUrl}/list-companies`
    : `${apiUrl}/list-companies?get_Parameter=${getCompaniesParameter}`
  const urlDelete = `${apiUrl}/companies/`
  const [companyLabs, setCompanyLabs] = useState()
  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.urlAccessCode
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 8
  }

  // Finestra Logo company
  function DialogLogo(props) {
    const { openLogo, handleClickClose } = props
    return (
      openLogo ? (
        <Dialog
          onClose={handleClickClose}
          aria-labelledby="customized-dialog-title"
          open={openLogo}
          maxWidth="xl"
        >
          <DialogContent>
            <CompanyImage
              {...props}
            />
          </DialogContent>
        </Dialog>
      ) : (
        null
      )
    )
  }

  // Finestra License company
  function DialogLicense(props) {
    const { openLicense, handleClickClose } = props
    return (
      openLicense ? (
        <Dialog
          onClose={handleClickClose}
          aria-labelledby="customized-dialog-title"
          open={openLicense}
          maxWidth="xl"
        >
          <DialogContent>
            <CompanyLicense
              {...props}
            />
          </DialogContent>
        </Dialog>
      ) : (
        null
      )
    )
  }

  // Tasto Logo company
  function CellButtonLogo(props) {
    const { keyToUse } = props
    const [openLogo, setOpenLogo] = useState(false)

    // Click Routing Job
    function handleOpenLogo(event) {
      setOpenLogo(true)
    }

    // Click Close Job
    function handleCloseLogo() {
      setOpenLogo(false)
    }

    return (
      <TableCell key={"tableBodyCellLogo-" + keyToUse} align="left">
        <AdminTableImageButton
          deleteButton={keyToUse}
          handleClickOpen={handleOpenLogo}
        />
        {openLogo ?
          (<DialogLogo
            openLogo={openLogo}
            handleClickClose={handleCloseLogo}
            urlAccessCode={keyToUse}>

          </DialogLogo>
          ) : (
            null)
        }
      </TableCell>
    )
  }

  // Tasto License company
  function CellButtonLicense(props) {
    const { keyToUse, currentLicense, rows, setRows } = props
    const [openLicense, setOpenLicense] = useState(false)

    // Click Routing Job
    function handleOpenLicense(event) {
      setOpenLicense(true)
    }

    // Click Close Job
    function handleCloseLicense() {
      setOpenLicense(false)
    }

    return (
      <TableCell key={"tableBodyCellLicense-" + keyToUse} align="left">
        <AdminTableLicenseButton
          deleteButton={keyToUse}
          handleClickOpen={handleOpenLicense}
        />
        {openLicense ?
          (<DialogLicense
            currentLicense={currentLicense}
            openLicense={openLicense}
            handleClickClose={handleCloseLicense}
            rows={rows}
            setRows={setRows}
            urlAccessCode={keyToUse}
          >
          </DialogLicense>
          ) : (
            null)
        }
      </TableCell>
    )
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const { CellButtonEdit, CellButtonDelete, keyToUse, labelId, row, rows, setRows } = props
    const key = keyToUse
    var companyUrl = ""

    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      companyUrl = 'https://pre-mini.pegasoplatform.com/#/'
    }
    else {
      companyUrl = 'https://mini.pegasoplatform.com/#/'
    }

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {row.companyId ? row.companyId : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.companySuffix ? row.companySuffix : ""}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.name ? row.name : ""}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.license !== undefined ? (row.license.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {
            <Typography>
              <Link
                //href="#"
                onClick={() => {
                  window.open(companyUrl + row.urlAccessCode, "_blank")
                }}
                variant="body2"
              >
                {companyUrl + row.urlAccessCode}
              </Link>
            </Typography>
          }
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.subscribedOn ? row.subscribedOn.split("T")[0] : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        {!isRetailer ? <CellButtonDelete keyToUse={keyToUse} /> : null}
        {!isRetailer ? <CellButtonLogo keyToUse={keyToUse} /> : null}
        <CellButtonLicense keyToUse={keyToUse} currentLicense={row.license ? row.license : null} rows={rows} setRows={setRows} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.companyId,
            filter.filter((filter) => {
              return filter.id === "companyId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.name,
            filter.filter((filter) => {
              return filter.id === "name"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.urlAccessCode,
            filter.filter((filter) => {
              return filter.id === "urlAccessCode"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.license.retailer.retailerId,
            filter.filter((filter) => {
              return filter.id === "retailerId"
            })[0].value
          )
        })
      /*.filter((row) => {
        return filterFieldLicense(
          row,
          row.companyScopes,
          filter.filter((filter) => {
            return filter.id === "advancedLicense"
          })[0].value
        )
      })*/
      : rows
  }

  //Filtro per select customizzata 
  /*function filterFieldLicense(row, rowField, filterField) {
    if (filterField !== null && filterField !== undefined) {
      filterField = String.prototype.trim.call(filterField)
      if (filterField !== "") {
        if (rowField !== null && rowField !== undefined) {
          if (rowField !== "") {
            var containScope = rowField.some(item => item.scopeId === parseInt(filterField))
            if (containScope) {
              return row
            }
          } else {
            return row
          }
        } else {
          // Elemento da scartare
        }
      } else {
        return row
      }
    } else {
      return row
    }
  }*/


  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch -  Dati Company selezionata
  const fetchCompany = useCallback(
    (urlAccessCode, rows, setData, setActionFetch) => {
      const urlCompany = `${apiUrl}/companies/${urlAccessCode}`
      //let labId
      fetchWithToken(urlCompany, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setData(data)
          const url = `${apiUrl}/companies/${urlAccessCode}/list-labs`
          fetchWithToken(url, {
            method: "GET",
            apiUrl: apiUrl,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
          })
            .then((response) => {
              return response.body // data
            })
            .then((data) => {
              data.sort(function (a, b) {
                if (a.labId >= b.labId) {
                  return 1
                } else {
                  return -1
                }
              })
              setCompanyLabs(data)
            })
            .catch((err) => {
              ErrorUtils.errorLog("FetchCompany  Error", err)
            })
        })
        .catch((err) => {
          ErrorUtils.errorLog("FetchCompany  Error", err)
        })
      setActionFetch(false)
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )

  //Fetch -  Elenco scope types
  const fetchScopesTypes = useCallback(() => {
    const url = `${apiUrl}/scopetypes`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((scopes) => {
        setAllScopes(scopes.scopeTypes)
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchScopes  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token])


  // 2024/02/01 - Commentato fetchScopes xk abbiamo tolto il filtro per SCOPES
  //Fetch -  Elenco scopes
  /*const fetchScopes = useCallback(() => {
    const url = `${apiUrl}/scopes`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((scopes) => {
        var newFilter = filter
        var filterScopes = scopes.map((option) => {
          return {
            value: option.scopeId,
            label: option.scopeId + " - " + option.description,
          }
        })
        filterScopes.sort(function (a, b) {
          if (parseInt(a.value) >= parseInt(b.value)) {
            return 1
          } else {
            return -1
          }
        })
        filterScopes.unshift({
          value: "",
          label: "",
        })
        newFilter.map(filter => {
          filter.selectOption = filter.id === "advancedLicense" ? filterScopes : filter.selectOption
          return filter
        })
        setFilter(newFilter)
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchScopes  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, filter, setFilter, setAccess_token])*/

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    fetchScopesTypes()
    //fetchScopes()
    //}, [fetchScopes, fetchRetailers, fetchScopesTypes])
  }, [fetchScopesTypes])

  useEffect(() => {
    if(allRetailers){
      if (allRetailers.length > 0) {
        var newFilter = filter
        var filterRetailers = allRetailers.map((option) => {
          return {
            value: option.retailerId,
            label: option.retailerId + " - " + option.name,
          }
        })
        filterRetailers.sort(function (a, b) {
          if (parseInt(a.value) >= parseInt(b.value)) {
            return 1
          } else {
            return -1
          }
        })
        filterRetailers.unshift({
          value: "",
          label: "",
        })
        newFilter.map(filter => {
          filter.selectOption = filter.id === "retailerId" ? filterRetailers : filter.selectOption
          return filter
        })
        setFilter(newFilter)
      }
    }
  }, [allRetailers,  filter])


  return (
    <div>
      <AdminTable
        AddEditElement={Company}
        AdminTableCells={AdminTableCells}
        allRetailers={allRetailers}
        allScopes={allScopes}
        createData={createData}
        companyLabs={companyLabs}
        defaultRowsPerPage={10}
        disableAdd={isRetailer}
        filter={filter}
        filterToolbarExpaned={false}
        FilterAdminTableCells={FilterAdminTableCells}
        handleClickOpenAction={fetchCompany}
        headCells={headCells}
        isFullTable={true}
        getAdminTableKey={getAdminTableKey}
        getAdminTableCellsCount={getAdminTableCellsCount}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        urlDelete={urlDelete}
        urlGet={urlGet}
        useCompany={false}
      />
    </div>
  )
}
