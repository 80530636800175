import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

const languageList = UserUtils.languageList

const UTCList = UserUtils.UTCList()

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    creation,
    handleClickClose,
    setAdminTableRows,
    urlAccessCode,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  //Valori dei Dati
  const [adminUser, setAdminUser] = useState(
    creation
      ? {
        username: undefined,
        password: "",
        language: "it-it",
        utc: 1,
        active: true,
        //email: UserUtils.generatePasswordLettersNumbers() + "@test.com",
        email: undefined,
        demo: false,
        description: undefined,
      }
      : {
        username: adminTableRowData.username,
        password: adminTableRowData.password,
        language: adminTableRowData.language,
        utc: adminTableRowData.utc,
        active: adminTableRowData.active,
        email: adminTableRowData.email,
        demo: adminTableRowData.demo ? adminTableRowData.demo : false,
        description: adminTableRowData.description,
      }
  )

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setAdminUser({ ...adminUser, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (adminParameter, name) => (event) => {
    setAdminUser({ ...adminUser, [name]: adminParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setAdminUser({ ...adminUser, [name]: event.target.checked })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/admin-users/${adminUser.username}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: adminUser.username,
        password: adminUser.password,
        email: adminUser.email,
        active: adminUser.active,
        language: adminUser.language,
        utc: adminUser.utc,
        demo: adminUser.demo,
        description: adminUser.description,
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10102")}\n\nUsername: ${adminUser.username}\nPassword: ${adminUser.password
          }`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Aggiorna la lista utenti
        let newElem = {
          username: adminUser.username,
          password: adminUser.password,
          email: adminUser.email !== "" ? adminUser.email : undefined,
          active: adminUser.active,
          language: adminUser.language,
          utc: adminUser.utc,
          demo: adminUser.demo,
          viewPassword: false,
          description: adminUser.description,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.username === adminUser.username) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !adminUser.username ||
      !adminUser.password
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10103").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DELL'UTENTE*/}

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"username"}
                    label={t("10062")}
                    onChange={handleChange("username")}
                    onBlur={adminUser.username ? onBlurTrim(adminUser.username, "username") : null}
                    className={classesText.textField}
                    value={adminUser.username || ""}
                    margin="normal"
                    helperText={creation ? null : t("10105")}
                    InputProps={{
                      readOnly: !creation,
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"password"}
                    label={t("10063")}
                    onChange={handleChange("password")}
                    onBlur={onBlurTrim(adminUser.password, "password")}
                    className={classesText.textField}
                    value={adminUser.password || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id="language"
                    label={t("10083")}
                    value={adminUser.language}
                    onChange={handleChange("language")}
                    helperText={t("10106")}
                  >
                    {languageList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    id="utc"
                    label={"UTC"}
                    value={adminUser.utc}
                    onChange={handleChange("utc")}
                    helperText={t("10107")}
                  >
                    {UTCList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={adminUser.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"email"}
                    label={t("10108")}
                    className={classesText.textField}
                    value={adminUser.email || ""}
                    margin="normal"
                    onChange={handleChange("email")}
                    onBlur={adminUser.email ? onBlurTrim(adminUser.email, "email") : null}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={adminUser.demo}
                        onChange={handleChangeChecked("demo")}
                        name="demo"
                      />
                    }
                    label={t("10109")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"description"}
                    label={t("10110")}
                    className={classesText.textField}
                    value={adminUser.description || ""}
                    margin="normal"
                    onChange={handleChange("description")}
                    onBlur={adminUser.description ? onBlurTrim(adminUser.description, "description") : null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del OpticianUser
export default function AdministratorUser(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
