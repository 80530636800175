import React, { useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import Checkbox from "@material-ui/core/Checkbox"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

//pegaso/views/components
import DownloadOption from "../DeviceOption/DownloadOption"
import ScopeUtils from "components/Utils/ScopeUtils";


export default function ExportJobsToExternalSystem(props) {

  const {
    adminTableRows,
    creation,
    handleClickClose,
    scopesJSON,
    userScope,
    selectedScopeData,
    setAdminTableRows,
    setScopesJSON,
  } = props

  const {
    t,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Varibili per lo scope

  const [active, setActive] = useState((creation || !userScope) ? true : userScope.active)
  const [downloadLabel, setDownloadLabel] = useState(((creation || !userScope) ? [] : userScope.hubSettings.file.download.omaLabels))
  const [downloadOption, setDownloadOption] = useState(((creation || !userScope) ?
    {
      trcfmtbin: 0,
      trcfmtpoints: 0,
      zfmtbin: 0,
      zfmtpoints: 0,
      omaLabels: []
    } :
    userScope.hubSettings.file.download))
  const [changeDevice, setChangeDevice] = useState(false)
  const [extension, setExtension] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.extension))
  const [fileNameType, setFileNameType] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.fileNameType))
  const [daysBeforeDelete, setDaysBeforeDelete] = useState(daysBeforeDeleteSetter(userScope, creation))
  const [username, setUsername] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.username))
  const [password, setPassword] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.file.password))
  const hubScopeId = ((creation || !userScope) ? ScopeUtils.countScopeId(adminTableRows) : userScope.hubScopeId)
  const [openPanelOption, setOpenPanelOption] = useState(false)
  const [path, setPath] = useState(((creation || !userScope) ? "" : userScope.hubSettings.file.path))
  const [scopeFull, setScopeFull] = useState(((creation || !userScope) ? undefined : userScope.scopeFull))
  const scopesType = selectedScopeData.scopes
  const [system, setSystem] = useState(((creation || !userScope) ? "" : userScope.scopeId))
  const [topicId, setTopicId] = useState(((creation || !userScope) ? undefined : userScope.hubSettings.topicId))
  const systemValue = scopesType.map((option) => {
    return {
      value: option.defaultSettings.system,
      scopeId: option.scopeId
    }
  })

  //Setta i giorni prima della cancellazione dei file da hub
  function daysBeforeDeleteSetter(userScope, creation) {
    if (creation || !userScope || !userScope.hubSettings.file.hasOwnProperty('daysBeforeDelete')) {
      return 182
    }
    if (userScope.hubSettings.file.hasOwnProperty('daysBeforeDelete')) {
      return userScope.hubSettings.file.daysBeforeDelete
    }
  }
  
  //Attiva lo scope
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
  }

  //Setta la path
  const handleChangePath = (event) => {
    setPath(event.target.value)
  }

  //Setta topicId
  const handleChangeTopicId = (event) => {
    setTopicId(event.target.value)
  }

  //Setta fileNameType
  const handleChangeFileNameType = (event) => {
    setFileNameType(parseInt(event.target.value))
  }

  //Setta fileNameType
  const handleChangeExtension = (event) => {
    setExtension(event.target.value)
  }

  //Setta i valori del sistema selezionato
  const handleChangeSystem = (event) => {
    let value, selected
    value = event.target.value
    setSystem(value)
    selected = scopesType.filter((option) => {
      return value === option.scopeId
    })[0]
    setChangeDevice(true)
    setDownloadLabel(selected.defaultSettings.file.download.omaLabels)
    setDownloadOption(selected.defaultSettings.file.download)
    setExtension(selected.defaultSettings.file.extension)
    setFileNameType(selected.defaultSettings.file.fileNameType)
    setPassword(selected.defaultSettings.file.password)
    setPath(selected.defaultSettings.file.path)
    setTopicId(selected.defaultSettings.topicId)
    setUsername(selected.defaultSettings.file.username)
    setDaysBeforeDelete(selected.defaultSettings.file.daysBeforeDelete)
    setScopeFull({
      defaultSettings: selected.defaultSettings,
      scopeType: {
        description: creation ? selectedScopeData.description : userScope.scopeFull.scopeType.description,
        scopeTypeId: creation ? selectedScopeData.scopeTypeId : userScope.scopeFull.scopeType.scopeTypeId
      }
    })
  }

  //Setta username path
  const handleChangeUsername = (event) => {
    setUsername(event.target.value)
  }

  //Setta password path
  const handleChangePassword = (event) => {
    setPassword(event.target.value)
  }

  //Setta daysbeforedelete 
  const handleChangeDaysbeforedelete = (event) => {
    setDaysBeforeDelete(parseInt(event.target.value))
  }

  //Salva il sistema selezionato
  const handleSave = () => {
    let value, selectedSystemData
    selectedSystemData = scopesType.filter((option) => {
      return system === option.scopeId
    })[0]
    value = {
      hubScopeId: hubScopeId,
      scopeId: system,
      active: active,
      hubSettings: {
        topicId: topicId,
        system: selectedSystemData.defaultSettings.system,
        file: {
          brand: selectedSystemData.defaultSettings.file.brand,
          download: downloadOption,
          extension: extension,
          fileNameType: fileNameType,
          model: selectedSystemData.defaultSettings.file.model,
          path: path,
          password: password !== undefined ? password : "",
          username: username !== undefined ? username : "",
          daysBeforeDelete: daysBeforeDelete !== undefined ? daysBeforeDelete : 182
        }
      },
      scopeFull: scopeFull
    }
    ScopeUtils.saveScopeJSON(scopesJSON, setScopesJSON, creation, value, hubScopeId)
    let newAdminTableRows = creation
      ? adminTableRows.concat(value)
      : adminTableRows.map((elem) => {
        if (elem.hubScopeId === userScope.hubScopeId) {
          return value
        } else {
          return elem
        }
      })
    setAdminTableRows(newAdminTableRows)
    handleClickClose()
  }

  //Chiude il pannello contenten i dati
  const changeOpenPanelOption = (event,) => {
    var value = !openPanelOption
    setOpenPanelOption(value)
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result = system === ""

    return result
  }

  return (
    <Grid className={classes.gridCurrentValues} item xs={12}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10068").toUpperCase()}</u>
          </p>
        </Grid>

        {/*RIGA 1*/ }

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={handleChangeActive}
                name="active"
              />
            }
            label={t("10047")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            id="system"
            disabled={!creation}
            label={t("10069")}
            value={system}
            onChange={handleChangeSystem}
            helperText={t("10070")}

          >
            {systemValue.map((option) => (
              <MenuItem key={option.scopeId} value={option.scopeId}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"path"}
            label={t("10071")}
            className={classesText.textField}
            value={path || ""}
            margin="normal"
            onChange={handleChangePath}

          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"topicId"}
            label={t("10072")}
            className={classesText.textField}
            value={topicId || ""}
            margin="normal"
            onChange={handleChangeTopicId}
          />
        </Grid>

        {/*RIGA 2*/ }

        <Grid item xs={3}>
          <TextField
            id={"fileNameType"}
            label={t("10073")}
            className={classesText.textField}
            value={fileNameType || ""}
            margin="normal"
            onChange={handleChangeFileNameType}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"extension"}
            label={t("10074")}
            className={classesText.textField}
            value={extension || ""}
            margin="normal"
            onChange={handleChangeExtension}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"username"}
            label={t("10062")}
            className={classesText.textField}
            value={username || ""}
            margin="normal"
            onChange={handleChangeUsername}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"password"}
            label={t("10063")}
            className={classesText.textField}
            value={password || ""}
            margin="normal"
            onChange={handleChangePassword}
          />
        </Grid>

        {/*RIGA 3*/ }

        <Grid item xs={3}>
          <TextField
            id={"daysBeforeDelete"}
            label={t("10283")}
            className={classesText.textField}
            value={daysBeforeDelete || ""}
            margin="normal"
            onChange={handleChangeDaysbeforedelete}
          />
        </Grid>

      </Grid>
      <Accordion expanded={openPanelOption} onChange={changeOpenPanelOption}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("10014")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <DownloadOption
              changeDevice={changeDevice}
              downloadLabel={downloadLabel}
              downloadOption={downloadOption}
              setChangeDevice={setChangeDevice}
              setDownloadLabel={setDownloadLabel}
              setDownloadOption={setDownloadOption}
              setOpenPanelOption={setOpenPanelOption}
            />
          </Card>
        </AccordionDetails>
      </Accordion>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        p={1}
        className={classes.gridToolbar}
      >
        <Box p={1}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={disableSave()}
            onClick={handleSave}
          >
            {t("10010")}
          </Button>
        </Box>
      </Box>



    </Grid >
  )
}