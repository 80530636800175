import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './styles/theme'
import './locales/i18n'
import RootContext from './RootContext'
import Login from './components/Login/Login'

// core components
import Admin from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <RootContext>
  <MuiThemeProvider theme={theme}>
    
      <Router history={hist}>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PrivateRoute path="/admin" component={Admin} />
          {/* <Redirect from="/" to="/admin/dashboard" /> */}
        </Switch>
      </Router>
  </MuiThemeProvider>
  </RootContext>,
  document.getElementById("root")
);

export function PublicRoute({ component: Component, ...rest }) {
  //const { authenticated } = useContext(RootContext)
  const authenticated = window.sessionStorage.getItem('authenticated') || null
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Redirect
            to={{
              pathname: `/admin`,  
              state: { from: props.location },
            }}
          />
        ) :
          (
            <Component {...props} />
          )
      }
    />
  )
}

export function PrivateRoute({ component: Component, ...rest }) {
  //const { authenticated,companyId} = useContext(RootContext)   
  const company_id = window.sessionStorage.getItem('company_id') || null
  const authenticated = window.sessionStorage.getItem('company_id') || null
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: `/${company_id}`,          
                state: { from: props.location },
              }}
            />
          )
      }
    />
  )
}