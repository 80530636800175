import React, { useContext } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components

//core components
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Tooltip from "@material-ui/core/Tooltip"

function AdminTableDeleteButton(props) {
  const { handleClickOpen, deleteButton } = props
  const { t } = useContext(RootContext)

  return (
    <div id="contenitore">
      <Tooltip title={t("10007")}>
        <IconButton
          aria-label={t("10007")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(deleteButton)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AdminTableDeleteButton
