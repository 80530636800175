//Pannello per la scelta del device
import React, { useContext, useEffect, } from "react"
import { RootContext } from "../../RootContext"

//core components
import Checkbox from "@material-ui/core/Checkbox"
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useStylesText } from "styles/adminTable/adminTableEditScreenCss"

//Componenti Pegaso
import DeviceUtils from "components/Utils/DeviceUtils"

export default function OptionDevice(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    optionDevice,
    setChangeDevice,
    setOpenPanelOption,
    setOptionDevice,
  } = props

  //Classes utilizzate per CSS
  const classesText = useStylesText()

  const tracerIdValue = DeviceUtils.tracerIdValue()
  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    if (changeDevice) {
      setOpenPanelOption(false)
      setChangeDevice(false)
    }
  }, [changeDevice, setOptionDevice, setChangeDevice, setOpenPanelOption])

  /*OPTION HANDLER*/

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setOptionDevice({ ...optionDevice, [name]: event.target.checked })
  }

  //Cambia field
  const handleChange = (name) => (event) => {
    var value = event.target.value.trim()
    setOptionDevice({ ...optionDevice, [name]: value })
  }

  const handleChangeTracerId = (event) => {
    setOptionDevice({ ...optionDevice, tracerId: event.target.value })
  }

  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"idProtocol"}
            label={t("10018")}
            className={classesText.textField}
            value={optionDevice.idProtocol}
            margin="normal"
            onChange={handleChange("idProtocol")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"jobWithoutBarcode"}
            label={t("10019")}
            className={classesText.textField}
            value={optionDevice.jobWithoutBarcode}
            margin="normal"
            onChange={handleChange("10019")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.nack}
                onChange={handleChangeChecked("nack")}
                name="nack"
              />
            }
            label={t("10020")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetMetal"}
            label={t("10021")}
            className={classesText.textField}
            value={optionDevice.offsetMetal}
            margin="normal"
            onChange={handleChange("offsetMetal")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetOther"}
            label={t("10059")}
            className={classesText.textField}
            value={optionDevice.offsetOther}
            margin="normal"
            onChange={handleChange("offsetOther")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetPlastic"}
            label={t("10022")}
            className={classesText.textField}
            value={optionDevice.offsetPlastic}
            margin="normal"
            onChange={handleChange("offsetPlastic")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.singleSide}
                onChange={handleChangeChecked("singleSide")}
                name="singleSide"
              />
            }
            label={t("10023")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.ignorePinChanged}
                onChange={handleChangeChecked("ignorePinChanged")}
                name="ignorePinChanged"
              />
            }
            label={t("10289")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.doLogReceiveData}
                onChange={handleChangeChecked("doLogReceiveData")}
                name="doLogReceiveData"
              />
            }
            label={t("10288")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            id={"tracerId"}
            label={t("10024")}
            className={classesText.textField}
            value={optionDevice.tracerId}
            margin="normal"
            onChange={handleChangeTracerId}
          >
            {tracerIdValue.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.withoutBarcode}
                onChange={handleChangeChecked("withoutBarcode")}
                name="withoutBarcode"
              />
            }
            label={t("10025")}
          />
        </Grid>
      </Grid>
    </div>
  )
}