import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme'
import {
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const useAdminTableStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  heading : {
    textTransform: 'capitalize'
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...primaryColor,
    "&, &$tableCell": {
      fontSize: "1.1rem",
      lineHeight: "1.42857143",
      padding: "8px 1px",
      verticalAlign: "middle",
    },
  },
  tableBody: {
    textTransform: 'none'
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "1px 1px",
    verticalAlign: "middle",
    fontSize: "1.1rem",   
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

export { useAdminTableStyles }
