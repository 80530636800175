import AWS from "aws-sdk"
import ErrorUtils from "components/Tools/ErrorUtils"
export default class HubUtils {


  //Crea la coda in aws
  static createAwsQueue(urlAccessCode, hubId, topicId) {
    var queueName = ""
    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      queueName = 'pre-prod_companies_' + urlAccessCode + '_hubs_' + hubId + '_' + topicId.replace('/', '_') + '.fifo'
    }
    else {
      queueName = 'companies_' + urlAccessCode + '_hubs_' + hubId + '_' + topicId.replace('/', '_') + '.fifo'
    }
    var sqs = new AWS.SQS({
      accessKeyId: process.env.REACT_APP_SQS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SQS_SECRET_ACCESS_KEY,
      region: "eu-central-1",
      apiVersion: '2012-11-05'
    })
    var params = {
      QueueName: queueName,
      Attributes: {
        'MessageRetentionPeriod': '1209600',
        'FifoQueue': 'true',
        'ContentBasedDeduplication': 'true',
        'VisibilityTimeout': '120'
      }
    }
    sqs.createQueue(params, function (err, data) {
      if (err) {
        ErrorUtils.errorLog("Error", err)
      }
    });

  }

  //Cancella la coda in aws
  static deleteAwsQueue(urlAccessCode, hubId, topicId) {
    var queueName = ""
    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      queueName = 'pre-prod_companies_' + urlAccessCode + '_hubs_' + hubId + '_' + topicId.replace('/', '_') + '.fifo'
    }
    else {
      queueName = 'companies_' + urlAccessCode + '_hubs_' + hubId + '_' + topicId.replace('/', '_') + '.fifo'
    }
    var sqs = new AWS.SQS({
      accessKeyId: process.env.REACT_APP_SQS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SQS_SECRET_ACCESS_KEY,
      region: "eu-central-1",
      apiVersion: '2012-11-05'
    })
    var url = undefined
    var params = {
      QueueName: queueName
    };
    sqs.getQueueUrl(params, function (err, data) {
      if (err) {
        ErrorUtils.errorLog("Error", err)
      }
      else {
        url = data.QueueUrl
        if (url) {
          params = {
            QueueUrl: url
          }
          sqs.deleteQueue(params, function (err, data) {
            if (err) {
              ErrorUtils.errorLog("Error", err)
            }
          });
        }
      }
    });
  }



}