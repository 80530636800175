import StringUtils from 'components/Tools/StringUtils'
export default class JobsUtils {
  // Format Job Id
  static formatJobId(job) {
    return (StringUtils.checkIfNotNull(job.jobId) === "" ? "" : job.jobId
      //Job.jobId.replace(/^0+/, ''); // Suprress leading zeros
    )
  }

  // Format Status
  static formatJobStatus(job, t) {
    if (String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) === "" || String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) === "OPEN") {
      job.status = t("10184").toUpperCase()
    } else if (String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) === "CLOSED") {
      job.status = t("10185").toUpperCase()
    } else if (String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) === "WIP") {
      job.status = t("10186").toUpperCase()
    }
    return (String.prototype.trim.call(job.status).toUpperCase())
  }

  // Format Optician
  static formatJobOptician(job) {
    
    const {optician} = job
    if (optician){
      const {id, name} = optician
      return ( StringUtils.checkIfNotNull(id) === "" ?  ""  : `${id} - ${String.prototype.trim.call(name)}` )
    }
    else {
      return ""
    }
    
  }

   // Format OpticianNote
   static formatJobOpticianNote(job) {
    const {notes} = job
    const {opticianNote} = notes
    if ( StringUtils.checkIfNotNull(opticianNote) === ""){
      job.notes.opticianNote = ""
    } 
    
    // 2020/03/02 - Tronco le note massimo XX chars
    var result
    const maxChars = 50
    result = job.notes.opticianNote
    if(result.length >= maxChars){
      result = result.substring(0, maxChars)  
      result = result + "..."
    }else{
      result = result  + ' '.repeat(maxChars)
      result = result.substring(0, maxChars) 
    }
    result = String.prototype.trim.call(result)
    return (result)
  }

  // Format Software
  static formatJobSoftware(job, t) {
    if (StringUtils.checkIfNotNull(job.software) === "") {
      job.software = ""
    } else if (String.prototype.trim.call(StringUtils.checkIfNotNull(job.software)) === "minigui") {
      job.software = t("10175")
    } else if (String.prototype.trim.call(StringUtils.checkIfNotNull(job.software)) === "minihub") {
      job.software = t("10146")
    }
    return (String.prototype.trim.call(job.software))
  }

  // Format date
  static formatJobCreationDate(job) {
    return (StringUtils.checkIfNotNull(job.createdOn) === "" ? "" : job.createdOn.$date)
  }

  static formatJobCreationDateFromString(job) {
    const {createdOn} = job
    if(createdOn){
      return `${job.createdOn.substring(0,10)} ${job.createdOn.substring(11,16)}`
    }else{
      return `${job.created.createdOn.substring(0,10)} ${job.created.createdOn.substring(11,16)}`
    }
  }

  static formatJobUpdateDateFromString(job) {
    const {updatedOn} = job
    if(updatedOn){
      return `${job.updatedOn.substring(0,10)} ${job.updatedOn.substring(11,16)}`
    }else{
      return `${job.updated.updatedOn.substring(0,10)} ${job.updated.updatedOn.substring(11,16)}`
    }
  }


}
