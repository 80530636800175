//Pannello per la scelta del device
import React, { useContext, useEffect, } from "react"
import { RootContext } from "../../RootContext"

//core components
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useStylesText } from "styles/adminTable/adminTableEditScreenCss"

//Componenti Pegaso

export default function UploadOption(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,

    uploadOption,
    setChangeDevice,
    setOpenPanelOption,
    setUploadOption,
  } = props

  const classesText = useStylesText()

  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    setOpenPanelOption(false)
    if (changeDevice) {
      setChangeDevice(false)
    }
  }, [changeDevice, setChangeDevice, setOpenPanelOption, setUploadOption])


  //Cambia field
  const handleChange = (name) => (event) => {
    let value = event.target.value.trim()
    value = (value.length === 0 ? 0 : parseInt(value))
    setUploadOption({ ...uploadOption, [name]: value })
  }


  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"req"}
            label={t("10026")}
            className={classesText.textField}
            value={uploadOption ? uploadOption.req : ""}
            margin="normal"
            onChange={handleChange("req")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtbin"}
            label={t("10027")}
            className={classesText.textField}
            value={uploadOption ? uploadOption.trcfmtbin : ""}
            margin="normal"
            onChange={handleChange("trcfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtpoints"}
            label={t("10028")}
            className={classesText.textField}
            value={uploadOption ? uploadOption.trcfmtpoints : ""}
            margin="normal"
            onChange={handleChange("trcfmtpoints")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtbin"}
            label={t("10029")}
            className={classesText.textField}
            value={uploadOption ? uploadOption.zfmtbin : ""}
            margin="normal"
            onChange={handleChange("zfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtpoints"}
            label={t("10030")}
            className={classesText.textField}
            value={uploadOption ? uploadOption.zfmtpoints : ""}
            margin="normal"
            onChange={handleChange("zfmtpoints")}
          />
        </Grid>
      </Grid>
    </div>
  )
}