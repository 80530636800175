import React, { useState, useContext,} from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"


//core components
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { DropzoneArea } from "material-ui-dropzone"



//import CSS
import {
  useAdminTableEditScreenStyles,
} from "styles/adminTable/adminTableEditScreenCss"


export default function CompanyImage(props) {
  const {
    handleClickClose,
    urlAccessCode
  } = props
  const {
    t,
    apiUrl,
    access_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()


  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [extension, setExtension] = useState('')
  const [file, setFile] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [url, setUrl] = useState(undefined)

  //Costanti estensione file
  const extensionType = [
    {
      label: t("10130"),
      value: "ico"
    },
    {
      label: t("10131"),
      value: "png"
    },

  ]

  //cambio estensione da inserire e setto url
  const handleChangeLogoExtension = (event) => {
    setExtension(event.target.value)
    setUrl(`${apiUrl}/companies/${urlAccessCode}/image?format=${event.target.value}`)
  }
  
  //salvo il logo
  const handleSave = () => {
    setIsSaving(true)
    var formdata = new FormData();
    formdata.append("imagefile", file[0], file[0].name);
    setIsSaving(true)
    var request = new XMLHttpRequest()
    request.open("PUT", url)
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.send(formdata)
    request.addEventListener(
      "loadend",
      () => {
        setOpenDialog(true)
        setDialogMsg(t("10132"))
      },
      false
    )
  }

  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }


  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10133").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid item xs={3}>
                <TextField
                  select
                  id="logoExtension"
                  label={"logoExtension"}
                  value={extension}
                  onChange={handleChangeLogoExtension}
                  helperText={t("10134")}

                >
                  {extensionType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          {
            extension === '' ? (
              null
            ) : (
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={extension === 'png' ? ['image/png'] : ['image/x-icon']}
                    onChange={(files) => setFile(files)}
                  //showPreviews={true}
                  //maxFileSize={100000}
                  />
              )
          }


          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            //disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>


    </div>
  )
}


