import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { CircularIndeterminate } from "views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import FilterDevice from "components/Filter/FilterDevice"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

const languageList = UserUtils.languageList

const UTCList = UserUtils.UTCList()

function searchDemoLab(adminTableRows) {
  let app = adminTableRows.filter(option => { return option.username === "demoLab" })
  if (app.length > 0) {
    return true
  }
  return false
}

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    creation,
    companyId,
    companyName,
    companyLanguage,
    handleClickClose,
    phone,
    setAdminTableRows,
    urlAccessCode,
    allBrandsDevices,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [errorFetch, setErrorFetch] = useState(false)
  const [isFetchingLab, setIsFetchingLab] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  //Valori dei Dati
  const isDemoLab = searchDemoLab(adminTableRows)
  const [labUser, setLabUser] = useState(
    isDemoLab ? (creation
      ? {
        username: undefined,
        password: "",
        lab: {
          labId: undefined,
          name: undefined,
        },
        language: companyLanguage ? companyLanguage : "it-it",
        utc: 1,
        active: true,
        //email: UserUtils.generatePasswordLettersNumbers() + "@test.com",
        email: undefined,
        demo: false,
        labPhone: "",
        description: undefined,
        settings: undefined,
      }
      : {
        username: adminTableRowData.username,
        password: adminTableRowData.password,
        lab: {
          labId: adminTableRowData.lab.labId,
          name: adminTableRowData.lab.name,
        },
        language: adminTableRowData.language,
        utc: adminTableRowData.utc,
        active: adminTableRowData.active,
        email: adminTableRowData.email,
        demo: adminTableRowData.demo ? adminTableRowData.demo : false,
        labPhone: phone,
        description: adminTableRowData.description,
        settings: adminTableRowData.settings,
      }
    ) : (
      {
        username: "demoLab",
        password: "demo",
        lab: {
          labId: 1,
          name: "LAB1",
        },
        language: "it-it",
        utc: 1,
        active: true,
        email: undefined,
        demo: true,
        labPhone: "",
        description: "Profilo uso pegaso",
      }
    )
  )

  const [selectedDeviceJSON, setSelectedDeviceJSON] = useState(
    adminTableRowData.userDevice ? adminTableRowData.userDevice : undefined
  )

  //Crea la codifica esatta dell'username
  const createUsername = useCallback(
    (userId, labOrStore) =>
      UserUtils.createUsernameUtil(
        parseInt(userId),
        labOrStore,
        adminTableRows,
        companyId
      ),
    [adminTableRows, companyId]
  )

  /*************************************
   *
   * Fetch
   *
   *************************************/
  //Fetch dati laboratorio qui dentro vengono settati/creati numero di telefono, nomeottico, password in fase di creazione
  const fetchLabId = useCallback(() => {
    setIsFetchingLab(true)
    setErrorFetch(false)
    const url = `${apiUrl}/companies/${urlAccessCode}/labs/${labUser.lab.labId}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se Lab esiste
        setLabUser({
          ...labUser,
          lab: {
            ...labUser.lab,
            name: data.name,
          },
          labPhone: data.phone,
          password: UserUtils.generatePasswordLettersNumbers() ,
          username: createUsername(labUser.lab.labId, "L"),
          utc: data.utc
        })
        setIsFetchingLab(false)
      })
      .catch((err) => {
        // Se Lab non esiste
        if (err.body.code === "E4") {
          setDialogMsg(t("10141") + " " + t("10142"))
          setErrorFetch(true)
        }
        setIsFetchingLab(false)
        ErrorUtils.errorLog(t("23"), err)
      })
  }, [
    access_token,
    apiUrl,
    createUsername,
    labUser,
    refresh_token,
    setAccess_token,
    t,
    urlAccessCode,
  ])

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setLabUser({ ...labUser, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (labUserParameter, name) => (event) => {
    setLabUser({ ...labUser, [name]: labUserParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setLabUser({ ...labUser, [name]: event.target.checked })
  }

  //Cambia LabUser.Lab field
  const handleChangeLab = (name) => (event) => {
    setLabUser({
      ...labUser,
      lab: { ...labUser.lab, [name]: event.target.value },
    })
  }

  //Cambia OpticianUser.Settings field
  const handleChangeSettings = (name) => (event) => {
    setLabUser({
      ...labUser,
      settings: { ...labUser.settings, [name]: event.target.value },
    })
  }

  const onBlurLabId = () => {
    if (isNaN(labUser.lab.labId)) {
      setLabUser({
        ...labUser,
        lab: {
          ...labUser.lab,
          name: "",
        },
        labPhone: "",
        password: "",
        username: "",
      })
    } else {
      fetchLabId()
    }
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    var device = selectedDeviceJSON
      ? (selectedDeviceJSON.model.modelId === undefined ? UserUtils.getBrandModel(selectedDeviceJSON, allBrandsDevices) : selectedDeviceJSON)
      : undefined

    var deviceToBody = device
      ? UserUtils.createJsonDevices(device, false)
      : undefined
    setIsSaving(true)
    var companyUrl = ""
    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      companyUrl = 'https://pre-mini.pegasoplatform.com/#/' + urlAccessCode
    }
    else {
      companyUrl = 'https://mini.pegasoplatform.com/#/' + urlAccessCode
    }
    const url = `${apiUrl}/companies/${urlAccessCode}/users/${labUser.username}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: labUser.username,
        password: labUser.password,
        email: labUser.email,
        labId: labUser.lab.labId,
        active: labUser.active,
        language: labUser.language,
        utc: labUser.utc,
        demo: labUser.demo,
        userDevice: deviceToBody,
        description: labUser.description,
        settings: labUser.settings,
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10102")}\n\nUsername: ${labUser.username}\nPassword: ${labUser.password
          }\n${companyUrl}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Aggiorna la lista utenti
        let newElem = {
          username: labUser.username,
          password: labUser.password,
          email: labUser.email !== "" ? labUser.email : undefined,
          lab: {
            labId: labUser.lab.labId,
            name: labUser.lab.name,
          },
          active: labUser.active,
          language: labUser.language,
          utc: labUser.utc,
          demo: labUser.demo,
          viewPassword: false,
          userDevice: device,
          description: labUser.description,
          settings: labUser.settings,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.username === labUser.username) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }



  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !labUser.username ||
      !labUser.password ||
      !labUser.lab.labId
    return result
  }

  return (
    <div className={classes.root}>
      {
        isDemoLab ? (
          <Card>
            <CardBody>
              <AdminTableElevationScroll
                {...props}
                handleClickClose={handleClickClose}
              />
              <p align="center">
                <u>{t("10160").toUpperCase()}</u>
              </p>
              <Grid container spacing={2}>
                <Grid
                  className={classes.gridCurrentValues}
                  item
                  xs={12}
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  {/* prima riga - la somma degli xs non deve superare 12  */}
                  <Grid item xs={12}>
                    <p>
                      <strong>
                        {t("10145")}: {companyName}
                      </strong>
                    </p>
                  </Grid>
                  {creation ? null : (
                    <Grid item xs={11}>
                      <p>
                        <strong>
                          {t("10146")}: {adminTableRowData.lab.labId} -{" "}
                          {adminTableRowData.lab.name}
                        </strong>
                      </p>
                    </Grid>
                  )}
                </Grid>

                <Grid className={classes.gridCurrentValues} item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <p align="center">
                        <u>{t("10104").toUpperCase()}</u>
                      </p>
                    </Grid>

                    {/*GRID CONTENENTE I DATI DELL'UTENTE*/}

                    <Grid item xs={3}>
                      <TextField
                        required
                        id={"username"}
                        label={t("10062")}
                        value={labUser.username || ""}
                        margin="normal"
                        helperText={t("10105")}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        required
                        id={"password"}
                        label={t("10063")}
                        onChange={handleChange("password")}
                        className={classesText.textField}
                        value={labUser.password || ""}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      {creation ? (
                        isFetchingLab ? (
                          <CircularIndeterminate />
                        ) : (
                          <TextField
                            required
                            error={errorFetch ? true : false}
                            id={"labId"}
                            label={t("10141")}
                            onChange={handleChangeLab("labId")}
                            onBlur={onBlurLabId}
                            className={classesText.textField}
                            value={labUser.lab.labId || ""}
                            margin="normal"
                            helperText={errorFetch ? dialogMsg : ""}
                          />
                        )
                      ) : (
                        <TextField
                          required
                          id={"labId"}
                          label={t("10141")}
                          className={classesText.textField}
                          value={labUser.lab.labId || ""}
                          margin="normal"
                          helperText={t("10105")}
                          InputProps={{
                            readOnly: creation ? false : true,
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id={"name"}
                        label={t("10079")}
                        className={classesText.textField}
                        value={labUser.lab.name || ""}
                        margin="normal"
                        helperText={t("10105")}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        select
                        id="language"
                        label={t("10083")}
                        value={labUser.language}
                        onChange={handleChange("language")}
                        helperText={t("10106")}
                      >
                        {languageList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        select
                        id="utc"
                        label={"UTC"}
                        value={labUser.utc}
                        onChange={handleChange("utc")}
                        helperText={t("10107")}
                      >
                        {UTCList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={labUser.active}
                            onChange={handleChangeChecked("active")}
                            name="active"
                          />
                        }
                        label={t("10047")}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id={"email"}
                        label={t("10108")}
                        className={classesText.textField}
                        value={labUser.email || ""}
                        margin="normal"
                        onChange={handleChange("email")}
                        onBlur={labUser.email ? onBlurTrim(labUser.email, "email") : null}

                      />
                    </Grid>

                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={"demo"}
                            checked={labUser.demo}
                            onChange={handleChangeChecked("demo")}
                            name="demo"
                          />
                        }
                        label={t("10109")}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id={"phone"}
                        label={t("10149")}
                        className={classesText.textField}
                        value={labUser.labPhone || ""}
                        margin="normal"
                        helperText={t("10105")}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id={"description"}
                        label={t("10110")}
                        className={classesText.textField}
                        value={labUser.description || ""}
                        margin="normal"
                        onChange={handleChange("description")}
                        onBlur={labUser.description ? onBlurTrim(labUser.description, "description") : null}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*GRID CONTENENTE IL DEVICE DELL'UTENTE*/}

              <FilterDevice
                userDevice={adminTableRowData.userDevice}
                creation={creation}
                isHub={false}
                handleChangeSettings={handleChangeSettings}
                userData={labUser}
                selectedDeviceJSON={selectedDeviceJSON}
                setSelectedDeviceJSON={setSelectedDeviceJSON}
                allBrandsDevices={allBrandsDevices}
              ></FilterDevice>

              {/*BOTTONI PER SALVATAGGIO*/}
              <AdminTableSaveButton
                {...props}
                handleSave={handleSave}
                disabled={disableSave()}
              />
              <AdminTableOkSaved
                {...props}
                dialogMsg={dialogMsg}
                handleClose={handleClose}
                isSaving={isSaving}
                openDialog={openDialog}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                >
                  {t("30")}
                </Button>
              </Box>
              <AdminTableOkSaved
                {...props}
                dialogMsg={dialogMsg}
                handleClose={handleClose}
                isSaving={isSaving}
                openDialog={openDialog}
              />
            </Box>
          </Card>
        )
      }



    </div>
  )
}

// Griglia interna alla finestra del LaboratoryUser
export default function LaboratoryUser(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
