import { useTranslation } from "react-i18next"

export default class DeviceUtils {


  //Conta il numero di scope presenti per la tabella scope
  static countDeviceId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (a.deviceId > b.deviceId ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return rowsFiltered[numberRows - 1].deviceId + 1
    }
    else {
      return 1
    }

  }

  static deviceType() {
    const { t } = useTranslation()
    return [
      {
        label: "",
        value: ""
      },
      {
        label: t("10210"),
        value: "Tracer"
      },
      {
        label: t("10211"),
        value: "Edger"
      },
      {
        label: t("10212"),
        value: "Blocker"
      },
      {
        label: t("Software"),
        value: "Software"
      },
    ]
  }

  static allDeviceType() {
    const { t } = useTranslation()
    return [
      {
        label: "",
        value: ""
      },
      {
        label: t("10210"),
        value: "Tracer"
      },
      {
        label: t("10211"),
        value: "Edger"
      },
      {
        label: t("10212"),
        value: "Blocker"
      },
      {
        label: "software",
        value: "Software"
      }
      ]
  }

  static connectionType() {
    const { t } = useTranslation()
    return [
      {
        label: t("10048"),
        value: "serial"
      },
      {
        label: t("10049"),
        value: "ip"
      },
    ]
  }

  static speedPortValue() {
    return [
      {
        label: "9600",
        value: 9600,
      },
      {
        label: "19200",
        value: 19200,
      },
      {
        label: "38400",
        value: 38400,
      },
      {
        label: "57600",
        value: 57600,
      },
      {
        label: "115200",
        value: 115200,
      }
    ]
  }

  static parityBitValue() {
    const { t } = useTranslation()
    return [
      {
        label: t("10085"),
        value: "N",
      },
      {
        label: t("10086"),
        value: "P",
      },
      {
        label: t("10087"),
        value: "D",
      },
    ]
  }

  static dataBitValue() {
    return [
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
      {
        label: "7",
        value: 7,
      },
      {
        label: "8",
        value: 8,
      },
    ]
  }

  static stopBitValue() {
    return [
      {
        label: "",
        value: "",
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "1.5",
        value: 1.5,
      },
      {
        label: "2",
        value: 2,
      },
    ]
  }

  static tracerIdValue() {
    return [
      {
        label: "1 Takubo",
        value: 1,
      },
      {
        label: "2 Nidek",
        value: 2,
      },
      {
        label: "3 Essilor",
        value: 3,
      },
      {
        label: "4 Blocker(OMA)",
        value: 4,
      },
      {
        label: "5 Briot / Weco/Nidek (OMA)",
        value: 5,
      },
      {
        label: "6 Hoya (OMA)",
        value: 6,
      },
      {
        label: "7 Topcon/Takubo (OMA)",
        value: 7,
      },
      {
        label: "8 Huvitz",
        value: 8,
      },
      {
        label: "9 Hoya (Hoya2)",
        value: 9,
      },
      {
        label: "11 Hoya GT500 (Hoya3)",
        value: 11,
      },
      {
        label: "12 National Optronics (OMA)",
        value: 12,
      },
      {
        label: "13 Essilor Kappa (OMA)",
        value: 13,
      },
    ]
  }

  static omaLabels =
    [
      "ADD",
      "ANS",
      "AX",
      "BCTHK",
      "BEVC",
      "BEVH",
      "BEVM",
      "BEVP",
      "BEP",
      "BSIZ",
      "CTHICK",
      "CIRC",
      "CSIZ",
      "CYL",
      "DBL",
      "DIA",
      "DO",
      "DRILL",
      "DRILLE",
      "EPRESS",
      "ERDRIN",
      "ERDRUP",
      "ERNRIN",
      "ERNRUP",
      "ERSGIN",
      "ERSGUP",
      "ETYP",
      "FBFCIN",
      "FBFCUP",
      "FCOCIN",
      "FCOCUP",
      "FCRV",
      "FCSGIN",
      "FCSGUP",
      "FMFR",
      "FPINB",
      "FRNT",
      "FTYP",
      "GDEPTH",
      "GWIDTH",
      "GRADIENT",
      "HBOX",
      "IPD",
      "LMATTYPE",
      "LDAX",
      "LDCYL",
      "LDDRAX",
      "LDDRCYL",
      "LDDRSPH",
      "LDNRAX",
      "LDNRCYL",
      "LDNRSPH",
      "LDSPH",
      "LIND",
      "LTYP",
      "LTYPE",
      "MBD",
      "MNAME",
      "MODEL",
      "MPD",
      "NPD",
      "OCHT",
      "OMAV",
      "PINB",
      "PIND",
      "POLAR",
      "POLISH",
      "PRVA",
      "PRVM",
      "SEGHT",
      "SGOCIN",
      "SGOCUP",
      "STATUS",
      "SN",
      "SPH",
      "SWIDTH",
      "THKP",
      "VBOX",
      "VEN",
      "ZTILT",
      "_BEVW",
      "_BSHAPE",
      "_BTFF",
      "_CLIP",
      "_ETYP2",
      "_FBK",
      "_FBRA",
      "_FTHICK",
      "_FPINONF",
      "_GRV2ON",
      "_LENSER",
      "_LCOAT",
      "_INFJOBS",
      "_MINTHK",
      "_PAUSE",
      "_PINONOF",
      "_RWIDTH",
      "_RHEIGHT",
      "_VARINC",
    ]

}