import React, { useContext } from "react"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { RootContext } from "RootContext"

function AdminTableKeyButton(props) {
  const { handleClickOpen, copyButton } = props
  const { t } = useContext(RootContext)

  return (
    <div id="contenitore">
      <Tooltip title={t("10295")}>
        <IconButton
          aria-label={t("10295")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(copyButton)
          }}
        >
          <VpnKeyIcon/>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AdminTableKeyButton
