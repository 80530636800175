import React, { useState } from 'react';

// Core components
import TextField from '@material-ui/core/TextField';
import { titleCase } from "title-case";

export default function FilterTextField(props) {
    const { classes, defaultValue, disabled, filterField, handleChange1, handleChange2, handleClick1, id, label } = props
    const [value, setValue] = useState({
        name: defaultValue,
        multiline: 'Controlled'
    });

    const handleChange = event => {
        const targetValue = event.target.value
        if (handleChange1) {
            handleChange1(filterField, targetValue)
        }
        if (handleChange2) {
            handleChange2(targetValue)
        }
        setValue({ ...value, "name": targetValue });
        if (handleClick1) {
            handleClick1()
        }
    };

    return (
        <TextField
            id={id}
            label={titleCase(label)}
            className={classes.textField}
            value={value.name}
            onChange={handleChange}
            margin="normal"
            disabled={disabled ? true : false}
        />
    );
}