//Pannello per la scelta del device
import React from "react"

import Edger from "components/Device/Edger";
import Tracer from "components/Device/Tracer";
import Blocker from "components/Device/Blocker";


export default function FilterDeviceType(props) {

  const {
    changeDevice,
    connectionType,
    deviceProtocol,
    isCreationModel,
    selectedDeviceData,
    setDeviceProtocol,
    selectedDeviceJSON,
    setChangeDevice,
    setSelectedDeviceJSON
  } = props

  if (selectedDeviceData.type === "Edger") {
    return <Edger
      changeDevice={changeDevice}
      connectionType={connectionType}
      deviceProtocol={deviceProtocol}
      isCreationModel={isCreationModel}
      selectedDeviceData={selectedDeviceData}
      setDeviceProtocol={setDeviceProtocol}
      selectedDeviceJSON={selectedDeviceJSON}
      setChangeDevice={setChangeDevice}
      setSelectedDeviceJSON={setSelectedDeviceJSON}
    />
  }
  else if (selectedDeviceData.type === "Blocker") {
    return <Blocker
      changeDevice={changeDevice}
      connectionType={connectionType}
      deviceProtocol={deviceProtocol}
      isCreationModel={isCreationModel}
      selectedDeviceData={selectedDeviceData}
      setDeviceProtocol={setDeviceProtocol}
      selectedDeviceJSON={selectedDeviceJSON}
      setChangeDevice={setChangeDevice}
      setSelectedDeviceJSON={setSelectedDeviceJSON}
    />
  }
  else
    return <Tracer
      changeDevice={changeDevice}
      connectionType={connectionType}
      deviceProtocol={deviceProtocol}
      isCreationModel={isCreationModel}
      selectedDeviceData={selectedDeviceData}
      setDeviceProtocol={setDeviceProtocol}
      selectedDeviceJSON={selectedDeviceJSON}
      setChangeDevice={setChangeDevice}
      setSelectedDeviceJSON={setSelectedDeviceJSON}
    />

}