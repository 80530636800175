export default class LabUtils {

  //Conta il numero di lab presenti per la tabella labs
  static countLabId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (a.labId > b.labId ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return rowsFiltered[numberRows-1].labId + 1
    }
    else {
      return 1
    }

  }
}