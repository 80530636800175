import React, { useState, useContext, useEffect } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"


const languageList = UserUtils.languageList

const UTCList = UserUtils.UTCList()

function EdgingGridContainer(props) {
  const {
    allRetailers,
    handleClickClose,
    handleClickRefresh,
    urlAccessCode,
  } = props
  const {
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [newOpen, setNewOpen] = useState(true)

  //Valori dei Dati
  const [licenseGenerator, setLicenseGenerator] = useState(
    {
      retailerId: 2,
      numLicenses: 0,
      language: "",
      utc: "",
    }
  )

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setLicenseGenerator({ ...licenseGenerator, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (name) => (event) => {
    setLicenseGenerator({ ...licenseGenerator, [name]: event.target.value.trim() })
  }

  //Cambia field
  const handleChangeRetailerId = (name) => (event) => {
    var retailer = allRetailers.filter((option) => option.retailerId === event.target.value)[0]

    setLicenseGenerator({
      retailerId: retailer.retailerId,
      numLicenses: 0,
      language: retailer.language,
      utc: retailer.utc.toString(),
    })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)

    const url = `${apiUrl}/licenses-generator`
    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        retailerId: parseInt(licenseGenerator.retailerId),
        numLicenses: parseInt(licenseGenerator.numLicenses),
        language: licenseGenerator.language,
        utc: parseInt(licenseGenerator.utc),
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10247")}\n\n${t("10226")}: ${licenseGenerator.retailerId}\n${t("10246")}: ${licenseGenerator.numLicenses}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Refresh Table
        handleClickRefresh()
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })

  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !licenseGenerator.retailerId ||
      !licenseGenerator.numLicenses ||
      !licenseGenerator.utc ||
      !licenseGenerator.language ||
      licenseGenerator.numLicenses <= 0
    return result
  }

  /*************************************
 *
 * UseEffect
 *
 *************************************/
  useEffect(() => {
    if (newOpen) {
      var retailer = allRetailers.filter((option) => option.retailerId === 2)[0]
      setLicenseGenerator({
        retailerId: 2,
        numLicenses: 0,
        language: retailer.language,
        utc: retailer.utc.toString(),
      })
      setNewOpen(false)
    }
  }, [setLicenseGenerator, allRetailers, newOpen])

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10245").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid
              className={classes.gridCurrentValues}
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
            </Grid>

            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >

                {/*GRID CONTENENTE I DATI DEL GENERATORE LICENZE*/}

                {/*RIGA 1*/}

                <Grid item xs={6}>
                  <TextField
                    select
                    required
                    id="retailerId"
                    label={t("10226")}
                    className={classesText.menuItem}
                    style={{ width: 150 }}
                    value={licenseGenerator.retailerId || ""}
                    onChange={handleChangeRetailerId()}
                  >
                    {allRetailers
                      .sort(function compare(a, b) {
                        if (a.retailerId < b.retailerId) {
                          return -1
                        }
                        if (a.retailerId > b.retailerId) {
                          return 1
                        }
                        return 0
                      })
                      .map((option) => (
                        <MenuItem key={"retailerId" + option.retailerId} value={option.retailerId}>
                          {option.retailerId + " - " + option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    id="utc"
                    label={"UTC"}
                    value={licenseGenerator.utc}
                    onChange={handleChange("utc")}
                    className={classesText.menuItem}
                  >
                    {UTCList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    id="language"
                    label={t("10083")}
                    value={licenseGenerator.language}
                    onChange={handleChange("language")}
                    className={classesText.menuItem}
                  >
                    {languageList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>


                <Grid item xs={6}>
                  <TextField
                    id={"numLicenses"}
                    label={t("10246")}
                    onChange={handleChange("numLicenses")}
                    onBlur={onBlurTrim("numLicenses")}
                    className={classesText.textField}
                    style={{ width: 150 }}
                    value={licenseGenerator.numLicenses || ""}
                    margin="normal"
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del LicenseGenerator
export default function LicenseGenerator(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
