import React, { useContext, useState } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"

import Reference from "views/Reference/Reference"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"


// Nomi dei campi delle colonne
function createData(
  idPerson,
  role,
  person,
  telephone,
  cellphone,
  email,
  labId

) {
  return {
    idPerson,
    role,
    person,
    telephone,
    cellphone,
    email,
    labId
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  let value = rows.references
  if (value) {
    return value
  }
  else return []
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.id !== rowToDelete
  })
}


export default function References(props) {
  const {
    company,
    companyLabs,
    setCompany
  } = props
  const {
    t,
  } = useContext(RootContext)

  const [filter] = useState([
    {
      id: "idPerson",
      value: ""
    },
    {
      id: "role",
      value: ""
    },
    {
      id: "person",
      value: ""
    },
    {
      id: "telephone",
      value: ""
    },
    {
      id: "cellphone",
      value: ""
    },
    {
      id: "email",
      value: ""
    },
    {
      id: "labId",
      value: ""
    },
  ])

  const headCells = [
    {
      id: "idPerson",
      numeric: false,
      disablePadding: false,
      label: t("10182"),
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: t("10179"),
    },
    {
      id: "person",
      numeric: false,
      disablePadding: false,
      label: t("10180"),
    },
    {
      id: "telephone",
      numeric: false,
      disablePadding: false,
      label: t("10149"),
    },
    {
      id: "cellphone",
      numeric: false,
      disablePadding: false,
      label: t("10181"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("10108"),
    },
    {
      id: "labId",
      numeric: false,
      disablePadding: false,
      label: t("10141")
    },
  ]
  const orderByDefault = "idPerson"


  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.id
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 7
  }


  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {t(row.role)}
        </TableCell>
        <TableCell key={"tableBodyCell2" + key} align="left">
          {row.person}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.telephone}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.cellphone}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.email}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {row.labId}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.modelId,
            filter.filter((filter) => { return filter.id === "role" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.description,
            filter.filter((filter) => { return filter.id === "person" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.clientId,
            filter.filter((filter) => { return filter.id === "telephone" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.lab ? row.lab.labId : "",
            filter.filter((filter) => { return filter.id === "cellphone" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.lab ? row.lab.labId : "",
            filter.filter((filter) => { return filter.id === "email" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.lab ? row.lab.labId : "",
            filter.filter((filter) => { return filter.id === "labId" })[0].value
          )
        })
      : rows
  }

 

  //Cancella la riga dalla tabella
  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    let newAdminTableRows = adminTableRows.filter((option) => { return option.id !== rowToDelete })
    setAdminTableRows(newAdminTableRows)
    setCompany({
      ...company, references : newAdminTableRows
    })
  }

  const getRowData = (
    (personId, rows, setData) => {
      let personToEdit
      personToEdit = rows.filter(function (elem) {
        return elem.id === personId
      })[0]
      setData(personToEdit)
    }
  )

  return (
    <div>
      <AdminTable
        AddEditElement={Reference}
        AdminTableCells={AdminTableCells}
        companyLabs={companyLabs}
        createData={createData}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getRowData}
        headCells={headCells}
        isFullTable={false}
        isDevice={false}
        notFullTableData={company.references !== undefined ? company : []}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        useCompany={true}
        setCompany={setCompany}
      //selectedDeviceJSON={selectedDeviceJSON}
      // setSelectedDeviceJSON={setSelectedDeviceJSON}
      />
    </div>
  )
}
