import React, { useState, useContext, useCallback, useEffect } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import FilterCompany from "components/Filter/FilterCompany"
import ErrorUtils from "components/Tools/ErrorUtils"
import Laboratory from "views/Laboratory/Laboratory"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

// Nomi dei campi delle colonne
function createData(
  labId,
  name,
  phone,
  email,
  maxInternalJobId,
  internalOpticianId,
  active
) {
  return {
    labId,
    name,
    phone,
    email,
    maxInternalJobId,
    internalOpticianId,
    active,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.labId !== rowToDelete
  })
}

export default function Laboratories() {
  const {
    apiUrl,
    access_token,
    getRetailer,
    refresh_token,
    setAccess_token,
    t,
  } = useContext(RootContext)

  const [allScopes, setAllScopes] = useState([])
  const [changeCompany, setChangeCompany] = useState()
  const [companyName, setCompanyName] = useState()
  const [companyScopes, setCompanyScopes] = useState()
  const [companyId, setCompanyId] = useState()
  const [retailer] = useState(getRetailer())
  const isIndependent = retailer ? retailer.isIndependent ? retailer.isIndependent : false : false

  const [filter] = useState([
    {
      id: "labId",
      value: "",
      label: t("10141"),
    },
    {
      id: "name",
      value: "",
      label: t("10079"),
    },
  ])

  const headCells = [
    {
      id: "labId",
      numeric: true,
      disablePadding: false,
      label: t("10141").toUpperCase(),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("10079").toUpperCase(),
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: t("10149").toUpperCase(),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("10128").toUpperCase(),
    },
    {
      id: "maxInternalJobId",
      numeric: false,
      disablePadding: false,
      label: t("10155").toUpperCase(),
    },
    {
      id: "internalOpticianId",
      numeric: true,
      disablePadding: false,
      label: t("10156").toUpperCase(),
    },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
  ]
  const orderByDefault = "labId"
  const [urlAccessCode, setUrlAccessCode] = useState()
  const urlGet = `${apiUrl}/companies/${urlAccessCode}/list-labs`
  const urlDelete = `${apiUrl}/companies/${urlAccessCode}/labs/`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.labId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 7
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.name ? row.name : ""}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {row.phone ? row.phone : ""}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.email
            ? row.email
            : ""}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.maxInternalJobId ? row.maxInternalJobId : ""}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.internalOpticianId ? row.internalOpticianId : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} /> 
        {!isIndependent ? <CellButtonDelete keyToUse={keyToUse} /> : null}
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.labId,
            filter.filter((filter) => {
              return filter.id === "labId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.name,
            filter.filter((filter) => {
              return filter.id === "name"
            })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch -  Elenco scopes
  const fetchScopes = useCallback(() => {
    const url = `${apiUrl}/scopetypes`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((scope) => {
        var scopeTypes = scope.scopeTypes
        var scopeLab = []
        companyScopes.map((elem) => 
          scopeTypes.map((option) =>
            option.scopes.map((last) => {return (elem.scopeId === last.scopeId && option.software === "minigui") ? scopeLab.push(option) : null})
          )
        )
        setAllScopes(scopeLab)
      })
      .catch((err) => {

        ErrorUtils.errorLog("FetchDevices  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, companyScopes])

  //Fetch -  Dati laboratorio selezionato
  const fetchLab = useCallback(
    (labId, rows, setData, setActionFetch) => {
      let labIdCheck
      let appLab
      let labToFetch

      labToFetch = rows.filter(function (elem) {
        return elem.labId === labId
      })[0]
      labIdCheck = labToFetch.labId
      appLab = labToFetch

      const url = `${apiUrl}/companies/${urlAccessCode}/labs/${labIdCheck}`

      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setData(appLab)
          setActionFetch(false)
        })
        .catch((err) => {
          ErrorUtils.errorLog("FetchLab  Error", err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token, urlAccessCode]
  )
  
  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    if (urlAccessCode) {
      fetchScopes()
    }
  }, [fetchScopes, urlAccessCode, changeCompany, setChangeCompany])

  return (
    <div>
      <FilterCompany
        urlAccessCode={urlAccessCode}
        setUrlAccessCode={setUrlAccessCode}
        companyName={companyName}
        companyId={companyId}
        setCompanyName={setCompanyName}
        setCompanyId={setCompanyId}
        setChangeCompany={setChangeCompany}
        setCompanyScopes={setCompanyScopes}
      />

      {urlAccessCode ? (
        <AdminTable
          AddEditElement={Laboratory}
          AdminTableCells={AdminTableCells}
          allScopes={allScopes}
          changeCompany={changeCompany}
          companyId={companyId}
          companyName={companyName}
          companyScopes={companyScopes}
          createData={createData}
          disableAdd={isIndependent}
          //disableOpen={isIndependent}
          disableDelete={isIndependent}
          defaultRowsPerPage={10}
          filter={filter}
          FilterAdminTableCells={FilterAdminTableCells}
          handleClickOpenAction={fetchLab}
          headCells={headCells}
          isFullTable={true}
          getAdminTableKey={getAdminTableKey}
          getAdminTableCellsCount={getAdminTableCellsCount}
          orderByDefault={orderByDefault}
          remapDeleteRow={remapDeleteRow}
          remapGetRows={remapGetRows}
          setChangeCompany={setChangeCompany}
          urlAccessCode={urlAccessCode}
          urlDelete={urlDelete}
          urlGet={urlGet}
          useCompany={true}
        />
      ) : null}
    </div>
  )
}
