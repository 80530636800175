import React, { useState, useContext } from "react"
import { RootContext } from "RootContext"

//core components

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"


//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"

//pegaso/views/components
import ScopeUtils from "components/Utils/ScopeUtils";


export default function PrintJobTickets(props) {

  const {
    adminTableRows,
    creation,
    handleClickClose,
    scopesJSON,
    userScope,
    selectedScopeData,
    setAdminTableRows,
    setScopesJSON,
  } = props

  const {
    t,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Varibili dello scope
  const [active, setActive] = useState((creation || !userScope) ? true : userScope.active)
  const [barcodeType, setBarcodeType] = useState((creation || !userScope) ? "" : userScope.hubSettings.printer.barcodeType)
  const hubScopeId = ((creation || !userScope) ? ScopeUtils.countScopeId(adminTableRows) : userScope.hubScopeId)
  const [paperModel, setPaperModel] = useState((creation || !userScope) ? 1 : userScope.hubSettings.printer.paperModel)
  const [printerName, setPrinterName] = useState((creation || !userScope) ? "" : userScope.hubSettings.printer.printerName)
  const scopesData = ((creation || !userScope) ? selectedScopeData.scopes[0] : userScope.scopeFull)

  //Costanti paperModel
  const paperModelValue = [
    {
      label: "1 - " + t("10080"),
      value: 1
    },
    {
      label: "2 - " + t("10081"),
      value: 2
    },
    {
      label: "3 - " + t("10268"),
      value: 3
    },

  ]


  const barcodeValue = [
    {
      label: "CODE39",
      value: "CODE39"
    },
    {
      label: "EAN13",
      value: "EAN13"
    },

  ]

  let scopeFull
  if (creation) {
    scopeFull = {
      active: scopesData.active,
      createdOn: scopesData.createdOn,
      defaultSettings: scopesData.defaultSettings,
      scopeId: scopesData.scopeId,
      scopeType: {
        description: creation ? selectedScopeData.description : userScope.scopeFull.scopeType.description,
        scopeTypeId: creation ? selectedScopeData.scopeTypeId : userScope.scopeFull.scopeType.scopeTypeId
      }
    }
  }


  //Attiva il device
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
  }

  //Setta nome della stampante
  const handleChangePrinterName = (event) => {
    setPrinterName(event.target.value)
  }

  //Elimina spazi finali
  const onBlurTrim = () => (event) => {
    setPrinterName(printerName.trim())
  }

  //Setta il paperModel
  const handleChangePaperModel = (event) => {
    setPaperModel(event.target.value)
  }

  //setta il barcode
  const handleChangeBarcode = (event) => {
    setBarcodeType(event.target.value)
  }


  //Salva lo scode
  const handleSave = () => {
    let value
    value = {
      active: active,
      hubScopeId: hubScopeId,
      hubSettings: {
        topicId: scopesData.defaultSettings.topicId,
        system: scopesData.defaultSettings.system,
        printer: {
          printerName: printerName,
          paperModel: paperModel,
          barcodeType: barcodeType,
          utc: 1 //da verificare se inserire o meno l'utc
        }
      },
      scopeId: scopesData.scopeId,
      scopeFull: creation ? scopeFull : scopesData
    }
    ScopeUtils.saveScopeJSON(scopesJSON, setScopesJSON, creation, value, hubScopeId)
    handleClickClose()
    let newAdminTableRows = creation
      ? adminTableRows.concat(value)
      : adminTableRows.map((elem) => {
        if (elem.hubScopeId === userScope.hubScopeId) {
          return value
        } else {
          return elem
        }
      })
    setAdminTableRows(newAdminTableRows)
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result = printerName === "" || barcodeType === ""

    return result
  }

  return (

    <Grid className={classes.gridCurrentValues} item xs={12}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10068").toUpperCase()}</u>
          </p>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"printerName"}
            label={t("10082")}
            className={classesText.textField}
            value={printerName || ""}
            margin="normal"
            onChange={handleChangePrinterName}
            onBlur={onBlurTrim()}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            id="paperModel"
            label={t("10083")}
            value={paperModel}
            onChange={handleChangePaperModel}
            helperText={t("10083")}
          >
            {paperModelValue.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>


        <Grid item xs={3}>
          <TextField
            select
            id="bracode"
            label={t("10084")}
            value={barcodeType}
            onChange={handleChangeBarcode}
            helperText={t("10084")}
          >
            {barcodeValue.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={handleChangeActive}
                name="active"
              />
            }
            label={t("10047")}
          />
        </Grid>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          p={1}
          className={classes.gridToolbar}
        >
          <Box p={1}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSave}
              disabled={disableSave()}
            >
              {t("10010")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )

}
