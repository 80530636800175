//Crea un pannello per la scelta della company sulla quale lavorare
import React, { useContext, useState, useEffect, useCallback } from "react"
import { RootContext } from "../../RootContext"

//Material UI
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Card from "components/Card/Card.js"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import Typography from "@material-ui/core/Typography"

//Componenti Pegaso
import { CircularIndeterminate } from "../../views/Loading"
import ErrorUtils from "../Tools/ErrorUtils"
import { fetchWithToken } from "../Fetch/api-fetch"


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default function FilterCompanyWithOpticians(props) {
  const {
    urlAccessCode,
    reducedCompany,
    scopeIdCompany,
    setUrlAccessCode,
    setCompanyName,
    setCompanyId,
    setChangeCompany,
    setCompanyLanguage,
    setCompanyScopes,
    setRetailerCompany,
  } = props

  const {
    t,
    isRetailerUser,
    getRetailerId,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const classes = useStyles()
  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())
  const [anchorEl, setAnchorEl] = useState(null)
  const [companies, setCompanies] = useState([])
  const [companyUrl, setCompanyUrl] = useState()
  const [fetch, setFetch] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const fetchCompany = useCallback(() => {
    setIsFetching(true)
    var reduced = reducedCompany !== undefined ? reducedCompany : true
    var scopeId = scopeIdCompany !== undefined ? scopeIdCompany : -1
    const url = `${apiUrl}/companies?reduced=${reduced}&scopeId=${scopeId}&retailer=${retailerId}`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ companies }) => {
        companies.sort(function (a, b) {
          if (parseInt(a.companyId) >= parseInt(b.companyId)) {
            return 1
          } else {
            return -1
          }
        })
        if (isRetailer) {
          companies = companies.filter(elem => {
            return elem.license.retailer.retailerId === retailerId
          })
        }
        setCompanies(companies)
        setFetch(false)
        setIsFetching(false)
      })
      .catch((err) => {
        setFetch(false)
        setIsFetching(false)
        ErrorUtils.errorLog("Fetch Company  Error", err)
      })
  }, [
    setIsFetching,
    setFetch,
    apiUrl,
    access_token,
    isRetailer,
    refresh_token,
    reducedCompany,
    retailerId,
    setAccess_token,
    scopeIdCompany
  ])

  useEffect(() => {
    if (fetch && !isFetching) {
      fetchCompany()
    }
    if (urlAccessCode) {
      setUrlAccessCode(urlAccessCode)
    }
  }, [
    fetch,
    isFetching,
    fetchCompany,
    urlAccessCode,
    setUrlAccessCode,
    setChangeCompany,
  ])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return isFetching ? (
    <div className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem>
          <ListItemText primary={t("10275").toUpperCase()} />
          <CircularIndeterminate />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      ></Menu>
    </div>
  ) : (
    <div>
      <Card>
        <div className={classes.root}>
          <Autocomplete
            id="filterCompany"
            style={{ width: 300 }}
            disableClearable={true}
            options={companies}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.companyId + "-" + option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("10042").toUpperCase()}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(event, newValue) => {
              const hostName = window.location.hostname
              if (
                hostName.indexOf("localhost") >= 0 ||
                hostName.indexOf("pre-admin") >= 0
              ) {
                setCompanyUrl(
                  "https://pre-mini.pegasoplatform.com/#/" +
                  newValue.urlAccessCode
                )
              } else {
                setCompanyUrl(
                  "https://mini.pegasoplatform.com/#/" + newValue.urlAccessCode
                )
              }
              setChangeCompany(true)
              setUrlAccessCode(newValue.urlAccessCode)
              if (setRetailerCompany) {
                setRetailerCompany(newValue.license.retailer.retailerId)
              }
              if (setCompanyScopes) {
                setCompanyScopes(newValue.companyScopes)
              }
              setCompanyName(newValue.name)
              setCompanyId(newValue.companyId)
              if (setCompanyLanguage) {
                setCompanyLanguage(newValue.language)
              }
              setAnchorEl(null)
            }}
          />
          {urlAccessCode ? (
            <Typography>
              <Link
                //href="#"
                onClick={() => {
                  window.open(companyUrl, "_blank")
                }}
                variant="body2"
              >
                {companyUrl}
              </Link>
            </Typography>
          ) :
            null}
        </div>
      </Card>
    </div>
  )
}